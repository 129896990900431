import React, {useEffect} from "react";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import {Box, Typography} from "@mui/material";
import NavigationButton from "../../../../components/Button/NavigationButton";
import routes from "../../../../routes";
import WorkSheetList from "./WorkSheetList";
import {fetchWorkSheets} from "../store/actions";
import {
    useAppDispatch,
    useAppSelector,
    useBreathalyzerResultFilterChips,
    useFetchBreathalyzerResults,
    useWorkSheetsNearingDeparturesObserving,
    useWorkSheetsSortType
} from "../../../../hooks";
import {fetchBuses} from "../../../../store/busSlice";
import {fetchDrivers} from "../../../../store/driverSlice";
import {selectSelectedRegion} from "../../../../store/regionSlice";
import {setLoading} from "../store/workSheetsSlice";
import {selectToggledResourceType, useDayNavigation, useResourceTypeToggle} from "../../../../store/viewSlice";
import {ResourceGroupToggleButtonType, resourceGroupToggleButtonTypeValues} from "../../../../types";
import {useDebounce} from "usehooks-ts";
import {DEFAULT_DEBOUNCE_DELAY} from "../../../../constants";
import ErrorWrapper from "../../../../components/ErrorWrapper/ErrorWrapper";
import {selectBusesWithDefects} from "../../../../store/workScheduleItemSlice";
import {ConstructionRounded} from "@mui/icons-material";
import BusesWithDefects from "./BusesWithDefects";
import AvailableBuses from "./AvailableBuses";
import LoaderWithChildren from "../../../../components/Loader/LoaderWithChildren";
import {FilterChipProps} from "../../../../components/FilterToolbar/components/FilterChip";
import AdvancedFilterToolbar from "../../../../components/FilterToolbar/AdvancedFilterToolbar";
import {getBreathalyzerResultFilterChipTranslation} from "../../../../utils/enumTranslations";
import {BreathalyzerResultFilterChipType} from "../types";
import {ResourceType} from "../../../../API/types";


export default function WorkSheetsListView() {
    const [date] = useDayNavigation();
    const error = useAppSelector(state => state.workSheets.error);
    const debouncedDate = useDebounce(date, DEFAULT_DEBOUNCE_DELAY);
    const [sortType, setSortType] = useWorkSheetsSortType();
    const [resourceTypeToggleButtonValue, handleResourceTypeChange] = useResourceTypeToggle<ResourceGroupToggleButtonType>();
    const [showOnlyNearingDepartures, setShowOnlyNearingDepartures] = useWorkSheetsNearingDeparturesObserving();
    const [selectedBreathalyzerFilterChips, setSelectedBreathalyzerFilterChips] = useBreathalyzerResultFilterChips();
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const toggledResourceType = useAppSelector(selectToggledResourceType);
    const busesWithDefects = useAppSelector(selectBusesWithDefects);
    const loading = useAppSelector(state => state.workSheets.loading);
    const dispatch = useAppDispatch();
    useFetchBreathalyzerResults();

    useEffect(() => {
        dispatch(fetchBuses());
        dispatch(fetchDrivers());
        dispatch(setLoading(true));
    }, []);

    useEffect(() => {
        dispatch(fetchWorkSheets());
    }, [debouncedDate, selectedRegion]);
    useEffect(() => {
        dispatch(setLoading(true));
    }, [date, selectedRegion]);

    useEffect(() => {
        if (selectedRegion?.showBreathalyzerResult) {
            if (sortType === 'code' && showOnlyNearingDepartures) setShowOnlyNearingDepartures(false);
        }
    }, [sortType]);

    useEffect(() => {
        if (selectedRegion?.showBreathalyzerResult) {
            if (showOnlyNearingDepartures && sortType === 'code') setSortType('startTime');
        }
    }, [showOnlyNearingDepartures]);

    const handleSelectedFilterChipsChange = (value: BreathalyzerResultFilterChipType) => {
        if (selectedBreathalyzerFilterChips.includes(value)) {
            setSelectedBreathalyzerFilterChips(prevState => prevState.filter(prevValue => prevValue !== value));
        } else {
            setSelectedBreathalyzerFilterChips(prevState => [...prevState, value]);
        }
    };

    return (
        <Box>
            <PageHeader
                title="Päevaplaan"
                toggleProps={{
                    options: [...resourceGroupToggleButtonTypeValues],
                    value: resourceTypeToggleButtonValue,
                    handleChange: handleResourceTypeChange,
                }}
                secondaryButton={
                    <NavigationButton text="Planeerija" route={routes.AUTHENTICATED.WORK_SHEETS.PLANNER} />
                }
                showRegionSelect
            />
            <ErrorWrapper error={error}>
                <AdvancedFilterToolbar
                    dateNavEndAdornment={
                        busesWithDefects.length > 0 ? (
                            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', pl: 1}}>
                                <ConstructionRounded fontSize="small" sx={{color: 'text.secondary'}} />
                                <Typography>{busesWithDefects.length}</Typography>
                            </Box>
                        ) : undefined
                    }
                    filterChips={selectedRegion?.showBreathalyzerResult ? [
                        [{
                            selected: showOnlyNearingDepartures,
                            handleSelectClick: () => setShowOnlyNearingDepartures(prevState => !prevState),
                            text: 'Jälgi väljumisi',
                            value: showOnlyNearingDepartures,
                        }],
                        ...(toggledResourceType === ResourceType.DRIVER ? [
                            Object.values(BreathalyzerResultFilterChipType).map((value): FilterChipProps<BreathalyzerResultFilterChipType> => ({
                                selected: selectedBreathalyzerFilterChips.includes(value),
                                handleSelectClick: handleSelectedFilterChipsChange,
                                text: getBreathalyzerResultFilterChipTranslation(value),
                                value: value
                            }))] : []
                        )
                    ] : []}
                />
                <Box sx={{mb: 1.5}} display="none" displayPrint="flex" justifyContent="space-between">
                    <Typography>{date.format('DD. MMMM YYYY')}</Typography>
                    <Typography>{selectedRegion?.name}</Typography>
                </Box>
                <LoaderWithChildren loading={loading}>
                    <WorkSheetList />
                    <AvailableBuses />
                    <BusesWithDefects />
                </LoaderWithChildren>
            </ErrorWrapper>
        </Box>
    );
}
