import React, {ReactElement} from 'react';
import {Tooltip as MuiTooltip} from "@mui/material";


interface TooltipProps {
    title?: React.ReactNode;
    alignTooltipArrowRight?: boolean;
    children: ReactElement;
}

const Tooltip = ({title, alignTooltipArrowRight, children}: TooltipProps) => {
    return (
        <MuiTooltip
            title={title}
            arrow
            placement={alignTooltipArrowRight ? 'top-end' : 'top'}
            slotProps={alignTooltipArrowRight ? {
                arrow: {
                    sx: {
                        right: '6px !important',
                    },
                },
            } : {}}
        >
            {children}
        </MuiTooltip>
    );
};

export default Tooltip;
