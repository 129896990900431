import React, {useContext} from 'react';
import {Box, Paper} from '@mui/material';
import {Outlet} from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import { styled } from '@mui/material/styles';
import { SidebarContext } from '../../contexts/SidebarContext';
import { RegionProvider } from '../../contexts/RegionContext';
import { isSandboxEnv } from "../../utils/utils";


interface MainWrapperProps {
    open: boolean;
}

const MainWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMobileLayout' && prop !== 'displaySandboxEnvStyle',
})<{ isMobileLayout: boolean, displaySandboxEnvStyle: boolean }>(({ isMobileLayout, displaySandboxEnvStyle }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: displaySandboxEnvStyle ? '#0d9bdd' : '#f0f2f0',
    minHeight: '100vh',
    ...(isMobileLayout && {
        overflow: 'hidden',
        '@supports (height: 100dvh)': {
            minHeight: '100dvh',
        }
    }),
    ...(!isMobileLayout && {
        height: '100vh',
    }),
}));

const PageWrapper = styled(Box, {
    shouldForwardProp: prop => prop !== 'open',
})<MainWrapperProps>(({ theme, open }) => ({
    transition: theme.transitions.create('padding-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '@media not print': { height: '100%' },
    ...(!open && {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '65px',
        }
    }),
    ...(open && {
        paddingLeft: '225px',
    }),
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingTop: '50px',
    },
    '@media print': {
        paddingLeft: 0,
    },
}));

const ContentWrapper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5),
        margin: theme.spacing(1),
    },
    '@media print': {
        overflowX: 'visible',
        padding: 0,
        margin: 0,
        border: 'none',
        boxShadow: 'none',
    }
}));

export default function DashboardLayout({isMobileLayout = false}: {isMobileLayout: boolean}) {
    const { isSidebarOpen } = useContext(SidebarContext);
    const displaySandboxEnvStyle = isSandboxEnv();

    return (
        <RegionProvider>
            <MainWrapper isMobileLayout={isMobileLayout} displaySandboxEnvStyle={displaySandboxEnvStyle}>
                <Sidebar />
                <PageWrapper open={isSidebarOpen}>
                    <ContentWrapper>
                        <Outlet />
                    </ContentWrapper>
                </PageWrapper>
            </MainWrapper>
        </RegionProvider>
    );
}
