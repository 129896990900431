import dayjs from "dayjs";
import {Dayjsable} from "../utils/dateUtils";

// general constants

export const MINUTES_IN_AN_HOUR = 60;
export const HOURS_IN_A_DAY = 24;

// driver constants

export const WEEKLY_NOMINAL_HOURS = 40;
export const MAX_NON_WORK_TO_WORK_RATIO = 0.47;

export const MIN_DRIVER_REST_TIME_HOURS = 9;
export const MAX_DRIVER_WORK_GROUP_DURATION_HOURS = 15;

// defaults

export const DEFAULT_DEBOUNCE_DELAY = 500;
export const DEFAULT_DISPLAY_TIMOUT = 6000;
export const DEFAULT_FETCHING_INTERVAL = 5 * 60 * 1000; // 5 minutes
export const DEFAULT_CHECKING_INTERVAL = 60 * 1000; // 1 minute

export const getMonthlyNominalHours = (date: Dayjsable): number => dayjs(date).isBefore(dayjs('2025-01-01')) ? 169 : 168;
