import {UnassignedTripsState} from "../../../store/plannerSlice";
import {ResourceType, WorkGroupItemType} from "../../../API/workGroup/types";
import {isBreakType, WorkGroupItemTypeCharterTrip} from "../../../API/workSheets/types";
import {EfficiencyIndicators, PlannerItemGroupWithItemDurations, PlannerItemStartAndEndHourWithType} from "../types";
import {round} from "lodash";


export const getEfficiencyIndicators = (
    toggledResourceType: ResourceType,
    unassignedTripsState: UnassignedTripsState,
    groupsWithItemDurations: PlannerItemGroupWithItemDurations[],
): EfficiencyIndicators => {
    const plannedWorkGroupsCount = getPlannedWorkGroupsCount(groupsWithItemDurations);
    const unassignedTripsCount = getUnassignedTripsCount(unassignedTripsState);

    if (toggledResourceType === ResourceType.VEHICLE) {
        return { plannedWorkGroupsCount, unassignedTripsCount };
    }

    const { driverWorkHours, driverWorkGroupLineTime, driverLineTimeCoefficient } = getDriverEfficiencyIndicators(groupsWithItemDurations);

    return {
        driverWorkHours: round(driverWorkHours, 2),
        driverWorkGroupLineTime: round(driverWorkGroupLineTime, 2),
        driverLineTimeCoefficient: round(driverLineTimeCoefficient, 2),
        plannedWorkGroupsCount: plannedWorkGroupsCount,
        unassignedTripsCount: unassignedTripsCount,
    };
};

const getPlannedWorkGroupsCount = (groups: PlannerItemGroupWithItemDurations[]) => groups.filter(group => group.items.length > 0).length;

export const getUnassignedTripsCount = (unassignedTripsState: UnassignedTripsState) =>
    [...unassignedTripsState.charterTripRows, ...unassignedTripsState.rows, ...unassignedTripsState.otherRegionRows].flat().length;

export const getDriverEfficiencyIndicators = (groups: PlannerItemGroupWithItemDurations[]) => {
    const { driverWorkHours, driverWorkGroupLineTime } = groups.reduce(
        (acc, group) => {
            const { countedWorkItems, notCountedBreakItems } = partitionWorkAndBreakItems(group.items);

            if (countedWorkItems.length > 0) {
                acc.driverWorkHours += getWorkGroupWorkHours(countedWorkItems, notCountedBreakItems);
                acc.driverWorkGroupLineTime += getWorkGroupLineTime(countedWorkItems);
            }

            return acc;
        },
        { driverWorkHours: 0, driverWorkGroupLineTime: 0 }
    );

    const driverLineTimeCoefficient = getLineTimeCoefficient(driverWorkHours, driverWorkGroupLineTime);

    return {driverWorkHours, driverWorkGroupLineTime, driverLineTimeCoefficient};
}

const partitionWorkAndBreakItems = (items: PlannerItemStartAndEndHourWithType[]): {
    countedWorkItems: PlannerItemStartAndEndHourWithType[];
    notCountedBreakItems: PlannerItemStartAndEndHourWithType[];
} => items.reduce(
    (acc, item) => {
        isBreakType(item.type) ? acc.notCountedBreakItems.push(item) : acc.countedWorkItems.push(item);
        return acc;
    },
    { countedWorkItems: [] as PlannerItemStartAndEndHourWithType[], notCountedBreakItems: [] as PlannerItemStartAndEndHourWithType[] }
);

const getWorkGroupWorkHours = (countedWorkItems: PlannerItemStartAndEndHourWithType[], notCountedBreakItems: PlannerItemStartAndEndHourWithType[]) => {
    const workGroupStartTime = countedWorkItems[0].startHh;
    const workGroupEndTime = countedWorkItems[countedWorkItems.length - 1].endHh;
    const deductedBreaks = notCountedBreakItems
        .filter(item => item.startHh > workGroupStartTime && item.endHh < workGroupEndTime)
        .reduce((total, item) => total + (item.endHh - item.startHh), 0);

    return Math.max(0, workGroupEndTime - workGroupStartTime - deductedBreaks);
};

const getWorkGroupLineTime = (items: PlannerItemStartAndEndHourWithType[]) => items
    .filter(item =>
        item.type === WorkGroupItemType.TRIP_DEFINITION ||
        item.type === WorkGroupItemTypeCharterTrip.CHARTER_TRIP
    )
    .reduce((total, item) => total + (item.endHh - item.startHh), 0);

const getLineTimeCoefficient = (driverWorkHours: number, driverWorkGroupLineTime: number) =>
    driverWorkHours > 0 ? (driverWorkGroupLineTime / driverWorkHours) : 0;
