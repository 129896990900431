import routes from '../../../routes';
import {
    AspectRatio,
    Assessment,
    Assignment,
    Badge,
    ConstructionRounded,
    DateRange,
    DescriptionOutlined,
    DirectionsBus,
    Diversity2,
    ModeOfTravel,
    MultipleStopRounded,
    PendingActions,
    PeopleAlt,
    RouteRounded,
    SavingsRounded,
    SyncProblem,
    ViewTimelineRounded,
    WorkHistory,
    YoutubeSearchedFor
} from '@mui/icons-material';
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {SvgIconTypeMap} from "@mui/material";
import {Environment, Permission} from "../../../types";
import DriverAndVehicleIcon from "./DriverAndVehicleIcon";


export interface MenuItem {
    name: string;
    route?: string;
    icon: OverridableComponent<SvgIconTypeMap>;
    permission?: Permission;
    excludedEnvironments?: Environment[];
    children?: MenuItem[];
}

export const DASHBOARD: MenuItem = {
    name: 'Avaleht',
    route: routes.AUTHENTICATED.DASHBOARD,
    icon: AspectRatio,
    permission: undefined,
};

const menuItemGroups: MenuItem[] = [
    {
        name: 'Juhid ja sõidukid',
        icon: DriverAndVehicleIcon,
        children: [
            {
                name: 'Bussijuhid',
                route: routes.AUTHENTICATED.DRIVERS.ROOT,
                icon: Badge,
                permission: Permission.ListDrivers,
            },
            {
                name: 'Bussid',
                route: routes.AUTHENTICATED.BUSES.ROOT,
                icon: DirectionsBus,
                permission: Permission.ListBuses,
            },
            {
                name: 'Rikked',
                route: routes.AUTHENTICATED.DEFECTS.ROOT,
                icon: ConstructionRounded,
                permission: Permission.ListDefects,
                excludedEnvironments: [Environment.SANDBOX],
            },
        ]
    },
    {
        name: 'Veod',
        icon: MultipleStopRounded,
        children: [
            {
                name: 'Reisid',
                route: routes.AUTHENTICATED.TRIPS.ROOT,
                icon: ModeOfTravel,
                permission: Permission.ListTrips,
            },
            {
                name: 'Tellimusveod',
                route: routes.AUTHENTICATED.CHARTER_TRIPS.ROOT,
                icon: RouteRounded,
                permission: Permission.ReadCharterTrips,
                excludedEnvironments: [Environment.SANDBOX],
            },
            {
                name: 'Veolepingud',
                route: routes.AUTHENTICATED.TRANSPORT_CONTRACTS.ROOT,
                icon: Assignment,
                permission: Permission.ListTransportContracts,
            },
            {
                name: 'Reisi otsing',
                route: routes.AUTHENTICATED.TRIP_LOOKUP,
                icon: YoutubeSearchedFor,
                permission: Permission.ReadTripOccurrences,
                excludedEnvironments: [Environment.SANDBOX],
            }
        ]
    },
    {
        name: 'Planeerimine',
        icon: ViewTimelineRounded,
        children: [
            {
                name: 'Töögrupid',
                route: routes.AUTHENTICATED.WORK_GROUPS.ROOT,
                icon: Diversity2,
                permission: Permission.ReadWorkGroups,
            },
            {
                name: 'Tööajakava',
                route: routes.AUTHENTICATED.WORK_SCHEDULE.ROOT,
                icon: WorkHistory,
                permission: Permission.ListWorkSchedules,
                excludedEnvironments: [Environment.SANDBOX],
            },
            {
                name: 'Päevaplaan',
                route: routes.AUTHENTICATED.WORK_SHEETS.ROOT,
                icon: PendingActions,
                permission: Permission.ListWorkSchedules,
                excludedEnvironments: [Environment.SANDBOX],
            },
        ]
    },
    {
        name: 'Administratsioon',
        icon: DescriptionOutlined,
        children: [
            {
                name: 'Kalender',
                route: routes.AUTHENTICATED.CALENDAR,
                icon: DateRange,
                permission: Permission.ReadCalendarEntries,
            },
            {
                name: 'Aruanded',
                route: routes.AUTHENTICATED.REPORTS,
                icon: Assessment,
                permission: Permission.ReadReports,
                excludedEnvironments: [Environment.SANDBOX],
            },
            {
                name: 'Palgalehed',
                route: routes.AUTHENTICATED.PAYROLL.ROOT,
                icon: SavingsRounded,
                permission: Permission.ReadPayroll,
                excludedEnvironments: [Environment.SANDBOX],
            },
            {
                name: 'Kasutajad',
                route: routes.AUTHENTICATED.USERS,
                icon: PeopleAlt,
                permission: Permission.ListUsers,
                excludedEnvironments: [Environment.SANDBOX],
            },
            {
                name: 'Töökäsud',
                route: routes.AUTHENTICATED.AVL_ASSIGNMENTS,
                icon: SyncProblem,
                permission: Permission.ReadAvlAssignments,
                excludedEnvironments: [Environment.SANDBOX],
            }
        ]
    },
];

export default menuItemGroups;
