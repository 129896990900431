import {SelectOptionWithId} from "../../../types";
import {RoutePointForm} from "../trips/detailsView/types";
import {Dayjs} from "dayjs";
import {Bus} from "../../../API/bus/types";
import {DefectSimple} from "../../../API/defects/types";
import {WorkGroupItemTypeWithCharterTrip} from "../../../API/workSheets/types";


export interface DriverOption {
    id: number; // This is actually contract ID
    name: string;
    active: boolean;
    contractStartDate: string;
    contractEndDate: string | null;
    nominalWeeklyWorkingHours: number;
}

export interface BusOption {
    id: number;
    name: string;
    active: boolean;
}

export interface WorkItemForm {
    type: WorkGroupItemTypeWithCharterTrip;
    trip: SelectOptionWithId | null;
    charterTrip: SelectOptionWithId | null;
    startTime: Dayjs | null;
    startTimeIsOnNextDay: boolean;
    endTime: Dayjs | null;
    endTimeIsOnNextDay: boolean;
    route: RoutePointForm[];
    distance: string | null;
    comment: string;
    oppositeResource: SelectOptionWithId | null;
    defectId: number | null;
}

export interface SelectOptionWithIdAndTripComment extends SelectOptionWithId {
    tripComment?: string;
}

export interface BusWithDefects extends Bus {
    defects: DefectSimple[];
}

export interface BusWithDefectsAndHighestPriority extends BusWithDefects {
    highestPriorityDefect: DefectSimple;
}

export enum BreathalyzerResultFilterChipType {
    PASSED = 'PASSED',
    FAILED = 'FAILED',
    UNKNOWN = 'UNKNOWN'
}
