import {Region} from "../region/types";

export interface CreateNotificationSubscription {
    type: NotificationType;
    regionIds: number[];
}

export interface UpdateNotificationSubscription {
    regionIds: number[];
}

export interface NotificationSubscription {
    id: number;
    type: NotificationType;
    regions: Region[];
}

export enum NotificationType {
    DEFECT_CREATED = 'DEFECT_CREATED',
    DEFECT_COMMENT_CREATED = 'DEFECT_COMMENT_CREATED',
}
