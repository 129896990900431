import React, {useContext, useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {ApiError, TripDefinition} from "../../../../API/types";
import {GridColDef} from '@mui/x-data-grid';
import {
    areBothOrNeitherActiveChipsSelected,
    filterByActive,
    filterByEndDate,
    filterByValue
} from "../../../../utils/utils";
import {Permission} from "../../../../types";
import Visible from '../../../../components/Visible/Visible';
import DataGrid, {DEFAULT_COL_DEF_PROPS, DEFAULT_MENU_COL_DEF_PROPS} from "../../../../components/DataGrid/DataGrid";
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import routes from "../../../../routes";
import {useAppSelector} from "../../../../hooks";
import {selectSelectedRegion} from "../../../../store/regionSlice";
import {endTrip, loadTripDefinitions} from "../../../../API";
import {ToastContext} from "../../../../contexts/ToastContext";
import {mapErrors} from "../../../../utils/errorMapping";
import {
    getDateString,
    getDisplayDate,
    getDisplayDateWithoutYear,
    getDisplayNullableDate,
    getDisplayTimeWithModifier
} from "../../../../utils/dateUtils";
import NavLink from "../../../../components/NavLink/NavLink";
import NavIcon from "../../../../components/Icon/NavIcon";
import StaticIcon from "../../../../components/Icon/StaticIcon";
import Repetition from "../../../../features/RepetitionRules/RepetitionRules";
import {SearchContextType} from "../../../../layouts/SearchLayoutWrapper";
import ListView from "../../../../layouts/ListViewWrapper";
import ActionIcon from "../../../../components/Icon/ActionIcon";
import EndTripDialog, {EndTripDialogData} from "./components/EndTripDialog";
import FilterToolbar from "../../../../components/FilterToolbar/FilterToolbar";
import {useLocalStorage} from "usehooks-ts";
import {TripFilterChipType, tripFilterChipValues} from "../types";
import {FilterChipProps} from "../../../../components/FilterToolbar/components/FilterChip";


export default function Trips() {
    const { addToast } = useContext(ToastContext);
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const navigate = useNavigate();
    const location = useLocation();
    const {searchInput, setSearchInput, paginationModel, setPaginationModel} = useOutletContext<SearchContextType>();
    const [rows, setRows] = useState<TripDefinition[] | undefined>(undefined);
    const [selectedFilterChips, setSelectedFilterChips] = useLocalStorage<TripFilterChipType[]>('tripFilterChips', ['Aktiivsed', 'Käigus']);
    const [endTripDialogData, setEndTripDialogData] = useState<EndTripDialogData | undefined>(undefined);

    useEffect(() => {
        if (selectedRegion) {
            loadTripDefinitions(selectedRegion.id)
                .then(result => setRows(
                    result.sort((a,b) => a.code.localeCompare(b.code) || (a.validFrom ?? '').localeCompare(b.validFrom ?? ''))
                ))
                .catch((error: ApiError) => {
                    setRows([]);
                    addToast({type: 'error', text: mapErrors(error) ?? 'Reiside pärimisel ilmnes viga'})
                });
        }
    }, [selectedRegion]);

    const handleAddTripDefinition = () => {
        navigate(routes.AUTHENTICATED.TRIPS.ADD);
    };

    const handleSelectedFilterChipsChange = (value: TripFilterChipType) => {
        if (selectedFilterChips.includes(value)) {
            setSelectedFilterChips(prevState => prevState.filter(prevValue => prevValue !== value));
        } else {
            setSelectedFilterChips(prevState => [...prevState, value]);
        }
    };

    const getFilteredRows = (rows: TripDefinition[]) => filterByActive(filterByEndDate(filterByValue(rows, searchInput), selectedFilterChips), selectedFilterChips);

    const handleClose = () => setEndTripDialogData(undefined);

    const handleEndTrip = async (trip: TripDefinition, date: Date) => {
        const dateString = getDateString(date);
        return endTrip(trip.tripId, {
                date: dateString,
                confirmed: false,
            }).then((result) => {
                setEndTripDialogData(prevState =>
                    prevState && { ...prevState, date: date, plannedChanges: result }
                );
            });
    };

    const handleConfirmEndTrip = async (trip: TripDefinition, date: Date) => {
        const dateString = getDateString(date);
        await endTrip(trip.tripId, {
            date: dateString,
            confirmed: true,
        });
        setRows((prevState) =>
            prevState && prevState.map(row => (row.id === trip.id) ? {...row, validTo: dateString} : row)
        );
        setEndTripDialogData(undefined);
        navigate(location.pathname, {
            replace: true,
            state: {highlightId: trip.id}
        });
        addToast({
            type: 'success',
            text: `Reisi ${trip.code} lõpukuupäevaks määratud ${getDisplayDate(date)}`,
        });
    };

    const columns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'code',
            headerName: 'Kood',
            minWidth: 90,
            maxWidth: 140,
            renderCell: params =>
                <NavLink id={params.row.id} value={params.row.code} route={routes.AUTHENTICATED.TRIPS.DETAILS} navPermission={Permission.ReadTrips} />
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'lineNumber',
            headerName: 'Liini nr',
            minWidth: 70,
            maxWidth: 100,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'startTimeWithModifier',
            headerName: 'Algusaeg',
            minWidth: 80,
            maxWidth: 90,
            valueGetter: params => getDisplayTimeWithModifier(params.row.startTime, params.row.startTimeIsOnNextDay),
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'endTimeWithModifier',
            headerName: 'Lõpuaeg',
            minWidth: 80,
            maxWidth: 90,
            valueGetter: params => getDisplayTimeWithModifier(params.row.endTime, params.row.endTimeIsOnNextDay),
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'startingPoint',
            headerName: 'Algpunkt',
            minWidth: 100,
            maxWidth: 150,
            valueGetter: params => params.row.route && params.row.route.length > 0
                ? params.row.route[0].name
                : '',
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'endingPoint',
            headerName: 'Lõpp-punkt',
            minWidth: 100,
            maxWidth: 150,
            valueGetter: params => params.row.route && params.row.route.length > 1
                ? params.row.route[params.row.route.length-1].name
                : ''
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'validFrom',
            headerName: 'Algus',
            minWidth: 95,
            maxWidth: 120,
            valueFormatter: params => getDisplayNullableDate(params.value)
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'validTo',
            headerName: 'Lõpp',
            minWidth: 95,
            maxWidth: 120,
            valueFormatter: params => getDisplayNullableDate(params.value)
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'periodStartAndEndDate',
            headerName: 'Kehtivusperiood',
            sortable: false,
            minWidth: 105,
            maxWidth: 140,
            valueGetter: params => params.row.startDate && params.row.endDate ?
                getDisplayDateWithoutYear(params.row.startDate) + ' - ' + getDisplayDateWithoutYear(params.row.endDate) : ''
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'repetition',
            headerName: 'Korduvusreeglid',
            sortable: false,
            minWidth: 140,
            maxWidth: 160,
            renderCell: params => params.value ?
                <Box style={{minWidth: 'fit-content'}}><Repetition repetition={params.value} /></Box> : <></>
        },
        ...(areBothOrNeitherActiveChipsSelected(selectedFilterChips) ? [{
            ...DEFAULT_COL_DEF_PROPS,
            field: 'active',
            headerName: 'Aktiivne',
            minWidth: 80,
            maxWidth: 100,
            renderCell: params => params.value ? <StaticIcon type="CHECK" /> : ''
        } as GridColDef] : []),
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'comment',
            headerName: 'Kommentaar',
            minWidth: 100,
        },
        {
            ...DEFAULT_MENU_COL_DEF_PROPS,
            minWidth: 160,
            flex: 0,
            renderCell: params => (
                <Visible permission={Permission.UpdateTrips}>
                    <Box>
                        <NavIcon type="COPY" id={params.row.id} route={routes.AUTHENTICATED.TRIPS.ADD} alignTooltipArrowRight
                                 copyState={{copiedTripDefinition: rows?.find(row => row.id === params.id)}} />
                        <ActionIcon type={"END_TRIP"} id={params.row.id} alignTooltipArrowRight
                                    handleClick={() => setEndTripDialogData({trip: params.row, date: null})} />
                        <NavIcon type="START_NEW" id={params.row.id} route={routes.AUTHENTICATED.TRIPS.ADD} alignTooltipArrowRight
                                 copyState={{previousTripDefinitionVersion: rows?.find(row => row.id === params.id)}} />
                        <NavIcon type="EDIT" id={params.row.id} route={routes.AUTHENTICATED.TRIPS.DETAILS} alignTooltipArrowRight />
                    </Box>
                </Visible>
            )
        }
    ];

    return (
        <ListView
            headerProps={{
                title: 'Reisid',
                buttonProps: {
                    title: 'Lisa reis',
                    onClick: handleAddTripDefinition,
                    permission: Permission.UpdateTrips
                },
                showRegionSelect: true,
            }}
            isLoading={!rows}
        >
            <DataGrid
                rows={getFilteredRows(rows ?? [])}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                filterToolbar={
                    <FilterToolbar
                        inputSearch={{value: searchInput, updateValue: setSearchInput}}
                        filterChips={
                            tripFilterChipValues.map(value => ({
                                selected: selectedFilterChips.includes(value),
                                handleSelectClick: handleSelectedFilterChipsChange,
                                value: value
                            })) as FilterChipProps<TripFilterChipType>[]
                        }
                    />
                }
            />
            {endTripDialogData &&
                <EndTripDialog
                    dialogData={endTripDialogData}
                    handleCloseDialog={handleClose}
                    handleSave={handleEndTrip}
                    handleConfirm={handleConfirmEndTrip}
                />
            }
        </ListView>
    );
}
