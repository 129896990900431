import React from 'react';
import {BreathalyzerResultType, WorkSheetBreathalyzerResult} from "../../../../API/breathalyzerResults/types";
import StaticIcon, {StaticIconType} from "../../../../components/Icon/StaticIcon";
import {getDisplayDateTime, getDisplayTime} from "../../../../utils/dateUtils";
import dayjs from "dayjs";


const BreathalyzerIcon = ({iconType, color, date}: {iconType: StaticIconType, color: string, date: string}) => (
    <StaticIcon
        type={iconType}
        color={color}
        tooltipTitle={iconType === 'ALERT'
            ? (`Puhumise tulemus puudub! Väljumine: ${getDisplayTime(date)}`)
            : (`Tulemuse aeg: ${getDisplayDateTime(date)}`)
        }
    />
);

interface BreathalyzerCellProps {
    breathalyzerResult?: WorkSheetBreathalyzerResult;
    departureTime?: string;
}

const BreathalyzerCell = ({breathalyzerResult, departureTime}: BreathalyzerCellProps) => {
    if (!breathalyzerResult) {
        if (!departureTime) return null;
        return dayjs(departureTime).subtract(15, 'minutes').isBefore(dayjs(), 'minutes') ? (
            <BreathalyzerIcon iconType="ALERT" color="warning.main" date={departureTime} />
        ) : null;
    }

    switch (breathalyzerResult.breathalyzerResultType) {
        case BreathalyzerResultType.PASSED:
            return <BreathalyzerIcon iconType="CHECK" color="secondary.main" date={breathalyzerResult.breathalyzerResultDateTime} />;
        case BreathalyzerResultType.FAILED:
            return <BreathalyzerIcon iconType="CLEAR" color="error.main" date={breathalyzerResult.breathalyzerResultDateTime} />;
    }
};

export default BreathalyzerCell;
