import React, {useState} from 'react';
import {useAppDispatch} from "../../../../../../../hooks";
import {Box, Typography} from "@mui/material";
import {BusDetails, OdometerReading} from "../../../../../../../API/bus/types";
import {Delete, Edit, LocalGasStation, Speed} from "@mui/icons-material";
import {OdometerAndRefuellingData} from "../../../../../../../features/Form/ReadingForm/ReadingForm";
import dayjs from "dayjs";
import {deleteOdometerReading} from "../../../../../../../store/currentDriverWorkSheetsSlice";
import ReadingFormWrapper from "../../../components/ReadingFormWrapper";
import {PaginatedItems} from "../../../../../../../components/PaginatedItems/PaginatedItems";
import {useAuth} from "../../../../../../../contexts/AuthContext";
import Button from "../../../../../../../components/Button/Button";
import IconButton from "@mui/material/IconButton";
import {getFuelTranslation, getFuelUnit} from "../../../../../../../utils/enumTranslations";


interface OdometerReadingsTabProps {
    bus: BusDetails;
}

const OdometerReadingsTab = ({bus}: OdometerReadingsTabProps) =>  {
    const {authenticatedUser} = useAuth();
    const dispatch = useAppDispatch();
    const [readingFormData, setReadingFormData] = useState<OdometerAndRefuellingData | undefined>(undefined);

    const handleOdometerToggleClick = () => {
        if (readingFormData && !readingFormData.includeRefuelling && !readingFormData.copiedData) {
            setReadingFormData(undefined);
        } else {
            setReadingFormData({includeRefuelling: false});
        }
    };

    const handleRefuellingToggleClick = () => {
        if (readingFormData && readingFormData.includeRefuelling && !readingFormData.copiedData) {
            setReadingFormData(undefined);
        } else {
            setReadingFormData({includeRefuelling: true});
        }
    };

    const handleEditReadingButtonClick = (reading: OdometerReading) => {
        if (!readingFormData || readingFormData.copiedData?.id !== reading.id) {
            setReadingFormData({
                includeRefuelling: !!reading.fuelType,
                copiedData: reading
            });
        } else {
            setReadingFormData(undefined);
        }
    };

    const handleDeleteReadingButtonClick = (reading: OdometerReading) => {
        if (bus && confirm(`Kas soovid ${(reading.fuelType ? 'tankimise' : 'odomeetri näidu')} ${dayjs(reading.dateTime).format('DD.MM.YYYY HH:mm')} (${reading.reading} km) kustutada?`)) {
            dispatch(deleteOdometerReading({
                busId: bus.id,
                readingId: reading.id,
                data: reading,
            }));
        }
    };

    const handleFormClose = () => setReadingFormData(undefined);

    return (
        <>
            <Box sx={{mt: 2, display: 'flex', gap: 1}}>
                <Box sx={{textAlign: 'center', width: '50%'}}>
                    <Button text="+ tankimine"
                            variant={readingFormData?.includeRefuelling && !readingFormData.copiedData ? 'contained' : 'outlined'}
                            onClick={handleRefuellingToggleClick}
                            startIcon={<LocalGasStation />}
                            styles={{width: '100%'}}
                    />
                </Box>
                <Box sx={{textAlign: 'center', width: '50%'}}>
                    <Button text="+ näit"
                            variant={readingFormData && !readingFormData.includeRefuelling && !readingFormData.copiedData ? 'contained' : 'outlined'}
                            onClick={handleOdometerToggleClick}
                            startIcon={<Speed />}
                            styles={{width: '100%'}}
                    />
                </Box>
            </Box>
            <Box sx={{my: 1}}>
                {readingFormData && !readingFormData.copiedData &&
					<ReadingFormWrapper
						bus={bus}
						includeRefuelling={readingFormData.includeRefuelling}
						handleClose={handleFormClose}
						isBusViewAddForm={!readingFormData.includeRefuelling}
					/>
                }
            </Box>
            {bus.odometerReadings.length > 0 &&
				<Box pt={2}>
					<PaginatedItems
						items={[...bus.odometerReadings].sort((a, b) => b.dateTime.localeCompare(a.dateTime))}
						itemsPerPage={10}
						renderItem={(reading, index, {length}) =>
                            <Box key={reading.id} sx={{
                                mb: .5, pb: .5, borderBottom: index + 1 < length ? '1px solid #ebebeb' : undefined
                            }}>
                                <Box sx={{display: 'flex', flexDirection: 'row-reverse', alignItems: 'center'}}>
                                    <Box sx={{minWidth: '60px', minHeight: '28px', ml: 0.5}}>
                                        {authenticatedUser?.driverId === reading.driverId &&
											<>
												<IconButton size="small" onClick={() => handleEditReadingButtonClick(reading)}>
													<Edit fontSize="small" sx={{color:"text.secondary"}} />
												</IconButton>
												<IconButton size="small" onClick={() => handleDeleteReadingButtonClick(reading)}>
													<Delete fontSize="small" sx={{color:"text.secondary"}} />
												</IconButton>
											</>
                                        }
                                    </Box>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', flexGrow: 1, alignItems: 'center'}}>
                                        <Box>
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                <Speed sx={{color: 'text.secondary', height: 16, width: 16, mr: .5}} />
                                                <Typography variant="body2">{reading.reading.toLocaleString('et-EE')} km</Typography>
                                            </Box>
                                            {reading.fuelType ?
                                                <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                    <LocalGasStation sx={{color: "text.secondary", height: 16, width: 16, mr: .5}} />
                                                    <Typography variant="body2">
                                                        {getFuelTranslation(reading.fuelType)}: {reading.fuelAmount?.toLocaleString('et-EE')} {getFuelUnit(reading.fuelType)}
                                                    </Typography>
                                                </Box>
                                                : null
                                            }
                                        </Box>
                                        <Typography variant="body2">{dayjs(reading.dateTime).format('DD.MM.YYYY HH:mm')}</Typography>
                                    </Box>
                                </Box>
                                {readingFormData && readingFormData.copiedData?.id === reading.id &&
									<ReadingFormWrapper
										bus={bus}
										includeRefuelling={readingFormData.includeRefuelling}
										copiedData={readingFormData.copiedData}
										handleClose={handleFormClose}
									/>
                                }
                            </Box>
                        }
					/>
				</Box>
            }
        </>
    );
}

export default OdometerReadingsTab;
