import React, {ReactElement} from "react";
import InputSearch, {InputSearchProps} from "./components/InputSearch";
import FilterChip, {FilterChipProps} from "./components/FilterChip";
import DataRefresh from "./components/DataRefresh";
import {Box} from "@mui/material";
import {useDayNavigation} from "../../store/viewSlice";
import {Dayjs} from "dayjs";
import ToolbarContainer from "./components/ToolbarContainer";
import DateNavigator from "../DateNavigator/DateNavigator";


interface FilterToolbarProps {
    dateNavEndAdornment?: ReactElement;
    inputSearch?: InputSearchProps,
    refreshTime?: Dayjs | null,
    handleRefreshed?: () => void,
    filterChips: FilterChipProps[][],
}

const AdvancedFilterToolbar = ({dateNavEndAdornment, inputSearch, refreshTime, handleRefreshed, filterChips}: FilterToolbarProps) => {
    const [date, handleChangeDate] = useDayNavigation();

    return (
        <ToolbarContainer sx={{flexDirection: 'row', gap: 1, flexWrap: {xs: 'wrap-reverse', lg: 'nowrap'}}}>
            <Box sx={{display: 'flex', order: 1}}>
                <DateNavigator date={date} handleSelectedDateChange={handleChangeDate} unit="day" />
                {dateNavEndAdornment}
            </Box>
            {(inputSearch || filterChips.length > 0) &&
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 1,
                    rowGap: 1.5,
                    flexWrap: {xs: 'wrap-reverse', md: 'nowrap'},
                    width: '100%',
                    order: {xs: 3, lg: 2},
                }}>
                    {inputSearch &&
                        <Box sx={{width: {xs: '100%', md: 'fit-content'}}}>
                            <InputSearch {...inputSearch} />
                        </Box>
                    }
                    <Box sx={{display: 'flex', gap: 0.5, flexWrap: 'wrap'}}>
                        {filterChips.flatMap((filterChipGroup, groupIndex) =>
                            filterChipGroup.map((filterChip, itemIndex) =>
                                <Box key={filterChip.value}
                                     sx={(groupIndex < (filterChips.length - 1) && itemIndex === filterChipGroup.length - 1)
                                         ? {pr: 1, mr: 0.5, borderRight: '2px solid', borderColor: 'divider'}
                                         : {}
                                     }
                                >
                                    <FilterChip {...filterChip} />
                                </Box>
                            )
                        )}
                    </Box>
                </Box>
            }
            {refreshTime && handleRefreshed &&
                <Box sx={{order: {xs: 2, lg: 3}}}>
                    <DataRefresh refreshTime={refreshTime} handleRefreshed={handleRefreshed} />
                </Box>
            }
        </ToolbarContainer>
    );
};

export default AdvancedFilterToolbar;
