import React from "react";
import {GeoPoint, WorkGroupActivity, WorkGroupTripDefinition} from "../../../../../API/types";
import DataGrid, {DEFAULT_COL_DEF_PROPS} from "../../../../../components/DataGrid/DataGrid";
import {Box, Typography} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {getWorkGroupItemTypeTranslation} from "../../../../../utils/enumTranslations";
import NavLink from "../../../../../components/NavLink/NavLink";
import routes from "../../../../../routes";
import {Permission} from "../../../../../types";
import {getDisplayTimeWithModifier} from "../../../../../utils/dateUtils";
import {decimalToStr} from "../../../../../utils/utils";

interface WorkGroupItemsProps {
    items: (WorkGroupActivity | WorkGroupTripDefinition)[] | undefined;
}

export default function WorkGroupItems({items}: WorkGroupItemsProps) {
    if (typeof items === 'undefined' || items.length === 0) {
        return null;
    }

    const columns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'type',
            headerName: 'Tegevus',
            sortable: false,
            minWidth: 130,
            maxWidth: 180,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'code',
            headerName: 'Kood',
            sortable: false,
            disableColumnMenu: true,
            minWidth: 90,
            maxWidth: 120,
            renderCell: (params) => params.value
                ? <NavLink id={params.row.tripId} value={params.row.code} route={routes.AUTHENTICATED.TRIPS.DETAILS} navPermission={Permission.UpdateTrips} openInNewWindow={true} />
                : <></>
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'lineNumber',
            headerName: 'Liin',
            sortable: false,
            minWidth: 60,
            maxWidth: 100,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'route',
            headerName: 'Teekond',
            sortable: false,
            minWidth: 150,
            maxWidth: 400,
            valueFormatter: params => params.value
                ? params.value.map((item: GeoPoint) => item.name).join(' - ')
                : null,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'startTimeWithModifier',
            headerName: 'Algusaeg',
            sortable: false,
            minWidth: 90,
            maxWidth: 110,
            valueGetter: (params) =>
                getDisplayTimeWithModifier(params.row.startTime, params.row.startTimeIsOnNextDay),
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'endTimeWithModifier',
            headerName: 'Lõpuaeg',
            sortable: false,
            minWidth: 90,
            maxWidth: 110,
            valueGetter: (params) =>
                getDisplayTimeWithModifier(params.row.endTime, params.row.endTimeIsOnNextDay),
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'distance',
            headerName: 'Läbisõit',
            sortable: false,
            minWidth: 80,
            valueGetter: (params) => params.value ? decimalToStr(params.value) : ''
        },
    ];

    return (
        <Box my={2}>
            <Typography variant="h5" my={1}>Reisid ja tegevused</Typography>
            <DataGrid
                rows={items.map((item, key) => ({
                    ...item,
                    type: getWorkGroupItemTypeTranslation(item.type),
                    id: item.id ?? key,
                }))}
                columns={columns}
            />
        </Box>
    );
}