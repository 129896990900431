import * as React from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    GridColDef,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
    GridRow
} from '@mui/x-data-grid';
import DataGrid from "./DataGrid";
import DateNavigator from "../DateNavigator/DateNavigator";
import {Dayjs} from "dayjs";
import {useEffect} from "react";
import {setToast} from "../../store/toastSlice";
import {useAppDispatch} from "../../hooks";
import {mapErrors} from "../../utils/errorMapping";
import Tooltip from "../Tooltip/Tooltip";


export const defaultColDef: GridColDef = {
    field: '',
    headerName: '',
    sortable: false,
    filterable: false,
    editable: true,
    disableColumnMenu: true,
    align: 'left',
    headerAlign: 'left',
    width: 120,
};

const numberValueParser = (value: string) => {
    // keep only commas and digits
    const sanitisedValue = value.replace('.', ',').replace(/[^0-9,]/g, '');

    const parts = sanitisedValue.split(',');
    // remove leading zeros
    const integerPart = parts[0].length > 0 ? parts[0].replace(/^0+(?=[0-9,])/, '') : parts[0];
    // truncate to two decimals and remove extra commas
    let decimalPart: string | undefined;
    if (parts.length > 1) {
        const decimals = parts.slice(1).join('');
        decimalPart = decimals.length > 2 ? decimals.substring(0, 2) : decimals;
    }

    return integerPart + (decimalPart !== undefined ? `,${decimalPart}` : '');
};

export const numberColDef: GridColDef = {
    ...defaultColDef,
    type: 'string',
    valueParser: numberValueParser
};

type InitialRow<T extends object> = T & { id: string | number, disabled: boolean };

interface FullFeaturedCrudGridProps<T extends object> {
    initialRows: InitialRow<T>[];
    columns: GridColDef[];
    monthSelect?: {
        month: Dayjs;
        handleChangeMonth: (month: Dayjs) => void;
        defaultMonth?: Dayjs;
    };
    handleSave: (row: T) => Promise<void>;
    getErrors: (row: T) => string | undefined;
    disabledRowText?: string;
}

export default function EditableDataGrid<T extends object>({initialRows, columns, monthSelect, handleSave, getErrors, disabledRowText}: FullFeaturedCrudGridProps<T>) {
    const dispatch = useAppDispatch();
    const [rows, setRows] = React.useState<GridRowsProp>(initialRows);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    useEffect(() => {
        setRows(initialRows);
    }, [initialRows]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = async (newRow: GridRowModel) => {
        const error = getErrors(newRow as T);
        if (error) throw new Error(error);

        const updatedRow = { ...newRow, isNew: false };
        await handleSave(newRow as T)
            .then(() =>
                setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
            )
            .catch(apiError => {
                throw new Error(mapErrors(apiError) ?? 'Rea salvestamisel esines viga');
            });

        return updatedRow;
    };

    const onProcessRowUpdateError = (error: Error) => dispatch(setToast({type: 'error', text: error.message}));

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columnsWithActions: GridColDef[] = [
        ...columns,
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            flex: 1,
            minWidth: 80,
            align: 'right',
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
                if (row.disabled) return [];

                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key={1}
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{color: 'primary.main'}}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key={2}
                            icon={<CancelIcon sx={{color: 'text.secondary'}} />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key={3}
                        icon={<EditIcon sx={{color: 'text.secondary'}} />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                    />,
                ];
            },
        },
    ];

    const CustomTooltipRowWrapper = (props: any) => {
        const { row } = props;
        const isDisabled = row?.disabled;

        return (
            <Box sx={{'& .MuiDataGrid-cell': {color: isDisabled ? 'text.secondary' : ''}}}>
                <Tooltip title={isDisabled ? disabledRowText : undefined}>
                    <GridRow {...props} />
                </Tooltip>
            </Box>
        );
    };

    return (
        <>
            {monthSelect &&
                <Box sx={{display: 'flex', justifyContent: 'space-between',  alignItems: 'flex-end', mb: 1}}>
                    <DateNavigator
                        date={monthSelect.month}
                        handleSelectedDateChange={monthSelect.handleChangeMonth}
                        unit={'month'}
                        defaultDate={monthSelect.defaultMonth}
                    />
                </Box>
            }
            <DataGrid
                rows={rows}
                columns={columnsWithActions}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={onProcessRowUpdateError}
                isCellEditable={(params) => !params.row.disabled}
                slots={{
                    row: CustomTooltipRowWrapper,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
            />
        </>
    );
}
