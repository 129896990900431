import React, {useEffect, useMemo, useState} from "react";
import {Box, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {
    createWorkItem,
    deleteOdometerReading,
    deleteWorkItem,
    deleteWorkScheduleItem,
    getWorkSheet,
    removeWorkItemResource,
    saveOdometerReading,
    saveTripWorkItemsOnDate,
    saveTripWorkItemsWithRouteOnDate,
    updateOdometerReading as apiUpdateOdometerReading,
    updateWorkItem,
    updateWorkScheduleItem,
} from "../../../../API";
import {
    ApiError,
    OdometerReadingRequest,
    OdometerReadingWithBusId,
    ResourceType,
    RoutePointRequest,
    SaveWorkItem,
    SaveWorkScheduleItem,
    WorkGroupItemType,
    WorkScheduleItemStatus,
    WorkScheduleItemType,
    WorkSheetDetails as ApiWorkSheetDetails,
    WorkSheetWorkItem
} from "../../../../API/types";
import LoaderWithChildren from "../../../../components/Loader/LoaderWithChildren";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {fetchBuses, selectAllBuses} from "../../../../store/busSlice";
import {fetchDrivers} from "../../../../store/driverSlice";
import {Add, ArrowBack, Delete, NextWeekOutlined, WorkOffOutlined} from "@mui/icons-material";
import Button from "../../../../components/Button/Button";
import routes from "../../../../routes";
import WorkItemDialog, {WorkItemDialogData} from "./components/dialogs/WorkItemDialog";
import {ensureGeoPoints, fetchGeoPoints, selectAllGeoPoints} from "../../../../store/geoPointSlice";
import {WorkItemForm} from "../types";
import {setToast} from "../../../../store/toastSlice";
import {mapErrors} from "../../../../utils/errorMapping";
import {correspondsWith, strToDecimal} from "../../../../utils/utils";
import SplitWorkItemDialog, {
    SplitWorkItemDialogData,
    SplitWorkItemFormData
} from "./components/dialogs/SplitWorkItemDialog";
import {getOdometerReadingRequest, ReadingFormData} from "../../../../features/Form/ReadingForm/ReadingForm";
import {FormikHelpers} from "formik";
import EditReadingDialog, {EditReadingDialogData} from "./components/dialogs/EditReadingDialog";
import {getDayjsDateTimeFromDateWithModifier, getHhMmFromDate} from "../../../../utils/dateUtils";
import AddReadingDialog, {AddReadingDialogData} from "./components/dialogs/AddReadingDialog";
import {selectBusOrDriverIdByResourceId, selectResourceNameByTypeNullable} from "../store/selectors";
import {TripWorkItemChanges, TripWorkItemChangesWithRoute} from "../../../../API/trips/types";
import {AddReadingFormData} from "./components/odometerReadings/AddReadingForm";
import {WorkGroupItemTypeCharterTrip} from "../../../../API/workSheets/types";
import dayjs from "dayjs";
import ButtonPairContainer from "../../../../components/Button/ButtonPairContainer";
import DeleteWorkItemDialog from "./components/dialogs/DeleteWorkItemDialog";
import DayLengthWarning from "./components/alerts/DayLengthWarning";
import DistanceDifferenceWarning from "./components/alerts/DistanceDifferenceWarning";
import DriverComment from "./components/alerts/DriverComment";
import WorkItems from "./components/WorkItems";
import TextButton from "../../../../components/Button/TextButton";
import OdometerReadings from "./components/odometerReadings/OdometerReadings";
import {BusUsageWithDistance} from "../../dashboard/DriverDashboard/types";
import {getBusUsages, getReadingRows} from "./utils";
import {useAuth} from "../../../../contexts/AuthContext";
import {Permission} from "../../../../types";
import ErrorWrapper from "../../../../components/ErrorWrapper/ErrorWrapper";
import {AxiosError, HttpStatusCode} from "axios";


export default function WorkSheetDetailsView() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {hasPermission} = useAuth();
    const { id} = useParams();
    const [workSheet, setWorkSheet] = useState<ApiWorkSheetDetails | undefined>(undefined);
    const busOrDriverId = useAppSelector(state => selectBusOrDriverIdByResourceId(state, workSheet?.resourceId ?? 0, workSheet?.resourceType ?? ResourceType.DRIVER));
    const [secondaryWorkSheets, setSecondaryWorkSheets] = useState<ApiWorkSheetDetails[]>([]);
    const [editWorkItemDialogData, setEditWorkItemDialogData] = useState<WorkItemDialogData | undefined>(undefined);
    const [splitWorkItemDialogData, setSplitWorkItemDialogData] = useState<SplitWorkItemDialogData | undefined>(undefined);
    const [editReadingDialogData, setEditReadingDialogData] = useState<EditReadingDialogData | undefined>(undefined);
    const [addReadingDialogData, setAddReadingDialogData] = useState<AddReadingDialogData | undefined>(undefined);
    const [deleteWorkItemDialogData, setDeleteWorkItemDialogData] = useState<WorkItemDialogData | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string|undefined>(undefined);
    const [secondaryWorkSheetsForbidden, setSecondaryWorkSheetsForbidden] = useState(false);
    const resourceName = useAppSelector(state => selectResourceNameByTypeNullable(state, workSheet?.resourceId, workSheet?.resourceType));
    const buses = useAppSelector(selectAllBuses);
    const geoPoints = useAppSelector(selectAllGeoPoints);

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            setError(undefined);
            getWorkSheet(id)
                .then(({workSheet, secondaryWorkSheets, secondaryWorksheetsForbidden}) => {
                    setWorkSheet(workSheet);
                    setSecondaryWorkSheets(secondaryWorkSheets);
                    setSecondaryWorkSheetsForbidden(secondaryWorksheetsForbidden);
                })
                .catch((error: AxiosError) => {
                    setWorkSheet(undefined);
                    setSecondaryWorkSheets([]);
                    if (error.request.status === HttpStatusCode.Forbidden) {
                        setError('Puuduvad õigused vaadata sõidulehte selles piirkonnas');
                    } else {
                        dispatch(setToast({type: 'error', text: 'Sõidulehe pärimisel ilmnes viga'}));
                    }
                })
                .finally(() => setIsLoading(false));
        }
    }, [id]);

    useEffect(() => {
        dispatch(fetchBuses()); // TODO not fetch buses when edit not allowed
        dispatch(fetchDrivers()); // TODO not fetch drivers when edit not allowed
        dispatch(ensureGeoPoints());
    }, []);

    const sortedWorkItems: WorkSheetWorkItem[] = useMemo(() => {
        return workSheet
            ? [...workSheet.workItems]
                .sort((a, b) => a.startDateTime.localeCompare(b.startDateTime))
                .map((item, index, array) => ({
                    ...item,
                    warning: array[index-1]?.endDateTime > item.startDateTime,
                }))
            : [];
    }, [workSheet]);

    const [odometerReadingRows, busUsages] = useMemo(() => {
        if (!workSheet || workSheet.resourceType === ResourceType.VEHICLE) return [[], []];
        const busUsages: BusUsageWithDistance[] = getBusUsages(sortedWorkItems, secondaryWorkSheets, workSheet.id);
        return [getReadingRows(workSheet, busUsages, buses, busOrDriverId), busUsages];
    }, [sortedWorkItems, secondaryWorkSheets, busOrDriverId]);

    const handleCloseForm = () => setEditWorkItemDialogData(undefined);

    const getOrderOfFirstPoint = (formData: WorkItemForm, workItem: WorkSheetWorkItem | undefined) => {
        if (formData.route.length > 0 && workItem?.route && workItem.route.length > 0) {
            return Math.min(...workItem.route.map(p => p.order));
        } else {
            return 1;
        }
    };

    const handleSave = (formData: WorkItemForm, workItem?: WorkSheetWorkItem, updatedReadings?: OdometerReadingWithBusId[]) => {
        const startTime = getDayjsDateTimeFromDateWithModifier(formData.startTime, formData.startTimeIsOnNextDay, workSheet?.startDate);
        const endTime = getDayjsDateTimeFromDateWithModifier(formData.endTime, formData.endTimeIsOnNextDay, workSheet?.startDate);
        const orderOfFirstPoint = getOrderOfFirstPoint(formData, editWorkItemDialogData?.workItem);
        const route: RoutePointRequest[] = formData.route.length > 0
            ? formData.route.map((point, index) => {
                const existingPoint = geoPoints.find(existingPoint => correspondsWith(point, existingPoint));
                if (existingPoint) {
                    return {...point, ...existingPoint, order: orderOfFirstPoint + index, time: point.time ? getHhMmFromDate(point.time) : undefined};
                } else {
                    return {...point, name: point.geoPoint?.name ?? '', order: orderOfFirstPoint + index, time: point.time ? getHhMmFromDate(point.time) : undefined};
                }
            })
            : [];
        const saveWorkItem: SaveWorkItem = {
            busWorkSheetId: workSheet?.resourceType === ResourceType.VEHICLE ? workSheet.id : formData.oppositeResource?.id ?? null,
            date: workSheet?.startDate ?? '',
            distance: strToDecimal(formData.distance ?? ''),
            comment: formData.comment.length > 0 ? formData.comment : null,
            driverWorkSheetId: workSheet?.resourceType === ResourceType.DRIVER ? workSheet.id : formData.oppositeResource?.id ?? null,
            endDateTime: endTime.format('YYYY-MM-DDTHH:mm:ss'),
            startDateTime: startTime.format('YYYY-MM-DDTHH:mm:ss'),
            tripDefinitionId: formData.trip?.id ?? undefined,
            charterTripId: formData.charterTrip?.id ?? undefined,
            route: route,
            type: formData.type === WorkGroupItemTypeCharterTrip.CHARTER_TRIP ? WorkGroupItemType.TRIP_DEFINITION : formData.type,
            defectId: formData.defectId ?? undefined,
        };

        if (workItem) {
            return updateWorkItem(workItem.id, saveWorkItem)
                .then(savedWorkItem => {
                    getSuccessfullyUpdatedReadings(updatedReadings)
                        .then((readingsResult) => updateWorkItemOnSuccessfulSave(savedWorkItem, workItem, readingsResult))
                        .catch(() => updateWorkItemOnSuccessfulSave(savedWorkItem, workItem, []));
                    dispatch(fetchGeoPoints());
                });
        } else {
            return createWorkItem(saveWorkItem)
                .then(workItem => {
                    getSuccessfullyUpdatedReadings(updatedReadings)
                        .then((readingsResult) => addWorkItemOnSuccessfulSave(workItem, readingsResult))
                        .catch(() => addWorkItemOnSuccessfulSave(workItem, []));
                });
        }
    };

    const updateWorkItemOnSuccessfulSave = (savedWorkItem: WorkSheetWorkItem, workItem: WorkSheetWorkItem, readingsResult: OdometerReadingWithBusId[]) => {
        fetchAdditionalSecondaryWorkSheet(savedWorkItem);
        setWorkSheet(prevState => {
            if (!prevState) {
                return prevState;
            }
            const existingItemId = prevState.workItems.findIndex(item => item.id === workItem.id);
            const workItems = [...prevState.workItems];
            if (existingItemId > -1) {
                workItems.splice(existingItemId, 1, {
                    ...savedWorkItem,
                });
            } else {
                workItems.push(savedWorkItem);
            }

            return {
                ...prevState,
                workItems: workItems,
                odometerReadings: getUpdatedWorkItemReadings(prevState.odometerReadings, readingsResult),
            };
        });
    };

    const addWorkItemOnSuccessfulSave = (workItem: WorkSheetWorkItem, readingsResult: OdometerReadingWithBusId[]) => {
        setWorkSheet(prevState => {
            if (!prevState) {
                return prevState;
            }

            return {
                ...prevState,
                workItems: [...prevState.workItems, workItem],
                odometerReadings: getUpdatedWorkItemReadings(prevState.odometerReadings, readingsResult),
            };
        });
    };

    const removeWorkItemFromWorkSheet = (workItem: WorkSheetWorkItem, updatedReadings: OdometerReadingWithBusId[]) => {
        setWorkSheet(prevState => {
            if (!prevState) {
                return prevState;
            }

            return {
                ...prevState,
                workItems: prevState.workItems.filter(item => item.id !== workItem.id),
                odometerReadings: getUpdatedWorkItemReadings(prevState.odometerReadings, updatedReadings),
            };
        });
    };

    const handleRemove = (workItem: WorkSheetWorkItem, removeBoth: boolean, updatedReadings: OdometerReadingWithBusId[]) => {
        const workItemResourceId = workSheet?.resourceType === ResourceType.VEHICLE
            ? workItem.busWorkSheetId
            : workItem.driverWorkSheetId;

        if (workItemResourceId) {
            (removeBoth ? deleteWorkItem(workItem.id) : removeWorkItemResource(workItem.id, workItemResourceId))
                .then(() => {
                    getSuccessfullyUpdatedReadings(updatedReadings)
                        .then((readingsResult) => removeWorkItemOnSuccessfulDelete(workItem, readingsResult))
                        .catch(() => removeWorkItemOnSuccessfulDelete(workItem, []));
                })
                .catch(error => {
                    dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Tegevuse eemaldamisel tekkis viga'}));
                }).finally(() => setDeleteWorkItemDialogData(undefined));
        }
    };

    const removeWorkItemOnSuccessfulDelete = (workItem: WorkSheetWorkItem, readingsResult: OdometerReadingWithBusId[]) => {
        removeWorkItemFromWorkSheet(workItem, readingsResult);
        dispatch(setToast({type: 'success', text: 'Tegevus edukalt kustutatud'}));
    };

    const isOriginalResource = (workItem: {driverWorkSheetId?: number | null, busWorkSheetId?: number | null}) => {
        const workSheetId = id ? parseInt(id) : 0;

        return workSheet?.resourceType === ResourceType.DRIVER
            ? workSheetId === workItem.driverWorkSheetId
            : workSheetId === workItem.busWorkSheetId
    };

    const handleEdit = (workItem: WorkSheetWorkItem) => {
        if (workSheet) {
            setEditWorkItemDialogData({
                workSheet: workSheet,
                workItem: workItem,
            });
        }
    };

    const handleSplit = (workItem: WorkSheetWorkItem) => {
        if (workItem) {
            setSplitWorkItemDialogData({ workItem: workItem, workSheet: workSheet });
        }
    };
    const handleCloseSplitDialog = () => setSplitWorkItemDialogData(undefined);
    const handleSaveSplitWorkItem = (tripId: number, formData: SplitWorkItemFormData, isAvlVersion: boolean) => {
        if (workSheet) {
            if (isAvlVersion) {
                const updatedWorkItems: TripWorkItemChangesWithRoute[] = formData.parts.map(workItem => ({
                    id: workItem.workItemId,
                    routePointId: workItem.splitRoutePoint?.id ?? 0,
                    distance: strToDecimal(workItem.distance ?? ''),
                    busWorkSheetId: workItem.busWorkSheetId?.id,
                    driverWorkSheetId: workItem.driverWorkSheetId?.id,
                }));

                saveTripWorkItemsWithRouteOnDate(tripId, updatedWorkItems, workSheet.startDate)
                    .then((result) => {
                        fetchAdditionalSecondaryWorkSheets(result);
                        upsertWorkSheetWorkItems(result, tripId);
                        dispatch(setToast({type: 'success', text: 'Reis edukalt poolitatud'}));
                        setSplitWorkItemDialogData(undefined);
                    })
                    .catch((error: ApiError) => {
                        dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Reisi salvestamisel tekkis viga'}));
                    });
            } else {
                const updatedWorkItems: TripWorkItemChanges[] = formData.parts.map(workItem => ({
                    id: workItem.workItemId,
                    tripSegmentId: workItem.tripSegmentId,
                    startDateTime: getDayjsDateTimeFromDateWithModifier(workItem.startTime, workItem.startTimeIsOnNextDay, workSheet?.startDate).format('YYYY-MM-DDTHH:mm:ss'),
                    endDateTime: getDayjsDateTimeFromDateWithModifier(workItem.endTime, workItem.endTimeIsOnNextDay, workSheet?.startDate).format('YYYY-MM-DDTHH:mm:ss'),
                    distance: strToDecimal(workItem.distance ?? ''),
                    busWorkSheetId: workItem.busWorkSheetId?.id,
                    driverWorkSheetId: workItem.driverWorkSheetId?.id,
                }));

                saveTripWorkItemsOnDate(tripId, updatedWorkItems, workSheet.startDate)
                    .then((result) => {
                        fetchAdditionalSecondaryWorkSheets(result);
                        upsertWorkSheetWorkItems(result, tripId);
                        dispatch(setToast({type: 'success', text: 'Reis edukalt poolitatud'}));
                        setSplitWorkItemDialogData(undefined);
                    })
                    .catch((error: ApiError) => {
                        dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Reisi salvestamisel tekkis viga'}));
                    });
            }
        }
    };

    const handleDeleteWorkSheet = () => {
        if (workSheet && confirm("Kas oled kindel, et soovid sõidulehe kustutada?")) {
            deleteWorkScheduleItem(workSheet.id)
                .then(() => {
                    dispatch(setToast({type: 'success', text: 'Sõiduleht edukalt kustutatud'}));
                    navigate(routes.AUTHENTICATED.WORK_SHEETS.ROOT);
                })
                .catch((apiError) => {
                    dispatch(setToast({type: 'error', text: mapErrors(apiError) ?? 'Sõidulehe kustutamisel ilmnes viga'}));
                });
        }
    };

    const fetchAdditionalSecondaryWorkSheet = (workItem: {driverWorkSheetId?: number | null, busWorkSheetId?: number | null}) => {
        const secondaryWorkSheetId = workSheet?.resourceType === ResourceType.DRIVER
            ? workItem.busWorkSheetId
            : workItem.driverWorkSheetId;

        if (secondaryWorkSheetId && !secondaryWorkSheets.find(workSheet => workSheet.id === secondaryWorkSheetId)) {
            getWorkSheet(secondaryWorkSheetId)
                .then(result => {
                    setSecondaryWorkSheets(prevState => {
                        return [...prevState, result.workSheet];
                    });
                })
                .catch(() => {
                    dispatch(setToast({type: 'error', text: 'Sõidulehe pärimisel ilmnes viga'}));
                });
        }
    };

    const fetchAdditionalSecondaryWorkSheets = (workItems: {driverWorkSheetId?: number | null, busWorkSheetId?: number | null}[]) => {
        const newSecondaryWorkSheetIds: number[] = [];

        workItems.forEach(workItem => {
            const secondaryWorkSheetId = workSheet?.resourceType === ResourceType.DRIVER
                ? workItem.busWorkSheetId
                : workItem.driverWorkSheetId;

            if (
                secondaryWorkSheetId && !newSecondaryWorkSheetIds.includes(secondaryWorkSheetId)
                && !secondaryWorkSheets.find(workSheet => workSheet.id === secondaryWorkSheetId)
            ) {
                newSecondaryWorkSheetIds.push(secondaryWorkSheetId);
            }
        });

        if (!newSecondaryWorkSheetIds.length) return;

        Promise.all(newSecondaryWorkSheetIds.map(async (secondaryWorkSheetId) => getWorkSheet(secondaryWorkSheetId)))
            .then(result => {
                setSecondaryWorkSheets(prevState => [...prevState, ...result.map(response => response.workSheet)]);
            })
            .catch(() => {
                dispatch(setToast({type: 'error', text: 'Sõidulehe pärimisel ilmnes viga'}));
            });
    };

    const upsertWorkSheetWorkItems = (workItems: WorkSheetWorkItem[], tripId: number) => {
        setWorkSheet(prevState => {
            if (!prevState) return prevState;

            const updatedWorkSheetWorkItems = [
                ...prevState.workItems.filter(workItem => workItem.trip?.id !== tripId),
                ...workItems.filter(workItem => isOriginalResource(workItem))
            ];

            return {
                ...prevState,
                workItems: updatedWorkSheetWorkItems,
            }
        });
    };

    const handleNew = () => {
        if (workSheet) {
            setEditWorkItemDialogData({
                workSheet: workSheet,
                workItem: undefined,
            });
        }
    };

    const handleConfirmChange = (newStatus: WorkScheduleItemStatus) => {
        if (workSheet) {
            if (newStatus === WorkScheduleItemStatus.GENERATED
                && !confirm('Kas soovid tagastatud sõidulehe uuesti genereeritud staatusesse viia?')
            ) return;

            const saveWorkSheet: SaveWorkScheduleItem = {
                ...workSheet,
                driverContractId: workSheet.resourceType === ResourceType.DRIVER ? workSheet.resourceId : undefined,
                busId: workSheet.resourceType === ResourceType.VEHICLE ? workSheet.resourceId : undefined,
                status: newStatus,
                comment: workSheet.comment ?? '',
                type: WorkScheduleItemType.WORK_GROUP
            };
            updateWorkScheduleItem(workSheet.id, saveWorkSheet)
                .then(() => {
                    setWorkSheet(prevState => prevState ? {...prevState, status: newStatus} : prevState);
                }).catch(error => {
                    dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Sõidulehe tagastamisel ilmnes viga'}));
                });
        }
    };

    const handleAddOdometerReadingClick = () => {
        if (workSheet && busOrDriverId && sortedWorkItems.length > 0) {
            setAddReadingDialogData({
                workSheet: workSheet,
                busUsages: busUsages,
                odometerReadings: odometerReadingRows,
                driverId: busOrDriverId,
            });
        }
    };

    const handleEditOdometerReadingClick = (reading: OdometerReadingWithBusId) => {
        if (workSheet) setEditReadingDialogData({workSheet: workSheet, reading: reading});
    };

    const handleCloseReadingDialog = () => setEditReadingDialogData(undefined);

    const handleSaveReading = (originalReading: OdometerReadingWithBusId, formData: ReadingFormData, helpers: FormikHelpers<ReadingFormData>) => {
        const data: OdometerReadingRequest = getOdometerReadingRequest(formData);

        apiUpdateOdometerReading(originalReading.id, data)
            .then(() => {
                dispatch(setToast({type: 'success', text: data.fuelType ? 'Tankimine uuendatud' : 'Odomeetri näit uuendatud'}));
                setWorkSheet(prevState => {
                    if (!prevState) return prevState;
                    const updatedReadings = [
                        ...prevState.odometerReadings.filter(reading => reading.id !== originalReading.id),
                        {...originalReading, ...data}
                    ];
                    return {...prevState, odometerReadings: updatedReadings};
                });
                helpers.setSubmitting(false);
                handleCloseReadingDialog();
            })
            .catch((error) => {
                helpers.setSubmitting(false);
                dispatch(setToast({
                    type: 'error',
                    text: mapErrors(error) ?? `${formData.fuelType ? 'Tankimise' : 'Odomeetri näidu'} uuendamisel ilmnes viga`
                }));
            });
    };

    const getSuccessfullyUpdatedReadings = (updatedReadings?: OdometerReadingWithBusId[]) => {
        return updatedReadings && updatedReadings.length > 0
            ? Promise
                .all(updatedReadings.map(async (updatedReading) => {
                    try {
                        await apiUpdateOdometerReading(updatedReading.id, updatedReading);
                        return updatedReading;
                    } catch (apiError) {
                        dispatch(setToast({type: 'error', text: mapErrors(apiError as ApiError) ?? 'Odomeetri näidu uuendamisel ilmnes viga'}));
                        return null;
                    }
                }))
                .then(results => results.filter(reading => reading !== null) as OdometerReadingWithBusId[])
            : Promise.resolve([]);
    };

    const getUpdatedWorkItemReadings = (originalReadings: OdometerReadingWithBusId[], updatedReadings: OdometerReadingWithBusId[]) => [
        ...originalReadings.filter(reading => !updatedReadings.some(successfulReading => successfulReading.id === reading.id)),
        ...updatedReadings
    ];

    const handleCloseAddReadingDialog = () => setAddReadingDialogData(undefined);

    const handleSaveNewReading = (formData: AddReadingFormData, helpers: FormikHelpers<AddReadingFormData>, busId: number) => {
        if (workSheet && workSheet.resourceType === ResourceType.DRIVER && busOrDriverId) {
            const request: OdometerReadingRequest = {
                driverId: busOrDriverId,
                ...getOdometerReadingRequest({
                    ...formData,
                    dateTime: formData.dateTime.toDate()
                })
            };

            saveOdometerReading(busId, request)
                .then(response => {
                    dispatch(setToast({type: 'success', text: `${response.fuelType ? 'Tankimine' : 'Odomeetri näit'} lisatud`}));
                    setWorkSheet(prevState => {
                        if (!prevState) return prevState;
                        const updatedReadings = [
                            ...prevState.odometerReadings,
                            {...response, busId: busId},
                        ];
                        return {...prevState, odometerReadings: updatedReadings};
                    });
                    helpers.setSubmitting(false);
                    handleCloseAddReadingDialog()
                })
                .catch((error) => {
                    helpers.setSubmitting(false);
                    dispatch(setToast({
                        type: 'error',
                        text: mapErrors(error) ?? `${formData.fuelType ? 'Tankimise' : 'Odomeetri näidu'} lisamisel ilmnes viga`
                    }));
                });
        }
    };

    const handleDeleteOdometerReadingClick = (reading: OdometerReadingWithBusId) => {
        if (confirm(`Kas soovid ${reading.fuelType ? 'tankimise' : 'odomteetri näidu'} kustutada?`)) {
            deleteOdometerReading(reading.id)
                .then((data) => {
                    dispatch(setToast({type: 'success', text: data.fuelType ? 'Tankimine kustutatud' : 'Odomeetri näit kustutatud'}));
                    setWorkSheet(prevState => {
                        if (!prevState) return prevState;
                        return {
                            ...prevState,
                            odometerReadings: prevState.odometerReadings
                                .filter(omr => omr.id !== reading.id)
                        };
                    })
                })
                .catch((error) => {
                    dispatch(setToast({
                        type: 'error',
                        text: mapErrors(error) ?? `${reading.fuelType ? 'Tankimise' : 'Odomeetri näidu'} kustutamisel ilmnes viga`
                    }));
                });
        }
    };

    const handleCloseDeleteDialog = () => setDeleteWorkItemDialogData(undefined);
    const handleDelete = (workItem: WorkSheetWorkItem) => {
        if (workSheet) {
            setDeleteWorkItemDialogData({
                workSheet: workSheet,
                workItem,
            });
        }
    };

    const editButtons = <>
        {workSheet?.status === WorkScheduleItemStatus.CONFIRMED ?
            <Button
                text="Tühista tagastamine"
                onClick={() => handleConfirmChange(WorkScheduleItemStatus.GENERATED)}
                startIcon={<WorkOffOutlined/>}
            />
            :
            <ButtonPairContainer
                firstButton={
                    <Button
                        text="Tagasta"
                        onClick={() => handleConfirmChange(WorkScheduleItemStatus.CONFIRMED)}
                        startIcon={<NextWeekOutlined/>}
                    />
                }
                secondButton={
                    <Button text="Lisa tegevus" onClick={handleNew} startIcon={<Add/>}/>
                }
            />
        }
    </>;

    return (
        <ErrorWrapper error={error}>
            <Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: {xs: 'column', md: 'row'},
                    pb: 1.5,
                    gap: 1
                }}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography component="h1" variant="h5" pt={{xs: 1, sm: 0}}>
                            Sõiduleht
                        </Typography>
                        {workSheet &&
                            <Typography variant="body2" color="text.secondary" mt={-0.5}>
                                {resourceName === '' ? 'Määramata' : resourceName} | {dayjs(workSheet.startDate).format('DD. MMMM')}{workSheet.region?.name && ` | ${workSheet.region.name}`}
                            </Typography>
                        }
                    </Box>
                    <Box sx={{display: 'flex', height: 'fit-content', gap: 1, flexDirection: {xs: 'column', sm: 'row'}}}>
                        {hasPermission(Permission.ListWorkSchedules) &&
                            <Button
                                text="Tagasi"
                                onClick={() => navigate(routes.AUTHENTICATED.WORK_SHEETS.ROOT)}
                                startIcon={<ArrowBack/>}
                                variant="outlined"
                            />
                        }
                        {hasPermission(Permission.UpdateWorkSchedules) && editButtons}
                    </Box>
                </Box>
                <LoaderWithChildren loading={isLoading}>
                    {workSheet &&
                        <>
                            <Box sx={{display: 'flex', flexWrap: 'wrap', flexDirection: {xs: 'column', sm: 'row'}}}>
                                <DayLengthWarning workSheet={workSheet}/>
                                <DistanceDifferenceWarning workSheet={workSheet} odometerReadingRows={odometerReadingRows}/>
                                <DriverComment workSheet={workSheet}/>
                            </Box>
                            <WorkItems
                                workSheet={workSheet}
                                workItems={sortedWorkItems}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                handleSplit={handleSplit}
                            />
                            { hasPermission(Permission.DeleteWorkSchedules) &&
                                workSheet.status !== WorkScheduleItemStatus.CONFIRMED &&
                                !workSheet.workGroupId &&
                                !workSheet.workItems.length &&
                                <TextButton text="Kustuta sõiduleht" startIcon={<Delete/>} onClick={handleDeleteWorkSheet}
                                            styles={{mt: 1}}/>
                            }
                            <OdometerReadings
                                workSheet={workSheet}
                                secondaryWorkSheets={secondaryWorkSheets}
                                odometerReadingRows={odometerReadingRows}
                                handleEditOdometerReading={handleEditOdometerReadingClick}
                                handleAddOdometerReading={handleAddOdometerReadingClick}
                                handleDeleteOdometerReading={handleDeleteOdometerReadingClick}
                                isForbidden={secondaryWorkSheetsForbidden}
                            />
                        </>
                    }
                </LoaderWithChildren>
                {editWorkItemDialogData &&
                    <WorkItemDialog
                        dialogData={editWorkItemDialogData}
                        handleCloseDialog={handleCloseForm}
                        handleSave={handleSave}
                        odometerReadingRows={odometerReadingRows}
                        busUsages={busUsages}
                        sortedWorkItems={sortedWorkItems}
                        secondaryWorkSheets={secondaryWorkSheets}
                    />
                }
                {splitWorkItemDialogData &&
                    <SplitWorkItemDialog
                        dialogData={splitWorkItemDialogData}
                        handleCloseDialog={handleCloseSplitDialog}
                        handleSave={handleSaveSplitWorkItem}
                    />
                }
                {editReadingDialogData &&
                    <EditReadingDialog
                        dialogData={editReadingDialogData}
                        handleCloseDialog={handleCloseReadingDialog}
                        handleSave={handleSaveReading}
                    />
                }
                {addReadingDialogData &&
                    <AddReadingDialog
                        dialogData={addReadingDialogData}
                        handleCloseDialog={handleCloseAddReadingDialog}
                        handleSave={handleSaveNewReading}
                    />
                }
                {deleteWorkItemDialogData &&
                    <DeleteWorkItemDialog
                        dialogData={deleteWorkItemDialogData}
                        handleCloseDialog={handleCloseDeleteDialog}
                        handleSave={handleRemove}
                        odometerReadingRows={odometerReadingRows}
                        busUsages={busUsages}
                        sortedWorkItems={sortedWorkItems}
                        secondaryWorkSheets={secondaryWorkSheets}
                    />
                }
            </Box>
        </ErrorWrapper>
    );
}
