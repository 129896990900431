import {ResourceType, WorkGroupItemType} from "../workGroup/types";
import {WorkScheduleItemStatus} from "../workSchedule/types";
import {OdometerReadingWithBusId, Region, RepetitionRules, RoutePoint, RoutePointRequest} from "../types";

export interface WorkSheet {
    id?: number;
    resourceId: number | null;
    resourceType: ResourceType;
    workGroupId?: number;
    workGroupCode?: string;
    regionId: number;
    date: string;
    connectedWorkSheets: WorkSheet[];
    startTime?: string;
    departureTime?: string;
    endTime?: string;
    distance?: number;
    status: WorkScheduleItemStatus;
}

export interface WorkSheetDetails {
    id: number;
    resourceId: number;
    resourceType: ResourceType;
    startDate: string;
    endDate: string;
    status: WorkScheduleItemStatus;
    workGroupId: number | null;
    workItems: WorkSheetWorkItem[];
    driverComment?: string;
    comment?: string;
    odometerReadings: OdometerReadingWithBusId[];
    region?: WorkSheetRegion,
}

export interface WorkSheetWorkItem {
    id: number;
    type: WorkGroupItemType;
    route: RoutePoint[] | null;
    date: string;
    startDateTime: string;
    endDateTime: string;
    comment: string | null;
    distance: number | null;
    busWorkSheetId: number | null;
    driverWorkSheetId: number | null;
    trip?: WorkItemTrip;
    tripSegmentId?: number;
    charterTrip?: WorkItemCharterTrip;
    busWorkSheet: ResourceWorkSheet | null;
    driverWorkSheet: ResourceWorkSheet | null;
    defectId: number | null;
}

export interface ResourceWorkSheet {
    id: number;
    resourceType: ResourceType;
    driver?: ResourceDriver;
    bus?: ResourceBus;
    region: WorkSheetRegion,
}

interface ResourceDriver {
    id: number;
    firstName: string;
    lastName: string;
}

interface ResourceBus {
    id: number;
    licencePlateNumber: string;
}

interface WorkSheetRegion {
    id: number;
    name: string;
}

interface WorkItemTrip {
    id: number;
    code: string;
    lineNumber: string;
    regionId: number;
    comment?: string;
    repetition: RepetitionRules | null;
}
interface WorkItemCharterTrip {
    id: number;
    tripNumber: string;
    orderNumber: string;
    client: string;
    startDateTime: string;
    endDateTime: string;
    departurePoint: string;
    destination: string;
    distance: number;
    regionId: number;
    route: string;
    numberOfPassengers: number;
    comment?: string;
}

export interface SaveWorkSheet {
    id?: number;
    resourceId: number | null;
    resourceType: ResourceType;
    workGroupId: number | null;
    date: string;
    regionId: number;
    status: WorkScheduleItemStatus;
}

export interface GenerateWorkSheetRequest {
    id: number;
    date: string;
}

export interface SaveWorkItem {
    type: WorkGroupItemType;
    date: string;
    startDateTime: string;
    endDateTime: string;
    distance: number | null;
    comment: string | null;
    route: RoutePointRequest[] | null;
    busWorkSheetId: number | null;
    driverWorkSheetId: number | null;
    tripDefinitionId?: number;
    tripSegmentId?: number;
    tripId?: number;
    charterTripId?: number;
    defectId?: number;
}

export interface WorkItemWorkSheets {
    id: number;
    date: string;
    busWorkSheetId?: number;
    driverWorkSheetId?: number;
}

export interface TripSegmentResources {
    busWorkSheetId: number | null;
    driverWorkSheetId: number | null;
}

export interface DriverWorkSheetRecord {
    id: number;
    driverId: number;
    driverFirstName: string;
    driverLastName: string;
    startDateTime: string;
    endDateTime: string;
    workGroupCode?: string;
    region?: Region;
}

export enum WorkGroupItemTypeCharterTrip {
    CHARTER_TRIP = 'CHARTER_TRIP',
}

export type WorkGroupItemTypeWithCharterTrip = WorkGroupItemType | WorkGroupItemTypeCharterTrip;

export const workGroupItemTypesWithDistance: WorkGroupItemTypeWithCharterTrip[] = [
    WorkGroupItemType.TRIP_DEFINITION,
    WorkGroupItemType.DEADHEADING,
    WorkGroupItemTypeCharterTrip.CHARTER_TRIP,
];
export const workGroupItemBreakTypes: WorkGroupItemTypeWithCharterTrip[] = [
    WorkGroupItemType.LUNCH_BREAK,
    WorkGroupItemType.DISRUPTION,
    WorkGroupItemType.TIME_BETWEEN_SHIFTS,
];
export const workGroupItemTypesWithOppositeResource: WorkGroupItemTypeWithCharterTrip[] = [
    WorkGroupItemType.TRIP_DEFINITION,
    WorkGroupItemType.PREPARATION_TIME,
    WorkGroupItemType.FINISHING_TIME,
    WorkGroupItemType.DEADHEADING,
    WorkGroupItemType.RESERVE,
    WorkGroupItemType.MAINTENANCE,
    WorkGroupItemTypeCharterTrip.CHARTER_TRIP,
];

export const isBreakType = (type: WorkGroupItemTypeWithCharterTrip): boolean => {
    return workGroupItemBreakTypes.includes(type)
};
export const canHaveOppositeResource = (type: WorkGroupItemTypeWithCharterTrip): boolean => {
    return workGroupItemTypesWithOppositeResource.includes(type)
};