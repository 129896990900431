export interface WorkSheetBreathalyzerResult {
    driverId: number;
    workSheetId: number;
    worksheetStartDateTime: string;
    breathalyzerResultType: BreathalyzerResultType;
    breathalyzerResultDateTime: string;
}

export enum BreathalyzerResultType {
    PASSED = 'PASSED',
    FAILED = 'FAILED',
}
