import {Box, SxProps} from "@mui/material";
import theme from "../../../theme";
import React from "react";

export interface ToolbarContainerProps {
    children: React.ReactNode,
    sx?: SxProps,
}

const ToolbarContainer = ({children, sx}: ToolbarContainerProps) => {

    const defaultSx: SxProps = {
        border: '1px solid',
        borderBottom: 'none',
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.action.hover,
        px: 1,
        py: 1.5,
        borderRadius: '5px 5px 0 0',
        mb: '-5px',
        display: 'flex',
        displayPrint: 'none'
    };

    return (
        <Box sx={{...defaultSx, ...sx}}>
            {children}
        </Box>
    );
}

export default ToolbarContainer;