import React from "react";
import {Box, Table, TableCell, TableContainer, TableHead, TableRow, useTheme} from "@mui/material";
import {
    useAppSelector,
    useBreathalyzerResultFilterChips,
    useResourceOptions,
    useWorkSheetsNearingDeparturesObserving,
    useWorkSheetsSortType
} from "../../../../hooks";
import {selectShowBreathalyzerColumn, selectWorkSheets} from "../store/selectors";
import {
    BreathalyzerResultType,
    Region,
    ResourceType,
    WorkSheet,
    WorkSheetBreathalyzerResult
} from "../../../../API/types";
import WorkSheetListRow from "./WorkSheetListRow";
import {grey} from "@mui/material/colors";
import {selectToggledResourceType} from "../../../../store/viewSlice";
import SortButton from "./SortButton";
import {selectSelectedRegion} from "../../../../store/regionSlice";
import {createSelector} from "@reduxjs/toolkit";
import {selectAllBreathalyzerResults, WorkSheetsSortType} from "../../../../store/workScheduleItemSlice";
import {RootState} from "../../../../store";
import {compareWorkSheetsByRegionAndStartTime, compareWorkSheetsByRegionAndWorkGroupCode} from "../utils";
import {BreathalyzerResultFilterChipType} from "../types";
import dayjs from "dayjs";
import {isSameDate} from "../../../../utils/dateUtils";


const filterWorkSheetsByChips = (
    workSheets: WorkSheet[], breathalyzerResults: WorkSheetBreathalyzerResult[],
    selectedBreathalyzerFilterChips: BreathalyzerResultFilterChipType[],
    resourceType: ResourceType,
    selectedRegion?: Region,
) => {
    if (!selectedRegion?.showBreathalyzerResult || resourceType === ResourceType.VEHICLE) return workSheets;
    if (Object.values(BreathalyzerResultFilterChipType).length === selectedBreathalyzerFilterChips.length || selectedBreathalyzerFilterChips.length === 0) return workSheets;

    return workSheets.filter(workSheet => {
        const breathalyzerResult = breathalyzerResults.find(result => result.workSheetId === workSheet.id);
        if (selectedBreathalyzerFilterChips.includes(BreathalyzerResultFilterChipType.PASSED) && breathalyzerResult?.breathalyzerResultType === BreathalyzerResultType.PASSED) return true;
        if (selectedBreathalyzerFilterChips.includes(BreathalyzerResultFilterChipType.FAILED) && breathalyzerResult?.breathalyzerResultType === BreathalyzerResultType.FAILED) return true;
        return selectedBreathalyzerFilterChips.includes(BreathalyzerResultFilterChipType.UNKNOWN) && !breathalyzerResult;
    });
}

export const selectRows = createSelector(
    selectWorkSheets,
    selectAllBreathalyzerResults,
    selectSelectedRegion,
    selectToggledResourceType,
    (_: RootState, sortingType: WorkSheetsSortType, showOnlyNearingDepartures: boolean, selectedBreathalyzerFilterChips: BreathalyzerResultFilterChipType[]) => ({
        sortingType, showOnlyNearingDepartures, selectedBreathalyzerFilterChips
    }),
    (workSheets, breathalyzerResults, selectedRegion, resourceType, filterParams) =>
        filterWorkSheetsByChips(workSheets, breathalyzerResults, filterParams.selectedBreathalyzerFilterChips, resourceType, selectedRegion)
            .filter(workSheet => !selectedRegion?.showBreathalyzerResult
                || !filterParams.showOnlyNearingDepartures
                || !isSameDate(workSheet.date, dayjs())
                || dayjs(workSheet.departureTime).isAfter(dayjs().subtract(30, 'minutes'))
                || dayjs(workSheet.startTime).isAfter(dayjs().subtract(15, 'minutes'))
            )
            .sort((a, b) => filterParams.sortingType === 'startTime'
                ? compareWorkSheetsByRegionAndStartTime(a, b, selectedRegion)
                : compareWorkSheetsByRegionAndWorkGroupCode(a, b, selectedRegion)
            ),
);

export default function WorkSheetList() {
    const theme = useTheme();
    const [sortType] = useWorkSheetsSortType();
    const [showOnlyNearingDepartures] = useWorkSheetsNearingDeparturesObserving();
    const [selectedBreathalyzerFilterChips] = useBreathalyzerResultFilterChips();
    const rows = useAppSelector(state => selectRows(state, sortType, showOnlyNearingDepartures, selectedBreathalyzerFilterChips));
    const resourceType = useAppSelector(selectToggledResourceType);
    const selectedRegion = useAppSelector(selectSelectedRegion);
    const showBreathalyzerColumn = useAppSelector(selectShowBreathalyzerColumn);
    const {availableDrivers, availableBuses} = useResourceOptions();

    return (
        <TableContainer sx={{boxSizing: 'border-box', border: `1px solid ${theme.palette.divider}`, borderRadius: '5px', backgroundColor: 'common.white'}}>
            <Table size="small" sx={{
                '& .MuiTableHead-root .MuiTableCell-root': {
                    fontWeight: 'bold',
                    paddingY: 1,
                },
                '& .MuiTableBody-root:hover': {
                    backgroundColor: grey[100],
                },
                '& .MuiTableCell-root': {
                    paddingY: 0.25,
                    paddingX: 1,
                },
            }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{minWidth: 24}} />
                        <TableCell sx={{minWidth: 100}}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                {resourceType === ResourceType.DRIVER ? 'Juhi TG' : 'Bussi TG'}
                                <SortButton parentSortType="code" />
                            </Box>
                        </TableCell>
                        <TableCell sx={{minWidth: resourceType === ResourceType.DRIVER ? 200 : 100}}>
                            <Box sx={{pl: 1}}>{resourceType === ResourceType.DRIVER ? 'Bussijuht' : 'Buss'}</Box>
                        </TableCell>
                        {showBreathalyzerColumn &&
                            <TableCell sx={{minWidth: 70, displayPrint: 'none'}}>
                                Puhumine
                            </TableCell>
                        }
                        <TableCell sx={{minWidth: 80}}>
                            {resourceType === ResourceType.DRIVER ? 'Bussi TG' : 'Juhi TG'}
                        </TableCell>
                        <TableCell sx={{minWidth: resourceType === ResourceType.VEHICLE ? 200 : 100}}>
                            <Box sx={{pl: 1}}>{resourceType === ResourceType.DRIVER ? 'Buss' : 'Bussijuht'}</Box>
                        </TableCell>
                        <TableCell sx={{minWidth: 90}}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                Algus <SortButton parentSortType="startTime" />
                            </Box>
                        </TableCell>
                        {resourceType === ResourceType.DRIVER && selectedRegion?.showBreathalyzerResult && showOnlyNearingDepartures &&
                            <TableCell sx={{minWidth: 70, displayPrint: 'none'}}>
                                Väljumine
                            </TableCell>
                        }
                        <TableCell sx={{minWidth: 90}}>Lõpp</TableCell>
                        <TableCell sx={{minWidth: 90}}>Läbisõit</TableCell>
                        <TableCell sx={{minWidth: 80, width: '100%'}} />
                    </TableRow>
                </TableHead>
                {rows.map((row: WorkSheet) => (
                    <WorkSheetListRow
                        key={`${row.id}_${row.workGroupId}`}
                        workSheet={row}
                        availableDrivers={availableDrivers}
                        availableBuses={availableBuses}
                    />
                ))}
            </Table>
        </TableContainer>
    );
}
