import axios from "axios";
import {handleError} from "../utils";
import {CreateNotificationSubscription, NotificationSubscription, UpdateNotificationSubscription} from "./types";


export const getUserNotificationSubscriptions = (userId: string) =>
    axios.get<NotificationSubscription[]>(`/api/users/${userId}/notifications`)
        .then(result => result.data)
        .catch(handleError);

export const createNotificationSubscription = (userId: string, data: CreateNotificationSubscription) =>
    axios.post<NotificationSubscription>(`/api/users/${userId}/notifications`, data)
        .then(result => result.data)
        .catch(handleError);

export const updateNotificationSubscription = (notificationId: string | number, data: UpdateNotificationSubscription) =>
    axios.put<void>(`/api/notifications/${notificationId}`, data)
        .catch(handleError);

export const deleteNotificationSubscription = (notificationId: string | number) =>
    axios.delete<void>(`/api/notifications/${notificationId}`)
        .catch(handleError);
