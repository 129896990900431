import React from 'react';
import {Clear} from '@mui/icons-material';
import {NotificationType, User} from "../../../../API/types";
import {Formik, FormikHelpers, FormikProps} from "formik";
import Dialog from "../../../../components/Dialog/Dialog";
import NotificationsForm from "./NotificationsForm";
import * as Yup from "yup";
import {UserNotificationsFormData} from "../types";
import {isEqual} from "lodash";


const getRegionalNotificationValidation = Yup.object().shape({
    regions: Yup.array()
        .when('isActive', (_, schema) => schema.test({
            test: function () {
                const regions = this.parent.regions;

                if (this.parent.isActive && regions && regions.length === 0) {
                    return this.createError({
                        message: 'Sisse lülitatud teavitusel peab olema märgitud vähemalt üks piirkond',
                    });
                }

                return true;
            },
        }))
    });

const userNotificationValidationSchema = Yup.object().shape({
    defectCreated: getRegionalNotificationValidation,
    defectCommentCreated: getRegionalNotificationValidation,
});

const getInitialNotificationValues = (type: NotificationType, user: User) => {
    const existingNotification = user.notificationSubscriptions?.find(notification => notification.type === type);

    return {
        id: existingNotification?.id ?? 0,
        type: type,
        regions: existingNotification?.regions ?? [],
        isActive: !!existingNotification
    };
};

interface NotificationsDialogProps {
    user: User;
    saveUserNotifications: (user: User, data: UserNotificationsFormData, formHelpers: FormikHelpers<UserNotificationsFormData>) => void;
    handleCloseDialog: () => void;
}

export default function NotificationsDialog({user, saveUserNotifications, handleCloseDialog}: NotificationsDialogProps) {
    const handleSaveUserNotifications = (data: UserNotificationsFormData, helpers: FormikHelpers<UserNotificationsFormData>) => saveUserNotifications(user, data, helpers);

    const initialValues: UserNotificationsFormData = {
        defectCreated: getInitialNotificationValues(NotificationType.DEFECT_CREATED, user),
        defectCommentCreated: getInitialNotificationValues(NotificationType.DEFECT_COMMENT_CREATED, user),
    };

    return <Formik initialValues={initialValues} onSubmit={handleSaveUserNotifications} validationSchema={userNotificationValidationSchema}>
        {(formikProps: FormikProps<UserNotificationsFormData>) =>
            <Dialog
                open={true}
                onClose={handleCloseDialog}
                title="Kasutaja teavituste sätted"
                subtitle={user.displayName}
                hasFormikForm={true}
                buttons={[
                    {
                        text: "Loobu",
                        color: "secondary",
                        startIcon: <Clear />,
                        onClick: handleCloseDialog,
                        disabled: formikProps.isSubmitting,
                    },
                    {
                        text: "Salvesta",
                        type: "submit",
                        disabled: formikProps.isSubmitting || isEqual(formikProps.initialValues, formikProps.values)
                    }
                ]}
            >
                <NotificationsForm />
            </Dialog>
        }
    </Formik>;
}
