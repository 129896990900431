import React, {useEffect, useMemo, useState} from "react";
import Button from "../../../../../../components/Button/Button";
import {Box, Typography, Dialog} from "@mui/material";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Clear} from "@mui/icons-material";
import SubmitErrorListener from "../../../../../../components/Form/SubmitErrorListener/SubmitErrorListener";
import {
    ApiError,
    OdometerReadingWithBusId,
    ResourceType,
    WorkGroupItemType,
    WorkSheetDetails as ApiWorkSheetDetails,
    WorkSheetDetails,
    WorkSheetWorkItem
} from "../../../../../../API/types";
import Select from "../../../../../../components/Form/Select/Select";
import {getWorkGroupItemTypeTranslationFromStr} from "../../../../../../utils/enumTranslations";
import ModifierStartAndEndTime from "../../../../../../components/Form/DateTimePicker/ModifierStartAndEndTime";
import NumberTextField from "../../../../../../components/Form/NumberTextField/NumberTextField";
import {workGroupActivityValidationSchema} from "../../../../../../utils/formValidation";
import dayjs from "dayjs";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks";
import {SelectOptionWithIdAndTripComment, WorkItemForm} from "../../../types";
import Autocomplete from "../../../../../../components/Form/Select/AutoComplete";
import {SelectOptionWithId, SelectOptionWithIdAndRegion} from "../../../../../../types";
import Route from "../../../../../../components/Form/Route/Route";
import {getCharterTripLabel, getTripLabel} from "../../../utils";
import TextField from "../../../../../../components/Form/TextField/TextField";
import {
    canHaveOppositeResource,
    ResourceWorkSheet,
    WorkGroupItemTypeCharterTrip,
    workGroupItemTypesWithDistance,
    workGroupItemTypesWithOppositeResource,
    WorkGroupItemTypeWithCharterTrip
} from "../../../../../../API/workSheets/types";
import {getUnplannedWorkItems, getWorkSheetsWithResourceOnDate} from "../../../../../../API";
import {setToast} from "../../../../../../store/toastSlice";
import {
    getBusUsages,
    getBusWorkSheetOption,
    getBusWorkSheetOptions,
    getDriverWorkSheetOption,
    getDriverWorkSheetOptions,
    getAffectedOdometerReadings,
    isItTheSameBusUsage
} from "../../utils";
import {getWorkGroupItemTypeOptions} from "../../../../../../utils/formUtils";
import TimeBetweenShiftsLengthAlert from "../../../../../../features/Alert/TimeBetweenShiftsLengthAlert";
import DefectSelect from "../../../../../../features/InputFields/DefectSelect";
import {BusUsageWithDistance, BusUsageWithOdometerReadings} from "../../../../dashboard/DriverDashboard/types";
import {getStrDateTimeFromDateWithModifier, isItemWithinTimeFrame} from "../../../../../../utils/dateUtils";
import {OdometerReadingRow} from "../odometerReadings/OdometerReadings";
import OdometerReadingChangeForm from "../odometerReadings/OdometerReadingChangeForm";
import {selectAllRegions} from "../../../../../../store/regionSlice";
import {mapErrors} from "../../../../../../utils/errorMapping";


const emptyValues: WorkItemForm = {
    type: WorkGroupItemType.TRIP_DEFINITION,
    trip: null,
    charterTrip: null,
    startTime: null,
    startTimeIsOnNextDay: false,
    endTime: null,
    endTimeIsOnNextDay: false,
    route: [],
    distance: '',
    comment: '',
    oppositeResource: null,
    defectId: null,
};

export interface WorkItemDialogData {
    workSheet: WorkSheetDetails;
    workItem?: WorkSheetWorkItem;
}

interface WorkItemDialogProps {
    dialogData: WorkItemDialogData;
    handleCloseDialog: () => void;
    handleSave: (formData: WorkItemForm, workItem?: WorkSheetWorkItem, modifiedOdometerReadings?: OdometerReadingWithBusId[]) => Promise<void>;
    odometerReadingRows: OdometerReadingRow[];
    busUsages: BusUsageWithDistance[];
    sortedWorkItems: WorkSheetWorkItem[];
    secondaryWorkSheets: ApiWorkSheetDetails[];
}

export default function WorkItemDialog({dialogData, handleCloseDialog, handleSave, odometerReadingRows, busUsages, sortedWorkItems, secondaryWorkSheets}: WorkItemDialogProps) {
    const { workSheet, workItem } = dialogData;
    const dispatch = useAppDispatch();
    const allRegions = useAppSelector(selectAllRegions);
    const [selectedWorkSheetItem, setSelectedWorkSheetItem] = useState<WorkSheetWorkItem | undefined>(dialogData.workItem);
    const [workSheetsWithResources, setWorkSheetsWithResources] = useState<ResourceWorkSheet[]>([]);
    const [unplannedWorkItems, setUnplannedWorkItems] = useState<WorkSheetWorkItem[]>([]);
    const [updatedWorkItemValues, setUpdatedWorkItemValues] = useState<undefined | {startDateTime: string, endDateTime: string, oppositeResource: number | null}>(
        workSheet.resourceType === ResourceType.DRIVER && dialogData.workItem ? {
                startDateTime: dialogData.workItem.startDateTime,
                endDateTime: dialogData.workItem.endDateTime,
                oppositeResource: (workSheet.resourceType === ResourceType.DRIVER ? dialogData.workItem.busWorkSheetId : dialogData.workItem.driverWorkSheetId) ?? null
            } : undefined
    );
    const [selectedReadingIdsForUpdate, setSelectedReadingIdsForUpdate] = useState<number[]>([]);

    const originalBusUsages: BusUsageWithOdometerReadings[] = useMemo(() =>
        busUsages.map(busUsage => ({
            ...busUsage,
            odometerReadings: odometerReadingRows.filter(reading => reading.busUsage && isItTheSameBusUsage(reading.busUsage, busUsage))
        }))
    , [busUsages]);

    const originalBusUsage: BusUsageWithOdometerReadings | undefined = useMemo(() => workSheet.resourceType === ResourceType.DRIVER && dialogData?.workItem
        ? originalBusUsages.find(usage => usage.busId === dialogData.workItem?.busWorkSheet?.bus?.id && isItemWithinTimeFrame(
            dialogData.workItem.startDateTime,
            dialogData.workItem.endDateTime,
            usage.startDateTime,
            usage.endDateTime
        ))
        : undefined
    , [originalBusUsages, dialogData]);

    const updatedBusUsages: BusUsageWithDistance[] | undefined = useMemo(() => workSheet.resourceType === ResourceType.DRIVER && updatedWorkItemValues ? getBusUsages(
            [...(selectedWorkSheetItem ? sortedWorkItems.filter(wi => selectedWorkSheetItem?.id !== wi.id) : sortedWorkItems), {
                ...updatedWorkItemValues,
                busWorkSheetId: updatedWorkItemValues.oppositeResource,
                distance: null
            }].sort((a, b) => a.startDateTime.localeCompare(b.startDateTime)),
            secondaryWorkSheets,
            workSheet.id
        ) : undefined
    , [updatedWorkItemValues]);

    const updatedBusUsage: BusUsageWithDistance | undefined = useMemo(() => {
        if (!updatedBusUsages || !updatedWorkItemValues) return undefined;
        const oppositeResourceBusId = secondaryWorkSheets.find(ws => ws.id === updatedWorkItemValues?.oppositeResource)?.resourceId;

        return oppositeResourceBusId ? updatedBusUsages.find(usage => usage.busId === oppositeResourceBusId && isItemWithinTimeFrame(
            updatedWorkItemValues.startDateTime,
            updatedWorkItemValues.endDateTime,
            usage.startDateTime,
            usage.endDateTime
        )) : undefined;
    }, [updatedBusUsages]);

    const updatedOdometerReadingRows: OdometerReadingWithBusId[] = useMemo(() => {
        if (workSheet.resourceType === ResourceType.VEHICLE) return [];
        return getAffectedOdometerReadings(originalBusUsages, originalBusUsage, updatedBusUsages, updatedBusUsage);
    }, [originalBusUsage, updatedBusUsage]);

    useEffect(() => {
        getWorkSheetsWithResourceOnDate(dialogData.workItem?.date ?? dialogData.workSheet.startDate)
            .then(setWorkSheetsWithResources)
            .catch(handleError('Sõidulehe valiku leidmisel tekkis viga'));
    }, []);

    useEffect(() => {
        if (dialogData.workSheet.region?.id && dialogData.workItem === undefined) {
            getUnplannedWorkItems(dialogData.workSheet.region.id, dayjs(dialogData.workSheet.startDate), dialogData.workSheet.resourceType)
                .then(setUnplannedWorkItems)
                .catch(handleError('Reiside valiku leidmisel tekkis viga'));
        }
    }, [dialogData]);

    useEffect(() => {
        if (selectedReadingIdsForUpdate.length) {
            setSelectedReadingIdsForUpdate([]);
        }
    }, [updatedOdometerReadingRows]);

    const date = useMemo(() => dayjs(workSheet.startDate), [workSheet]);

    const handleError = (defaultMessage: string) => (error?: ApiError) => {
        dispatch(setToast({type: 'error', text: error?.message ?? defaultMessage}));
        handleCloseDialog();
    };

    const getOppositeResourceOption = (workItem: WorkSheetWorkItem, resourceType: ResourceType): SelectOptionWithId | null => {
        if (!canHaveOppositeResource(workItem.type)) {
            return null;
        }

        if (resourceType === ResourceType.DRIVER) {
            return getBusWorkSheetOption(workItem);
        } else {
            return getDriverWorkSheetOption(workItem);
        }
    };

    const getTripOption = (workItem?: WorkSheetWorkItem): SelectOptionWithId | null => {
        if (!workItem || !workItem.tripSegmentId) {
            return null;
        }

        return {
            id: workItem.tripSegmentId,
            name: getTripLabel(workItem),
        }
    };

    const getCharterTripOption = (workItem?: WorkSheetWorkItem): SelectOptionWithId | null => {
        if (!workItem || !workItem.charterTrip?.id) {
            return null;
        }

        return {
            id: workItem.charterTrip.id,
            name: getCharterTripLabel(workItem),
        }
    };

    const initialValues: WorkItemForm = workItem ? {
        type: workItem.charterTrip ? WorkGroupItemTypeCharterTrip.CHARTER_TRIP : workItem.type,
        trip: getTripOption(workItem),
        charterTrip: getCharterTripOption(workItem),
        startTime: dayjs(workItem.startDateTime),
        startTimeIsOnNextDay: dayjs(workItem.startDateTime).isAfter(date, 'day'),
        endTime: dayjs(workItem.endDateTime),
        endTimeIsOnNextDay: dayjs(workItem.endDateTime).isAfter(date, 'day'),
        route: workItem.route?.map(point => ({
            geoPoint: point,
            stopName: point.stopName ?? '',
            requestStop: point.requestStop,
            time: point.time ? dayjs(point.time, 'HH:mm:ss') : null,
            timeIsOnNextDay: point.timeIsOnNextDay ?? false,
        })) ?? [],
        distance: workItem.distance?.toString() ?? '',
        comment: workItem.comment ?? '',
        oppositeResource: getOppositeResourceOption(workItem, workSheet.resourceType),
        defectId: workItem.defectId ?? null,
    } : emptyValues;

    const handleSubmit = (form: WorkItemForm, formHelpers: FormikHelpers<WorkItemForm>) => {
        formHelpers.setSubmitting(true);
        const odometerReadingsForUpdate: OdometerReadingWithBusId[] = selectedReadingIdsForUpdate
            .map(readingId => updatedOdometerReadingRows.find(reading => reading.id === readingId))
            .filter(reading => reading !== undefined) as OdometerReadingWithBusId[];

        const workItemToSave = selectedWorkSheetItem ? selectedWorkSheetItem : workItem;
        handleSave(form, workItemToSave, odometerReadingsForUpdate)
            .then(() => handleCloseDialog())
            .catch((error: ApiError) => {
                dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Tegevuse muutmisel tekkis viga'}));
            })
            .finally(() => formHelpers.setSubmitting(false));
    };

    const getFilteredTypeOptions = (selectedType: WorkGroupItemTypeWithCharterTrip): WorkGroupItemTypeWithCharterTrip[] => {
        const worksheetIncludesLunchOrDisruption = workSheet.workItems.some(activity =>
            activity.type === WorkGroupItemType.LUNCH_BREAK || activity.type === WorkGroupItemType.DISRUPTION
        );

        return [...getWorkGroupItemTypeOptions(workSheet.resourceType, worksheetIncludesLunchOrDisruption, selectedType), ...Object.values(WorkGroupItemTypeCharterTrip)];
    };

    const tripOptions: SelectOptionWithIdAndTripComment[] = unplannedWorkItems
        .filter(item => item.type === WorkGroupItemType.TRIP_DEFINITION && item.trip)
        .map(item => {
            return {
                id: item.tripSegmentId as number,
                name: getTripLabel(item),
                tripComment: item.trip?.comment,
            }
        });

    const charterTripOptions: SelectOptionWithId[] = unplannedWorkItems
        .filter(item => item.type === WorkGroupItemType.TRIP_DEFINITION && item.charterTrip)
        .map(item => {
            return {
                id: item.charterTrip?.id as number,
                name: getCharterTripLabel(item),
            }
        });

    if (workItem) {
        tripOptions.push({
            id: workItem.tripSegmentId as number,
            name: getTripLabel(workItem),
            tripComment: workItem.trip?.comment,
        });
    }

    const handleChangeTrip = (formikProps: FormikProps<WorkItemForm>) => (value: SelectOptionWithId | null) => {
        const workSheetItem = unplannedWorkItems.find(item => item.tripSegmentId === value?.id);
        if (workSheetItem && workSheetItem.tripSegmentId) {
            void formikProps.setValues({
                ...formikProps.values,
                trip: getTripOption(workSheetItem),
                route: workSheetItem.route?.map(point => ({
                    geoPoint: point,
                    stopName: point.stopName ?? '',
                    requestStop: point.requestStop,
                    time: point.time ? dayjs(point.time, 'HH:mm:ss') : null,
                    timeIsOnNextDay: point.timeIsOnNextDay ?? false,
                })) ?? [],
                startTime: dayjs(workSheetItem.startDateTime),
                startTimeIsOnNextDay: !dayjs(workSheetItem.startDateTime).isSame(date, 'day'),
                endTime: dayjs(workSheetItem.endDateTime),
                endTimeIsOnNextDay: !dayjs(workSheetItem.endDateTime).isSame(date, 'day'),
                distance: workSheetItem.distance?.toString() ?? '',
                comment: '',
                oppositeResource: getOppositeResourceOption(workSheetItem, workSheet.resourceType),
            });
        }
        if (workSheetItem && workSheetItem.id) {
            setSelectedWorkSheetItem(workSheetItem);
        }
    };

    const handleChangeCharterTrip = (formikProps: FormikProps<WorkItemForm>) => (value: SelectOptionWithId | null) => {
        const workSheetItem = unplannedWorkItems.find(item => item.charterTrip?.id === value?.id);
        if (workSheetItem && workSheetItem.charterTrip) {
            void formikProps.setValues({
                ...formikProps.values,
                charterTrip: getCharterTripOption(workSheetItem),
                startTime: dayjs(workSheetItem.startDateTime),
                startTimeIsOnNextDay: !dayjs(workSheetItem.startDateTime).isSame(date, 'day'),
                endTime: dayjs(workSheetItem.endDateTime),
                endTimeIsOnNextDay: !dayjs(workSheetItem.endDateTime).isSame(date, 'day'),
                distance: workSheetItem.distance?.toString() ?? '',
                comment: '',
                oppositeResource: getOppositeResourceOption(workSheetItem, workSheet.resourceType),
            });
        }
        if (workSheetItem && workSheetItem.id) {
            setSelectedWorkSheetItem(workSheetItem);
        }
    };

    const handleChangeType = (formikProps: FormikProps<WorkItemForm>) => (value: WorkGroupItemTypeWithCharterTrip) => {
        setSelectedWorkSheetItem(undefined);
        void formikProps.setValues({
            ...formikProps.values,
            type: value,
            trip: value === WorkGroupItemType.TRIP_DEFINITION ? (formikProps.values.trip ?? null) : null,
            charterTrip: value === WorkGroupItemTypeCharterTrip.CHARTER_TRIP ? (formikProps.values.charterTrip ?? null) : null,
            route: value === WorkGroupItemType.TRIP_DEFINITION ? formikProps.values.route : [],
            distance: workGroupItemTypesWithDistance.includes(value) ? formikProps.values.distance : '',
            defectId: null,
            comment: formikProps.values.comment ?? '',
        });
    };

    const handleChangeBusResource = (formikProps: FormikProps<WorkItemForm>) => () => {
        if (formikProps.values.defectId) void formikProps.setFieldValue('defectId', null);
    };

    const getSecondaryBusWorkSheetOptions = (workItem: WorkSheetWorkItem | undefined): SelectOptionWithIdAndRegion[] => {
        const preferredRegionName = workSheet.region?.name;
        const workItemOption = getBusWorkSheetOption(workItem);
        return getBusWorkSheetOptions(workSheetsWithResources, workItemOption, preferredRegionName);
    };

    const getSecondaryDriverWorkSheetOptions = (workItem: WorkSheetWorkItem | undefined): SelectOptionWithIdAndRegion[] => {
        const preferredRegionName = workSheet.region?.name;
        return getDriverWorkSheetOptions(workSheetsWithResources, getDriverWorkSheetOption(workItem), preferredRegionName);
    };

    const renderTripComment = (formikProps: FormikProps<WorkItemForm>) => {
        const tripComment = tripOptions.find(option => option.id === formikProps.values.trip?.id)?.tripComment;

        return tripComment
            ? <Typography variant="body2" color="text.secondary" pb={0.5}>Reisi kommentaar: {tripComment}</Typography>
            : <></>;
    };

    const groupByFunc = (option: SelectOptionWithIdAndRegion) => option.regionName;

    const excludeStopsWithoutCode = useMemo(() => {
        if (workItem?.trip !== undefined) {
            const tripRegion = allRegions.find(region => region.id === workItem.trip?.regionId);

            return tripRegion?.reportToAvl === true;
        }

        return false;
    }, [allRegions, workItem?.trip]);

    return (
        <Dialog open={true} onClose={handleCloseDialog}>
            <Box sx={{width: '450px', maxWidth: '100%', p: {xs: 0, sm: 2}}}>
                <DialogTitle sx={{maxWidth: '100%', display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="h5" component="div">{workItem ? 'Tegevuse muutmine' : 'Uus tegevus'}</Typography>
                </DialogTitle>
                <Formik initialValues={initialValues}
                        validationSchema={workGroupActivityValidationSchema}
                        onSubmit={handleSubmit}>
                    {(formikProps: FormikProps<WorkItemForm>) => {
                        useEffect(() => {
                            if (workSheet.resourceType === ResourceType.DRIVER) {
                                if (formikProps.values.startTime && formikProps.values.startTime.isValid() && formikProps.values.endTime && formikProps.values.endTime.isValid()) {
                                    setUpdatedWorkItemValues({
                                        startDateTime: getStrDateTimeFromDateWithModifier(formikProps.values.startTime, formikProps.values.startTimeIsOnNextDay, workSheet.startDate),
                                        endDateTime: getStrDateTimeFromDateWithModifier(formikProps.values.endTime, formikProps.values.endTimeIsOnNextDay, workSheet.startDate),
                                        oppositeResource: formikProps.values.oppositeResource?.id ?? null
                                    });
                                }
                            }
                        }, [formikProps.values.startTime, formikProps.values.startTimeIsOnNextDay, formikProps.values.endTime, formikProps.values.endTimeIsOnNextDay, formikProps.values.oppositeResource]);

                        return (
                            <Form>
                                <DialogContent sx={{display: 'flex', flexDirection: 'column', pt: 0}}>
                                    <TimeBetweenShiftsLengthAlert values={formikProps.values} />
                                    <Select name="type"
                                            label="Tegevuse tüüp"
                                            options={getFilteredTypeOptions(formikProps.values.type)}
                                            translationFunction={getWorkGroupItemTypeTranslationFromStr}
                                            translationEnumType={WorkGroupItemType}
                                            onChange={handleChangeType(formikProps)}
                                            disabled={!!workItem}
                                    />
                                    {formikProps.values.type === WorkGroupItemType.TRIP_DEFINITION &&
                                        <>
                                            <Autocomplete
                                                name="trip"
                                                label="Reis"
                                                options={tripOptions}
                                                onChange={handleChangeTrip(formikProps)}
                                                getOptionLabel={(option) => option.name}
                                                disabled={!!workItem}
                                            />
                                            {renderTripComment(formikProps)}
                                            <Route
                                                name="route"
                                                excludeStopsWithoutCode={excludeStopsWithoutCode}
                                                values={formikProps.values.route}
                                            />
                                        </>
                                    }
                                    {formikProps.values.type === WorkGroupItemTypeCharterTrip.CHARTER_TRIP &&
                                        <Autocomplete
                                            name="charterTrip"
                                            label="Tellimusvedu"
                                            options={charterTripOptions}
                                            onChange={handleChangeCharterTrip(formikProps)}
                                            getOptionLabel={(option) => option.name}
                                            disabled={!!workItem}
                                        />
                                    }
                                    <ModifierStartAndEndTime />
                                    {workGroupItemTypesWithDistance.includes(formikProps.values.type) &&
                                        <NumberTextField name="distance" label="Pikkus (km)" decimals={3} />
                                    }
                                    {workSheet.resourceType === ResourceType.DRIVER && workGroupItemTypesWithOppositeResource.includes(formikProps.values.type) &&
                                        <Autocomplete
                                            name="oppositeResource"
                                            label="Buss"
                                            options={getSecondaryBusWorkSheetOptions(workItem)}
                                            groupBy={groupByFunc}
                                            getOptionLabel={option => option.name}
                                            onChange={handleChangeBusResource(formikProps)}
                                        />
                                    }
                                    {workSheet.resourceType === ResourceType.VEHICLE && workGroupItemTypesWithOppositeResource.includes(formikProps.values.type) &&
                                        <Autocomplete
                                            name="oppositeResource"
                                            label="Juht"
                                            options={getSecondaryDriverWorkSheetOptions(workItem)}
                                            groupBy={groupByFunc}
                                            getOptionLabel={option => option.name}
                                        />
                                    }
                                    {formikProps.values.type === WorkGroupItemType.MAINTENANCE &&
                                        <DefectSelect
                                            busId={
                                                workSheet.resourceType === ResourceType.DRIVER
                                                    ? workSheetsWithResources.find(workSheet => formikProps.values.oppositeResource?.id === workSheet.id)?.bus?.id
                                                    : workSheet.resourceId
                                            }
                                            date={workSheet.startDate}
                                        />
                                    }
                                    <TextField
                                        name="comment"
                                        label="Kommentaar"
                                        multiline
                                        minRows={2}
                                        maxRows={5}
                                        required={false}
                                    />
                                    {updatedOdometerReadingRows.length > 0 &&
                                        <OdometerReadingChangeForm
											selectedReadingIdsForUpdate={selectedReadingIdsForUpdate}
											setSelectedReadingIdsForUpdate={setSelectedReadingIdsForUpdate}
                                            odometerReadingRows={odometerReadingRows}
                                            updatedOdometerReadingRows={updatedOdometerReadingRows}
                                        />
                                    }
                                </DialogContent>
                                <DialogActions sx={{justifyContent: 'center', maxWidth: '100%', mb: 2}}>
                                    <Box maxWidth="50%">
                                        <Button
                                            disabled={formikProps.isSubmitting}
                                            text="Loobu"
                                            color="secondary"
                                            startIcon={<Clear />}
                                            onClick={handleCloseDialog}
                                        />
                                    </Box>
                                    <Box maxWidth="50%">
                                        <Button disabled={formikProps.isSubmitting} text="Salvesta" type="submit" />
                                    </Box>
                                </DialogActions>
                                <SubmitErrorListener
                                    isValid={formikProps.isValid}
                                    isValidating={formikProps.isValidating}
                                    isSubmitting={formikProps.isSubmitting}
                                />
                            </Form>
                        )
                    }}
                </Formik>
            </Box>
        </Dialog>
    );
}
