import React from "react";
import {WorkGroup, WorkGroupVersion} from "../../../../../API/types";
import DataGrid, {DEFAULT_COL_DEF_PROPS} from "../../../../../components/DataGrid/DataGrid";
import {Box, Typography} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {getResourceTypeTranslation} from "../../../../../utils/enumTranslations";
import Repetition from "../../../../../features/RepetitionRules/RepetitionRules";
import {getDisplayDate, getDisplayDateWithoutYear} from "../../../../../utils/dateUtils";
import NavLink from "../../../../../components/NavLink/NavLink";
import {Permission} from "../../../../../types";
import ToggleButton from "../../../../../components/Button/ToggleButton";
import {useResourceTypeToggle} from "../../../../../store/viewSlice";
import {resourceGroupToggleButtonTypeValuesWithAll, ResourceGroupToggleButtonTypeWithAll} from "../../../../../types";
import {filterWorkGroupsByResourceType} from "../../../workGroups/utils";
import routes from "../../../../../routes";


interface TripDefinitionWorkGroupsProps {
    workGroups: WorkGroup[];
}

export default function TripDefinitionWorkGroups({workGroups}: TripDefinitionWorkGroupsProps) {
    const [resourceTypeToggleButtonValue, handleResourceTypeChange] = useResourceTypeToggle<ResourceGroupToggleButtonTypeWithAll>(true);

    const sortedWorkGroups: WorkGroup[] = workGroups.flatMap(
        wg => (wg.versions ?? []).map((wgv: WorkGroupVersion) => ({
            ...wg,
            workGroupId: wg.id,
            id: wgv.id,
            validFrom: wgv.validFrom,
            validTo: wgv.validTo,
        }))
    ).sort((a,b) =>
        a.code.localeCompare(b.code) || (a.validFrom ?? '').localeCompare(b.validFrom ?? '')
    );

    const filteredWorkGroups = filterWorkGroupsByResourceType(sortedWorkGroups, resourceTypeToggleButtonValue);

    const columns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'code',
            headerName: 'Kood',
            minWidth: 100,
            maxWidth: 140,
            renderCell: (params) =>
                <NavLink id={params.row.workGroupId} value={params.row.code} route={routes.AUTHENTICATED.WORK_GROUPS.EDIT} navPermission={Permission.UpdateWorkGroups} openInNewWindow={true} />
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'type',
            headerName: 'Tüüp',
            minWidth: 100,
            maxWidth: 140,
            renderCell: (params) => params.value ?
                <Box>{getResourceTypeTranslation(params.value)}</Box> : <></>
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'validFrom',
            headerName: 'Algus',
            minWidth: 100,
            maxWidth: 140,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDate(params.value)}</Box> : <></>
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'validTo',
            headerName: 'Lõpp',
            minWidth: 100,
            maxWidth: 140,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDate(params.value)}</Box> : <></>
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'startDate',
            headerName: 'Perioodi algus',
            minWidth: 100,
            maxWidth: 140,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDateWithoutYear(params.value)}</Box> : <></>
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'endDate',
            headerName: 'Perioodi lõpp',
            minWidth: 100,
            maxWidth: 140,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}>{getDisplayDateWithoutYear(params.value)}</Box> : <></>
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'repetition',
            headerName: 'Korduvusreeglid',
            sortable: false,
            minWidth: 160,
            renderCell: (params) => params.value ?
                <Box style={{minWidth: 'fit-content'}}><Repetition repetition={params.value} /></Box> : <></>
        }
    ];

    return (sortedWorkGroups.length > 0 ?
        <Box my={2}>
            <Box sx={{
                display: {sm: 'flex'},
                alignItems: 'center',
                mb: {xs: 1, sm: 0},
            }}>
                <Typography variant="h5" my={1}>Reisiga seotud töögrupid</Typography>
                <Box sx={{ml: {sm: 2}}}>
                    <ToggleButton
                        options={[...resourceGroupToggleButtonTypeValuesWithAll]}
                        value={resourceTypeToggleButtonValue}
                        handleChange={handleResourceTypeChange}
                    />
                </Box>
            </Box>
            <DataGrid rows={filteredWorkGroups} columns={columns} />
        </Box>
        :
        null
    );
}
