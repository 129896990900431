import React, {ReactElement} from 'react';
import {
    Box,
    Checkbox as MuiCheckbox,
    CheckboxProps as MuiCheckboxProps,
    FormControl,
    FormControlLabel,
    Typography
} from '@mui/material';
import {useField} from "formik";
import Tooltip from "../../Tooltip/Tooltip";


interface CheckboxProps extends Omit<MuiCheckboxProps, 'name'> {
    name: string;
    label: string;
    labelIcon?: ReactElement;
}

export default function Checkbox({name, label, labelIcon, disabled, ...otherProps}: CheckboxProps) {
    const [field] = useField(name);

    const configCheckbox = {
        ...field,
        checked: field.value,
        disabled: disabled,
        ...otherProps
    };

    const labelColor = disabled ? 'text.disabled' : field.value ? 'text.primary' : 'text.secondary';

    const labelElement = labelIcon ?
        <Tooltip title={label}>
            <Box color={labelColor} sx={{display: 'flex', alignItems: 'center'}}>
                {labelIcon}
            </Box>
        </Tooltip>
        :
        <Typography color={labelColor}>{label}</Typography>;

    return (
        <Box sx={{ml: 0.5}}>
            <FormControl>
                <FormControlLabel
                    control={
                        <MuiCheckbox
                            color="primary"
                            {...configCheckbox}
                        />
                    }
                    label={labelElement}
                />
            </FormControl>
        </Box>
    );
}
