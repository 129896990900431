import {createSelector, EntityId} from "@reduxjs/toolkit";
import {RootState} from "../../../../store";
import {BusOption, DriverOption} from "../types";
import {ResourceType, WorkSheet} from "../../../../API/types";
import {selectAllBuses} from "../../../../store/busSlice";
import {
    selectAllBreathalyzerResults,
    selectAllWorkScheduleItems,
    selectWorkItemById,
    selectWorkScheduleItemEntityId,
    selectWorkScheduleItemsOnDay,
    WorkScheduleItem,
} from "../../../../store/workScheduleItemSlice";
import {
    selectWorkScheduleItemWithCalculatedHours,
    selectWorkScheduleItemWithWorkGroup
} from "../../workSchedule/store/selectors";
import {
    selectWorkGroupByIdAndDate,
    selectWorkGroupItemById
} from "../../../../store/workGroupSlice";
import {
    CalculatedWork,
    calculateHours,
    formatName,
    restTimeLessThan9Hours,
    WorkItem as CalculatorWorkItem
} from "../../workSchedule/utils";
import dayjs from "dayjs";
import {selectAllDrivers} from "../../../../store/driverSlice";
import {WorkScheduleItemStatus} from "../../../../API/workSchedule/types";
import {selectToggledResourceType} from "../../../../store/viewSlice";
import {isToday} from "../../../../utils/utils";
import {selectSelectedRegion} from "../../../../store/regionSlice";


export const selectDriverOptions = createSelector(
    selectAllDrivers,
    (state: RootState) => state.region.selected,
    (drivers, regionId): DriverOption[] => {
        const regionDrivers = regionId ? drivers.filter(driver => driver.regionIds.includes(regionId)) : drivers;

        return regionDrivers.flatMap(
            driver => driver.contracts.map((contract): DriverOption => ({
                id: contract.id ?? 0,
                name: formatName(driver, 'lastNameFirst'),
                active: driver.active,
                contractStartDate: contract.startDate,
                contractEndDate: contract.endDate,
                nominalWeeklyWorkingHours: contract.nominalWeeklyWorkingHours,
            }))
        )
    }
);

export const selectBusOptions = createSelector(
    selectAllBuses,
    (state: RootState) => state.region.selected,
    (buses, regionId): BusOption[] => {
        const regionBuses = regionId ? buses.filter(bus => bus.regionIds.includes(regionId)) : buses;

        return regionBuses.map(bus =>({
                id: bus.id,
                name: bus.licencePlateNumber,
                active: bus.active,
            })
        );
    }
);

export const selectPrimaryResourceNameById = createSelector(
    selectAllBuses,
    selectAllDrivers,
    (state: RootState) => selectToggledResourceType(state),
    (_: RootState, id: number) => id,
    (buses, drivers, resourceType, id) => {
        if (0 === id) {
            return ''
        }

        if (resourceType === ResourceType.DRIVER) {
            const driver = drivers.find(driver =>
                driver.contracts.some(contract => contract.id === id));

            return driver ? formatName(driver, 'lastNameFirst') : '';
        } else {
            return buses.find(bus => bus.id === id)?.licencePlateNumber ?? '';
        }
    }
);

export const selectSecondaryResourceNameById = createSelector(
    selectAllBuses,
    selectAllDrivers,
    (state: RootState) => selectToggledResourceType(state),
    (_: RootState, id: number) => id,
    (buses, drivers, resourceType, id) => {
        if (0 === id) {
            return ''
        }

        if (resourceType === ResourceType.VEHICLE) {
            const driver = drivers.find(driver =>
                driver.contracts.some(contract => contract.id === id));

            return driver ? formatName(driver, 'lastNameFirst') : '';
        } else {
            return buses.find(bus => bus.id === id)?.licencePlateNumber ?? '';
        }
    }
);

export const selectBusOrDriverIdByResourceId = (state: RootState, resourceId: number, resourceType: ResourceType) => {
    if (resourceType === ResourceType.DRIVER) {
        const drivers = selectAllDrivers(state);
        const driver = drivers.find(driver =>
            driver.contracts.some(contract => contract.id === resourceId));

        return driver?.id;
    } else {
        return resourceId;
    }
};

export const selectResourceNameByTypeNullable = (state: RootState, resourceId?: number, resourceType?: ResourceType) => {
    if (!resourceId || !resourceType) return undefined;
    return selectResourceNameByType(state, resourceId, resourceType);
};

export const selectResourceNameByType = (state: RootState, resourceId: number, resourceType: ResourceType) => {
    if (resourceType === ResourceType.DRIVER) {
        const drivers = selectAllDrivers(state);
        const driver = drivers.find(driver =>
            driver.contracts.some(contract => contract.id === resourceId));

        return driver ? formatName(driver, 'lastNameFirst') : '';
    } else {
        const buses = selectAllBuses(state);
        return buses.find(bus => bus.id === resourceId)?.licencePlateNumber ?? '';
    }
};

export const selectWorkScheduleItemWorkGroupCode = (state: RootState, id: EntityId) =>
    selectWorkScheduleItemWithWorkGroup(state, id)?.workGroup?.code;

export const calculateJointHours = (state: RootState, itemA: WorkScheduleItem, itemB: WorkScheduleItem): CalculatedWork => {
    const joint: CalculatorWorkItem[] = [];

    if (itemA.status === WorkScheduleItemStatus.SCHEDULED) {
        const workGroupA = itemA.workGroupId ? selectWorkGroupByIdAndDate(state, itemA.workGroupId, itemA.startDate) : undefined;
        const workGroupB = itemB.workGroupId ? selectWorkGroupByIdAndDate(state, itemB.workGroupId, itemB.startDate) : undefined;

        workGroupA?.workGroupItemIds?.forEach(id => {
            if(workGroupB?.workGroupItemIds?.includes(id)) {
                const item = selectWorkGroupItemById(state, id);
                if (item) {
                    joint.push({
                        startTime: dayjs(`${itemA.startDate} ${item.startTime}`).add(item.startTimeIsOnNextDay ? 1 : 0, 'day'),
                        endTime: dayjs(`${itemA.startDate} ${item.endTime}`).add(item.endTimeIsOnNextDay ? 1 : 0, 'day'),
                        type: item.type,
                        distance: item.distance ?? 0,
                    })
                }
            }
        });
    } else {
        itemA.workItemIds?.forEach(id => {
            if(itemB.workItemIds?.includes(id)) {
                const item = selectWorkItemById(state, id);
                if (item) {
                    joint.push({
                        startTime: dayjs(item.startDateTime),
                        endTime: dayjs(item.endDateTime),
                        type: item.type,
                        distance: item.distance,
                    })
                }
            }
        });
    }

    return calculateHours(joint);
};

export const selectSelectedDay = (state: RootState) => state.view.date;
export const selectIsSelectedDayToday = (state: RootState) => isToday(state.view.date);

export const selectWorkSheets = (state: RootState): WorkSheet[] => {
    const workScheduleItems = selectWorkScheduleItemsOnDay(state);
    const resourceType = selectToggledResourceType(state);

    const result: WorkSheet[] = [];

    workScheduleItems
        .filter(workScheduleItem => workScheduleItem.resourceType === resourceType)
        .forEach(primaryWorkScheduleItem => {
            if (primaryWorkScheduleItem.status === WorkScheduleItemStatus.SCHEDULED) {
                const itemWithWorkGroup = selectWorkScheduleItemWithWorkGroup(
                    state,
                    selectWorkScheduleItemEntityId(primaryWorkScheduleItem)
                );
                if (itemWithWorkGroup) {
                    const workGroupItems = itemWithWorkGroup.workGroup?.items ?? [];
                    const connected: WorkSheet[] = [];
                    workGroupItems.forEach(item => {
                        const connectedWorkScheduleItem = workScheduleItems.find(
                            secondaryWorkScheduleItem => {
                                if (secondaryWorkScheduleItem.resourceType === resourceType) { // Ignore same resource type
                                    return false;
                                }
                                const itemWithWorkGroup = selectWorkScheduleItemWithWorkGroup(
                                    state,
                                    selectWorkScheduleItemEntityId(secondaryWorkScheduleItem)
                                );
                                return (itemWithWorkGroup?.workGroup?.items.some(innerItem => item.id === innerItem.id))
                            }
                        );
                        if (!connectedWorkScheduleItem || connected.find(connectedItem =>
                            connectedItem.id === connectedWorkScheduleItem.id
                            && connectedItem.workGroupId === connectedWorkScheduleItem.workGroupId
                        )) {
                            return;
                        }

                        const connectedWithHours = selectWorkScheduleItemWithCalculatedHours(state, selectWorkScheduleItemEntityId(connectedWorkScheduleItem));
                        if (connectedWorkScheduleItem && connectedWithHours) {
                            const joint = calculateJointHours(state, primaryWorkScheduleItem, connectedWorkScheduleItem);
                            connected.push({
                                id: connectedWithHours.id,
                                workGroupId: connectedWithHours.workGroupId,
                                workGroupCode: connectedWithHours.workGroupCode,
                                resourceId: connectedWithHours.resourceId ?? null,
                                resourceType: connectedWithHours.resourceType,
                                date: connectedWithHours.startDate,
                                status: connectedWithHours.status,
                                departureTime: connectedWithHours?.departureDateTime,
                                startTime: joint.startDateTime,
                                endTime: joint.endDateTime,
                                distance: joint.distance,
                                regionId: connectedWithHours.regionId,
                                connectedWorkSheets: [],
                            });
                        }
                    });

                    const primaryWithHours = selectWorkScheduleItemWithCalculatedHours(state, selectWorkScheduleItemEntityId(primaryWorkScheduleItem));
                    result.push({
                        id: primaryWorkScheduleItem.id,
                        workGroupId: primaryWorkScheduleItem.workGroupId,
                        workGroupCode: primaryWithHours?.workGroupCode ?? '',
                        resourceId: primaryWithHours?.resourceId ?? null,
                        resourceType: primaryWorkScheduleItem?.resourceType,
                        date: primaryWorkScheduleItem?.startDate,
                        status: primaryWorkScheduleItem?.status,
                        departureTime: primaryWithHours?.departureDateTime,
                        startTime: primaryWithHours?.startDateTime,
                        endTime: primaryWithHours?.endDateTime,
                        distance: primaryWithHours?.distance,
                        regionId: primaryWorkScheduleItem?.regionId,
                        connectedWorkSheets: connected,
                    })
                }
            } else {
                const workItems = primaryWorkScheduleItem.workItemIds ?? [];
                const connected: WorkSheet[] = [];
                workItems.forEach(workItemId => {
                    const connectedWorkScheduleItem = workScheduleItems.find(
                        secondaryWorkScheduleItem => {
                            if (secondaryWorkScheduleItem.resourceType === resourceType) { // Ignore same resource type
                                return false;
                            }
                            if (secondaryWorkScheduleItem.workItemIds?.includes(workItemId)) {
                                return true;
                            }
                        }
                    );
                    if (!connectedWorkScheduleItem || connected.find(connectedItem =>
                        connectedItem.id === connectedWorkScheduleItem.id
                    )) {
                        return;
                    }

                    const connectedWithHours = selectWorkScheduleItemWithCalculatedHours(state, selectWorkScheduleItemEntityId(connectedWorkScheduleItem));
                    if (connectedWorkScheduleItem && connectedWithHours) {
                        const joint = calculateJointHours(state, primaryWorkScheduleItem, connectedWorkScheduleItem);
                        connected.push({
                            id: connectedWithHours.id,
                            workGroupId: connectedWithHours.workGroupId,
                            workGroupCode: connectedWithHours.workGroupCode,
                            resourceId: connectedWithHours.resourceId ?? null,
                            resourceType: connectedWithHours.resourceType,
                            date: connectedWithHours.startDate,
                            status: connectedWithHours.status,
                            departureTime: connectedWithHours?.departureDateTime,
                            startTime: joint.startDateTime,
                            endTime: joint.endDateTime,
                            distance: joint.distance,
                            regionId: connectedWithHours.regionId,
                            connectedWorkSheets: [],
                        });
                    }
                });

                const primaryWithHours = selectWorkScheduleItemWithCalculatedHours(state, selectWorkScheduleItemEntityId(primaryWorkScheduleItem));
                result.push({
                    id: primaryWorkScheduleItem.id,
                    workGroupId: primaryWorkScheduleItem.workGroupId,
                    workGroupCode: primaryWithHours?.workGroupCode,
                    resourceId: primaryWithHours?.resourceId ?? null,
                    resourceType: primaryWorkScheduleItem?.resourceType,
                    date: primaryWorkScheduleItem?.startDate,
                    status: primaryWorkScheduleItem?.status,
                    departureTime: primaryWithHours?.departureDateTime,
                    startTime: primaryWithHours?.startDateTime,
                    endTime: primaryWithHours?.endDateTime,
                    distance: primaryWithHours?.distance,
                    regionId: primaryWorkScheduleItem?.regionId,
                    connectedWorkSheets: connected,
                })
            }
        });

    return result;
};

export const selectHasRestTimeLessThan9HoursWarning = (state: RootState, workSheet: WorkSheet): boolean => {
    if (workSheet.resourceType !== ResourceType.DRIVER) {
        return false;
    }
    if (!workSheet.resourceId) {
        return false;
    }
    const allWorkSheets = selectAllWorkScheduleItems(state);
    const driverWorkSheets = allWorkSheets.filter(item => item.resourceId === workSheet.resourceId);
    if (driverWorkSheets.length < 2) {
        return false;
    }
    const prevDayWorkSheet = allWorkSheets.find(item => item.resourceId === workSheet.resourceId && item.startDate < workSheet.date)
    const nextDayWorkSheet = allWorkSheets.find(item => item.resourceId === workSheet.resourceId && item.startDate > workSheet.date)

    if (!prevDayWorkSheet && !nextDayWorkSheet) {
        return false;
    }

    const currentDayWorkSheetWithHours = selectWorkScheduleItemWithCalculatedHours(state, selectWorkScheduleItemEntityId({
        id: workSheet.id,
        workGroupId: workSheet.workGroupId,
        startDate: workSheet.date,
    }));

    if (prevDayWorkSheet) {
        const prevDayWorkSheetWithHours = selectWorkScheduleItemWithCalculatedHours(state, selectWorkScheduleItemEntityId(prevDayWorkSheet));
        if (restTimeLessThan9Hours(currentDayWorkSheetWithHours, prevDayWorkSheetWithHours)) {
            return true;
        }
    }

    if (nextDayWorkSheet) {
        const nextDayWorkSheetWithHours = selectWorkScheduleItemWithCalculatedHours(state, selectWorkScheduleItemEntityId(nextDayWorkSheet));
        if (restTimeLessThan9Hours(nextDayWorkSheetWithHours, currentDayWorkSheetWithHours)) {
            return true;
        }
    }

    return false;
};

export const selectShowBreathalyzerColumn = (state: RootState): boolean => {
    const selectedDate = selectSelectedDay(state);
    const resourceType = selectToggledResourceType(state);
    const selectedRegion = selectSelectedRegion(state);
    const breathalyzerResults = selectAllBreathalyzerResults(state);

    return resourceType === ResourceType.DRIVER
        && !!selectedRegion?.showBreathalyzerResult
        && ((breathalyzerResults && breathalyzerResults.length > 0) || !dayjs(selectedDate).isBefore(dayjs(), 'day'));
};
