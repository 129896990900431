import {
    ResourceWorkSheet,
    WorkSheetDetails as ApiWorkSheetDetails,
    WorkSheetWorkItem
} from "../../../../API/workSheets/types";
import {BusUsageWithDistance, BusUsageWithOdometerReadings} from "../../dashboard/DriverDashboard/types";
import dayjs from "dayjs";
import {Bus, OdometerReadingWithBusId} from "../../../../API/bus/types";
import {
    findNearestReading,
    findPreviousReading,
    isReadingWithinAllowedTimeRange
} from "../../../../features/Form/ReadingForm/utils";
import {BusUsage} from "../../../../API/workSchedule/types";
import {SelectOptionWithIdAndRegion} from "../../../../types";
import {formatName} from "../../workSchedule/utils";
import {WorkGroupItemType} from "../../../../API/workGroup/types";
import {getWorkGroupItemTypeTranslation} from "../../../../utils/enumTranslations";
import {getStartAndEndPointLabel} from "../../../../utils/utils";
import {isEqual} from "lodash";
import {OdometerReadingRow} from "./components/odometerReadings/OdometerReadings";


export const hasAnItemFromAnotherDriverWithinTimeframe = (
    timeframeStartDateTime: string,
    timeframeEndDateTime: string,
    items: {startDateTime: string, driverWorkSheetId?: number | null}[],
    driverWorkSheetId: number
): boolean => {
    const startTime = dayjs(timeframeStartDateTime);
    const endTime = dayjs(timeframeEndDateTime);

    for (const item of items) {
        if (item.driverWorkSheetId !== driverWorkSheetId) {
            const itemTime = dayjs(item.startDateTime);

            if ((!itemTime.isSame(startTime) && (itemTime.isAfter(startTime)) && (itemTime.isBefore(endTime)) && !itemTime.isSame(endTime))) {
                return true;
            }
        }
    }

    return false;
};

export const isTheItemWithinTimeframeWithBuffer = (timeframeStartDateTime: string, timeframeEndDateTime: string, itemDateTime: string): boolean => {
    const startTime = dayjs(timeframeStartDateTime);
    const endTime = dayjs(timeframeEndDateTime);
    const itemTime = dayjs(itemDateTime);

    return (!itemTime.isSame(startTime) && (itemTime.isAfter(startTime)) && (itemTime.isBefore(endTime)) && !itemTime.isSame(endTime))
        || isReadingWithinAllowedTimeRange(startTime, itemTime) || isReadingWithinAllowedTimeRange(endTime, itemTime);
};

export const getBusUsages = (
    sortedWorkItems: {
        busWorkSheetId: number | null;
        startDateTime: string;
        endDateTime: string;
        distance: number | null;
    }[],
    secondaryWorkSheets: ApiWorkSheetDetails[],
    driverWorkSheetId: number
) => {
    const busUsages: BusUsageWithDistance[] = [];
    let currentBusId: number | undefined;
    let currentStartDateTime: string | undefined;
    let currentEndDateTime: string | undefined;
    let currentStartDateTimeOfFirstActivityWithDistance: string | undefined;
    let currentEndDateTimeOfLastActivityWithDistance: string | undefined;
    let currentDistance: number = 0;

    sortedWorkItems.forEach(workItem => {
        const busWorkSheet = secondaryWorkSheets.find(secondaryWorkSheet => secondaryWorkSheet.id === workItem.busWorkSheetId);
        const busId = busWorkSheet ? busWorkSheet.resourceId : undefined;
        if (busId) {
            // if the current bus usage id does not match the work item bus id
            if (currentBusId !== busId) {
                // if there currently already is a bus usage, then add it to usages
                if (currentBusId && currentStartDateTime && currentEndDateTime) {
                    busUsages.push({
                        busId: currentBusId,
                        startDateTime: currentStartDateTime,
                        startDateTimeOfFirstActivityWithDistance: currentStartDateTimeOfFirstActivityWithDistance,
                        endDateTime: currentEndDateTime,
                        endDateTimeOfLastActivityWithDistance: currentEndDateTimeOfLastActivityWithDistance,
                        totalDistance: currentDistance,
                    });
                }
                // start new current bus usage
                currentBusId = busId;
                currentStartDateTime = workItem.startDateTime;
                currentEndDateTime = workItem.endDateTime;
                currentDistance = workItem.distance ?? 0;
                if (workItem.distance && workItem.distance !== 0) {
                    currentStartDateTimeOfFirstActivityWithDistance = workItem.startDateTime;
                    currentEndDateTimeOfLastActivityWithDistance = workItem.endDateTime;
                } else {
                    currentStartDateTimeOfFirstActivityWithDistance = undefined;
                    currentEndDateTimeOfLastActivityWithDistance = undefined;
                }
            } else {
                // if the current bus usage matches the work item bus id, check whether there has been another usage of bus
                const busWorkItems = busWorkSheet?.workItems;
                if (busWorkItems && currentStartDateTime && currentEndDateTime && hasAnItemFromAnotherDriverWithinTimeframe(currentEndDateTime, workItem.startDateTime, busWorkItems, driverWorkSheetId)) {
                    // if another driver has used the bus in the meantime then create a bus usage
                    busUsages.push({
                        busId: currentBusId,
                        startDateTime: currentStartDateTime,
                        startDateTimeOfFirstActivityWithDistance: currentStartDateTimeOfFirstActivityWithDistance,
                        endDateTime: currentEndDateTime,
                        endDateTimeOfLastActivityWithDistance: currentEndDateTimeOfLastActivityWithDistance,
                        totalDistance: currentDistance,
                    });
                    currentBusId = busId;
                    currentStartDateTime = workItem.startDateTime;
                    currentEndDateTime = workItem.endDateTime;
                    currentDistance = workItem.distance ?? 0;
                    if (workItem.distance && workItem.distance !== 0) {
                        currentStartDateTimeOfFirstActivityWithDistance = workItem.startDateTime;
                        currentEndDateTimeOfLastActivityWithDistance = workItem.endDateTime;
                    } else {
                        currentStartDateTimeOfFirstActivityWithDistance = undefined;
                        currentEndDateTimeOfLastActivityWithDistance = undefined;
                    }
                } else {
                    // add to current bus usage
                    currentEndDateTime = workItem.endDateTime;
                    currentDistance += workItem.distance ?? 0;
                    if (workItem.distance && workItem.distance !== 0) {
                        if (!currentStartDateTimeOfFirstActivityWithDistance) {
                            currentStartDateTimeOfFirstActivityWithDistance = workItem.startDateTime;
                        }
                        if (!currentEndDateTimeOfLastActivityWithDistance || workItem.endDateTime > currentEndDateTimeOfLastActivityWithDistance) {
                            currentEndDateTimeOfLastActivityWithDistance = workItem.endDateTime;
                        }
                    }
                }
            }
        }
    });

    // add the last bus usage to usages
    if (currentBusId && currentStartDateTime && currentEndDateTime) {
        busUsages.push({
            busId: currentBusId,
            startDateTime: currentStartDateTime,
            startDateTimeOfFirstActivityWithDistance: currentStartDateTimeOfFirstActivityWithDistance,
            endDateTime: currentEndDateTime,
            endDateTimeOfLastActivityWithDistance: currentEndDateTimeOfLastActivityWithDistance,
            totalDistance: currentDistance,
        });
    }

    return busUsages;
};

export const isItTheSameBusUsage = (originalBusUsage: BusUsage, newBusUsage: BusUsage) => isEqual(originalBusUsage, newBusUsage)

const findReading = (busOdometerReadings: OdometerReadingWithBusId[], mandatoryPriorityDateTime: string, optionalFallbackDateTime: string | undefined) => {
    let result = findNearestReading(busOdometerReadings, mandatoryPriorityDateTime);
    if (!result && optionalFallbackDateTime) {
        result = findNearestReading(busOdometerReadings, optionalFallbackDateTime);
    }
    return result;
};

const findStartReading = (busOdometerReadings: OdometerReadingWithBusId[], busUsage: BusUsageWithDistance) => {
    return findReading(busOdometerReadings, busUsage.startDateTime, busUsage.startDateTimeOfFirstActivityWithDistance);
};

const findEndReading = (busOdometerReadings: OdometerReadingWithBusId[], busUsage: BusUsageWithDistance) => {
    return findReading(busOdometerReadings, busUsage.endDateTime, busUsage.endDateTimeOfLastActivityWithDistance);
};

export const getReadingRows = (workSheet: ApiWorkSheetDetails, busUsages: BusUsageWithDistance[], buses: Bus[], driverId?: number): OdometerReadingRow[] => {
    const sortedReadingsOfCurrentDriver = workSheet.odometerReadings
        .filter(reading => reading.driverId === driverId)
        .sort((a, b) => a.dateTime.localeCompare(b.dateTime));
    const readingRows: OdometerReadingRow[] = [];

    busUsages.forEach(busUsage => {
        const busOdometerReadings = getValidOdometerReadingsDuringBusUsage(sortedReadingsOfCurrentDriver, busUsage);
        const startReading = findStartReading(busOdometerReadings, busUsage);
        const previousReading = findPreviousReading(busOdometerReadings, busUsage.startDateTime, startReading?.id);
        const endReading = findEndReading(busOdometerReadings, busUsage);
        const licencePlateNr = buses.find(bus => bus.id === busUsage.busId)?.licencePlateNumber;

        busOdometerReadings.forEach(reading => {
            // don't include the previous reading as it is not relevant to the work sheet, only used in the difference calc
            if (!previousReading || reading.id !== previousReading.id) {
                if (startReading && reading.id === startReading.id) {
                    readingRows.push({...reading, displayType: 'Algnäit', licencePlateNumber: licencePlateNr, busUsage: busUsage});
                } else if (endReading && reading.id === endReading.id) {
                    const initialReading = startReading?.reading ?? previousReading?.reading ?? undefined;
                    const distanceDiff = initialReading ? endReading.reading - initialReading - busUsage.totalDistance : undefined;
                    readingRows.push({
                        ...reading,
                        displayType: 'Lõppnäit',
                        licencePlateNumber: licencePlateNr,
                        expectedTotalDistance: busUsage.totalDistance,
                        distanceDiff: distanceDiff,
                        busUsage: busUsage,
                    })
                } else if (reading.fuelType) {
                    readingRows.push({...reading, displayType: 'Tankimine', licencePlateNumber: licencePlateNr});
                } else {
                    readingRows.push({...reading, displayType: 'Vahenäit', licencePlateNumber: licencePlateNr});
                }
            }
        });
    });

    // Add extra readings if not added
    sortedReadingsOfCurrentDriver.forEach(reading => {
        if (
            dayjs(reading.dateTime) >= dayjs(workSheet.startDate).startOf('day')
            && !readingRows.some(readingRow => readingRow.id === reading.id)
        ) {
            const licencePlateNr = buses.find(bus => bus.id === reading.busId)?.licencePlateNumber;
            if (reading.fuelType) {
                readingRows.push({...reading, displayType: 'Tankimine', licencePlateNumber: licencePlateNr});
            } else {
                readingRows.push({...reading, displayType: 'Vahenäit', licencePlateNumber: licencePlateNr});
            }
        }
    });

    return readingRows.sort((a, b) => a.dateTime.localeCompare(b.dateTime));
};

export const getValidOdometerReadingsDuringBusUsage = (odometerReadings: OdometerReadingWithBusId[], busUsage: BusUsage) => {
    const odometerReadingsOfTheCurrentBus = odometerReadings.filter(reading => reading.busId === busUsage.busId);
    const validReadings: OdometerReadingWithBusId[] = [];

    odometerReadingsOfTheCurrentBus.forEach(reading => {
        if (isTheItemWithinTimeframeWithBuffer(busUsage.startDateTime, busUsage.endDateTime, reading.dateTime)) validReadings.push(reading);
    });

    return validReadings;
};

export const getBusWorkSheetOption = (workItem: WorkSheetWorkItem | undefined): SelectOptionWithIdAndRegion | null => {
    if (!workItem?.busWorkSheet?.bus) {
        return null;
    }

    return workSheetToBusOption(workItem.busWorkSheet);
};

export const workSheetToBusOption = (busWorkSheet: ResourceWorkSheet): SelectOptionWithIdAndRegion => {
    return {
        id: busWorkSheet.id,
        name: busWorkSheet.bus!.licencePlateNumber,
        regionName: busWorkSheet.region.name,
    }
};

export const getDriverWorkSheetOption = (workItem: WorkSheetWorkItem | undefined): SelectOptionWithIdAndRegion | null => {
    if (!workItem?.driverWorkSheet?.driver) {
        return null;
    }

    return workSheetToDriverOption(workItem.driverWorkSheet);
};

export const workSheetToDriverOption = (driverWorkSheet: ResourceWorkSheet): SelectOptionWithIdAndRegion => {
    return {
        id: driverWorkSheet.id,
        name: formatName(driverWorkSheet.driver!, 'lastNameFirst'),
        regionName: driverWorkSheet.region.name
    };
};

export const getBusWorkSheetOptions = (
    workSheetsWithResources: ResourceWorkSheet[],
    workItemOption: SelectOptionWithIdAndRegion | null,
    preferredRegionName: string | undefined,
): SelectOptionWithIdAndRegion[] => {
    return getSortedResourceOptions(
        workSheetsWithResources.filter(w => w.bus != null).map(item => workSheetToBusOption(item)),
        workItemOption,
        preferredRegionName
    );
};

export const getDriverWorkSheetOptions = (
    workSheetsWithResources: ResourceWorkSheet[],
    workItemOption: SelectOptionWithIdAndRegion | null | undefined,
    preferredRegionName: string | undefined,
): SelectOptionWithIdAndRegion[] => {
    return getSortedResourceOptions(
        workSheetsWithResources.filter(w => w.driver != null).map(item => workSheetToDriverOption(item)),
        workItemOption,
        preferredRegionName
    );
};

export const getSortedResourceOptions = <T extends { id: number, regionName: string, name: string }>(
    options: T[],
    workItemOption: T | null | undefined,
    preferredRegionName: string | undefined,
): T[] => {
    return sortByRegionAndName(mergeInto(workItemOption, options), preferredRegionName);
};

const sortByRegionAndName = <T extends { regionName: string, name: string }>(options: T[], preferredRegionName: string | undefined) =>
    options.sort((a, b) => {
        return compareRegionNames(a.regionName, b.regionName, preferredRegionName) * 10 + a.name.localeCompare(b.name);
    });

const compareRegionNames = (a: string, b: string, preferredRegionName: string | undefined) => {
    if (preferredRegionName && a === preferredRegionName && b !== preferredRegionName) {
        return -1;
    } else if (preferredRegionName && a !== preferredRegionName && b === preferredRegionName) {
        return 1;
    } else {
        return a.localeCompare(b);
    }
};

const mergeInto = <T extends { id: number }>(option: T | null | undefined, options: T[]) => {
    if (option && !options.find(o => o.id === option.id)) {
        return [option, ...options];
    } else {
        return [...options];
    }
};

export const getWorkItemLabel = (workItem: WorkSheetWorkItem) => {
    if (!workItem.type) return '';
    if (workItem.type === WorkGroupItemType.TRIP_DEFINITION) {
        if (workItem.charterTrip) {
            return `Tellimusvedu (${workItem.charterTrip.route})`;
        }
        if (workItem.trip) {
            return getWorkGroupItemTypeTranslation(workItem.type) + (workItem.route ? ` (${getStartAndEndPointLabel(workItem.route)})` : '');
        }
    }

    return getWorkGroupItemTypeTranslation(workItem.type);
};

export const getUpdatedOdometerReadings = (original?: BusUsageWithOdometerReadings, updated?: BusUsageWithDistance): OdometerReadingWithBusId[] => {
    if (!original?.odometerReadings?.length || !updated) return [];

    const startReading = findNearestReading(original.odometerReadings, original.startDateTime);
    const endReading = findNearestReading(original.odometerReadings, original.endDateTime);
    const newStartReading = findNearestReading(original.odometerReadings, updated.startDateTime);
    const newEndReading = findNearestReading(original.odometerReadings, updated.endDateTime);
    const updatedReadings: OdometerReadingWithBusId[] = [];

    if (startReading && (!newStartReading || startReading.id !== newStartReading.id)) updatedReadings.push({...startReading, dateTime: updated.startDateTime});
    if (endReading && (!newEndReading || endReading.id !== newEndReading.id)) updatedReadings.push({...endReading, dateTime: updated.endDateTime});

    return updatedReadings;
};

export const getAffectedOdometerReadings = (
    originalBusUsages: BusUsageWithOdometerReadings[],
    originalBusUsage?: BusUsageWithOdometerReadings,
    updatedBusUsages?: BusUsageWithDistance[],
    updatedBusUsage?: BusUsageWithDistance,
): OdometerReadingWithBusId[] => {
    if (isWithinTheOriginalUsage(originalBusUsages, originalBusUsage, updatedBusUsages, updatedBusUsage)) {
        // work item stays in the same bus usage, modify current bus usage reading if necessary
        return getUpdatedOdometerReadings(originalBusUsage, updatedBusUsage);
    } else if (originalBusUsage?.busId && updatedBusUsage?.busId && originalBusUsage.busId !== updatedBusUsage.busId) {
        // work item has changed its bus usage, modify both previous and new bus usage reading if necessary
        const updatedOriginalUsageFrom = getMatchingOriginalOrUpdatedUsage(originalBusUsage, updatedBusUsages);
        const originalUsageTo = getMatchingOriginalOrUpdatedUsage(updatedBusUsage, originalBusUsages);
        return [
            ...getUpdatedOdometerReadings(originalBusUsage, updatedOriginalUsageFrom),
            ...getUpdatedOdometerReadings(originalUsageTo, updatedBusUsage)
        ];
    } else if (!originalBusUsage?.busId && updatedBusUsage?.busId) {
        // completely new work item or item did not have a bus before but is now part of a bus usage, modify new bus usage reading if necessary
        const originalUsage = getMatchingOriginalOrUpdatedUsage(updatedBusUsage, originalBusUsages);
        return getUpdatedOdometerReadings(originalUsage, updatedBusUsage);
    } else if (originalBusUsage?.busId && !updatedBusUsage?.busId) {
        // work item had a bus but no longer has a bus, modify original bus usage reading if necessary
        const updatedOriginalUsage = getMatchingOriginalOrUpdatedUsage(originalBusUsage, updatedBusUsages);
        return getUpdatedOdometerReadings(originalBusUsage, updatedOriginalUsage);
    }

    return [];
};

export const getMatchingOriginalOrUpdatedUsage = <T extends { busId?: number; startDateTime: string; endDateTime: string }>(
    targetUsage: { busId?: number; startDateTime: string; endDateTime: string },
    usages?: T[],
): T | undefined =>
    usages?.find(usage =>
        targetUsage.busId === usage.busId &&
        (targetUsage.startDateTime === usage.startDateTime || targetUsage.endDateTime === usage.endDateTime)
    );

const isWithinTheOriginalUsage = (
    originalBusUsages: BusUsageWithOdometerReadings[],
    originalBusUsage?: BusUsageWithOdometerReadings,
    updatedBusUsages?: BusUsageWithDistance[],
    updatedBusUsage?: BusUsageWithDistance,
) => originalBusUsage && updatedBusUsage && originalBusUsage.busId === updatedBusUsage.busId
    && (originalBusUsages.findIndex(usage => usage.busId === originalBusUsage?.busId)
        === updatedBusUsages?.findIndex(usage => usage.busId === updatedBusUsage?.busId));
