import {NotificationSubscription} from "../../../../API/notifications/types";
import {Box, List, ListItem, ListItemIcon, Typography} from "@mui/material";
import {getNotificationSubscriptionTypeTranslation} from "../../../../utils/enumTranslations";
import StaticIcon from "../../../../components/Icon/StaticIcon";
import React from "react";
import {Check} from "@mui/icons-material";
import Tooltip from "../../../../components/Tooltip/Tooltip";


interface UserNotificationsCellProps {
    notificationSubscriptions?: NotificationSubscription[] | null;
}

const UserNotificationsCell = ({notificationSubscriptions}: UserNotificationsCellProps) => {
    if (!notificationSubscriptions || notificationSubscriptions.length === 0) return null;

    return (
        <Tooltip title={
            <Box p={0.5}>
                Sisselülitatud teavitused:
                <List sx={{pl: 0.5, pb: 0, color: 'inherit'}}>
                    {notificationSubscriptions.map((notification: NotificationSubscription) =>
                        <ListItem key={notification.id} disablePadding sx={{display: 'flex', alignItems: 'start', pt: 0.5}}>
                            <ListItemIcon sx={{minWidth: '10px', color: 'inherit', mr: 0.5}}><Check fontSize="small" /></ListItemIcon>
                            <Typography sx={{color: 'inherit', fontSize: 'inherit'}}>
                                <span style={{fontWeight: 'bold'}}>
                                    {getNotificationSubscriptionTypeTranslation(notification.type)}
                                </span>
                                <br/>
                                <span style={{fontStyle: 'italic'}}>
                                    ({notification.regions.map(region => region.name).join(', ')})
                                </span>
                            </Typography>
                        </ListItem>
                    )}
                </List>
            </Box>
        }>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <StaticIcon type="NOTIFICATION" />
                {notificationSubscriptions.length}
            </Box>
        </Tooltip>
    )
};

export default UserNotificationsCell;
