import React, {ReactNode, useState} from "react";
import CardBase from "../../../components/CardBase";
import {Box, Collapse, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {getWorkGroupItemTypeTranslation} from "../../../../../../../utils/enumTranslations";
import BusLabel from "../../../components/BusLabel";
import TimeLabel from "../../../components/TimeLabel";
import {getStartAndEndPointLabel} from "../../../../../../../utils/utils";
import {RoutePoint} from "../../../../../../../API/types";
import {ChevronRight, Comment, ErrorRounded, Hail} from "@mui/icons-material";
import {useAppSelector} from "../../../../../../../hooks";
import {
    selectCurrentDriverBusByBusWorkSheetId,
    selectCurrentDriverWorkItemById,
    selectDefectByWorkSheetId
} from "../../../../../../../store/currentDriverWorkSheetsSlice";
import {Defect} from "./DefectAlert";
import Tooltip from "../../../../../../../components/Tooltip/Tooltip";


const getRoutePointLabel = (routePoint: RoutePoint) => {
    return (
        <Typography variant="body2" sx={{alignItems: 'center', display: 'flex', height: '20px'}}>
            {routePoint.name}
            {routePoint.stopName && ` (${routePoint.stopName})`}
            {routePoint.requestStop && <Tooltip title="Nõudepeatus"><Hail sx={{height: '18px'}} /></Tooltip>}
        </Typography>
    );
};

const TitledRow = ({title, children}: {title: string, children?: ReactNode}) => {
    if (!children) {
        return null;
    }

    return (
        <Typography variant="body2" sx={{my: 0.5, whiteSpace: 'pre-wrap', '&:first-of-type': { mt: 2 }}}>
            <Box component="span" sx={{fontWeight: 'bold'}}>{title}: </Box>
            {children}
        </Typography>
    );
};

const ActivityCard = ({id}: { id: number }) => {
    const [cardOpen, setCardOpen] = useState(false);
    const item = useAppSelector(state => selectCurrentDriverWorkItemById(state, id));
    const bus = useAppSelector(state => selectCurrentDriverBusByBusWorkSheetId(state, item?.busWorkSheetId ?? 0));
    const defect = useAppSelector(state => selectDefectByWorkSheetId(state, item?.driverWorkSheetId ?? 0, item?.defectId ?? 0));
    const canOpen = !!(item?.route || item?.charterTrip || defect);

    const handleClick = () => {
        setCardOpen(prevState => !prevState);
    };

    return item && (
        <CardBase onClick={canOpen ? handleClick : undefined}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <Typography>
                    <Box component="span" sx={{fontWeight: 'bold'}}>
                        {item.charterTrip ? 'Tellimusvedu' : getWorkGroupItemTypeTranslation(item.type)}
                    </Box>
                    {item.trip?.code && ` ${item.trip.code}`}
                    {item.charterTrip?.tripNumber && ` ${item.charterTrip?.tripNumber}`}
                    {item.trip?.lineNumber && ` (liin ${item.trip.lineNumber})`}
                </Typography>
                <Typography>
                    <TimeLabel startDateTime={item.startDateTime} endDateTime={item.endDateTime} />
                </Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'end', gap: 0.5}}>
                <Typography variant="body2">
                    {item.route && getStartAndEndPointLabel(item.route)}
                    {item.charterTrip?.route}
                    {item.distance ? ` (${item.distance.toFixed(0)}km)` : ''}
                </Typography>
                {bus && <BusLabel licencePlateNumbers={[bus.licencePlateNumber]}/>}
            </Box>
            {item.comment &&
                <Box sx={{whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'top', my: 0.5}}>
                    <ErrorRounded sx={{fontSize: '16px', pr: 0.5, pt: 0.25}} color="info" />
                    <Box>
                        {item.trip?.comment && item.comment &&
                            <Typography variant="body2" sx={{fontWeight: 'bold'}}>Päeva kommentaar</Typography>
                        }
                        <Typography variant="body2">{item.comment.replace(/\n\s*\n/g, '\n')}</Typography>
                    </Box>
                </Box>
            }
            <Box sx={{pb: 0.5}}>
                {item.trip?.comment && item.trip.comment.length > 0 &&
                    <Box sx={{whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'top'}}>
                        <Comment sx={{fontSize: '16px', pr: 0.5, pt:0.25, color: 'text.secondary'}}/>
                        <Box>
                            {item.trip?.comment && item.comment &&
                                <Typography variant="body2" color="text.secondary" sx={{fontWeight: 'bold'}}>Reisi kommentaar</Typography>
                            }
                            <Typography variant="body2" color="text.secondary">{item.trip.comment.replace(/\n\s*\n/g, '\n')}</Typography>
                        </Box>
                    </Box>
                }
            </Box>
            <Collapse in={cardOpen}>
                {item.route && item.route.length > 0 &&
                    <List sx={{pl: 1}}>
                        {item.route.map(routePoint =>
                            <ListItem key={routePoint.id} disablePadding>
                                <ListItemIcon sx={{minWidth: '10px'}}> - </ListItemIcon>
                                <ListItemText primary={getRoutePointLabel(routePoint)} sx={{m: 0}}/>
                            </ListItem>
                        )}
                    </List>
                }
                {defect && <Defect defect={defect} />}
                <TitledRow title="Lähtekoht">{item.charterTrip?.departurePoint}</TitledRow>
                <TitledRow title="Sihtkoht">{item.charterTrip?.destination}</TitledRow>
                <TitledRow title="Reisijate arv">{item.charterTrip?.numberOfPassengers}</TitledRow>
                <TitledRow title="Klient">{item.charterTrip?.client}</TitledRow>
                <TitledRow title="Lisainfo">{item.charterTrip?.comment}</TitledRow>
            </Collapse>
            {canOpen &&
                <Typography variant="body2" color="text.secondary" sx={{textDecoration: 'underline', display: 'flex', alignItems: 'center', pt: 0.5}}>
                    {cardOpen ? 'Näita vähem' : 'Näita rohkem'}
                    <ChevronRight sx={{rotate: cardOpen ? '-90deg' : '90deg', fontSize: '16px', mt: '1px'}} />
                </Typography>
            }
        </CardBase>
    );
};

export default ActivityCard;
