import {OdometerReading, OdometerReadingWithBusId} from "../../../../../API/bus/types";
import {useAppDispatch, useAppSelector} from "../../../../../hooks";
import {fetchDrivers, selectAllDrivers} from "../../../../../store/driverSlice";
import React, {useEffect} from "react";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {getFuelTranslation} from "../../../../../utils/enumTranslations";
import DataGrid, {DEFAULT_COL_DEF_PROPS, DEFAULT_MENU_COL_DEF_PROPS} from "../../../../../components/DataGrid/DataGrid";
import {formatName} from "../../../workSchedule/utils";
import ActionIcon from "../../../../../components/Icon/ActionIcon";

interface BusOdometerReadingsProps {
    odometerReadings: OdometerReading[];
    handleEditOdometerReading: (reading: OdometerReadingWithBusId) => void;
    handleDeleteOdometerReading: (reading: OdometerReadingWithBusId) => void;
}

const BusOdometerReadings = ({
    odometerReadings,
    handleEditOdometerReading,
    handleDeleteOdometerReading
}: BusOdometerReadingsProps) => {
    const sorted = [...odometerReadings].sort(
        (a, b) => b.dateTime.localeCompare(a.dateTime)
    );
    const drivers = useAppSelector(selectAllDrivers);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchDrivers());
    }, []);

    const columns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'dateTime',
            headerName: 'Kuupäev/aeg',
            minWidth: 140,
            maxWidth: 200,
            valueFormatter: (params) => dayjs(params.value).format('DD.MM.YYYY HH:mm')
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'reading',
            headerName: 'Näit',
            sortable: false,
            minWidth: 100,
            maxWidth: 150,
            valueFormatter: (params) => `${Number(params.value).toLocaleString('et-EE')} km`,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'driverId',
            headerName: 'Juht',
            minWidth: 150,
            maxWidth: 200,
            valueGetter: (params) => drivers.find(driver => driver.id === params.value),
            valueFormatter: (params) => {
                return params.value ? formatName(params.value, 'lastNameFirst') : '';
            },
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'fuelType',
            headerName: 'Kütuse liik',
            sortable: false,
            minWidth: 100,
            maxWidth: 150,
            valueFormatter: (params) => getFuelTranslation(params.value)
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'fuelAmount',
            headerName: 'Tangitud kogus',
            sortable: false,
            minWidth: 100,
            maxWidth: 150,
            valueFormatter: (params) => `${params.value ? Number(params.value).toLocaleString('et-EE') : ''}`,
        },
        {
            ...DEFAULT_MENU_COL_DEF_PROPS,
            minWidth: 80,
            renderCell: (params: GridRenderCellParams) =>
                <>
                    <ActionIcon type="EDIT" id={params.row.id} alignTooltipArrowRight handleClick={() => handleEditOdometerReading(params.row)} />
                    <ActionIcon type="DELETE" id={params.row.id} alignTooltipArrowRight handleClick={() => handleDeleteOdometerReading(params.row)} />
                </>
        },
    ];

    return <DataGrid rows={sorted} columns={columns} />
};

export default BusOdometerReadings;
