import axios, {AxiosError, HttpStatusCode} from "axios";
import {WorkSheetBreathalyzerResult} from "./types";
import {Dayjs} from "dayjs";
import {handleError} from "../utils";


export const getBreathalyzerResults = (date: Dayjs, regionId: number) =>
    axios.get<WorkSheetBreathalyzerResult[]>(`/api/breathalyzer-results?date=${date.format('YYYY-MM-DD')}&regionId=${regionId}`)
        .then(result => result.data)
        .catch(handleError);

export const getBreathalyzerResult = (workSheetId: string | number) =>
    axios.get<WorkSheetBreathalyzerResult | undefined>(`/api/breathalyzer-results/work-sheet/${workSheetId}`)
        .then(result => result.data)
        .catch((error: AxiosError) => {
            if (error.status === HttpStatusCode.NotFound) {
                return undefined;
            } else {
                handleError(error);
            }
        });
