import {User} from "../../../API/users/types";
import {NotificationSubscription} from "../../../API/notifications/types";


export enum UserDialogType {
    PERMISSIONS,
    CREDENTIALS,
    NOTIFICATIONS,
}

export interface UserDialogData {
    type: UserDialogType;
    user: User;
}

export interface DisplayUser extends User {
    regionsStr: string;
    rolesStr: string;
}

export interface NotificationSubscriptionData extends Omit<NotificationSubscription, 'id'>{
    id: number;
    isActive: boolean;
}

export const userNotificationFormOptions = ['defectCreated', 'defectCommentCreated'] as const;
export type UserNotificationFormOptionType = typeof userNotificationFormOptions[number];

export interface UserNotificationsFormData {
    defectCreated: NotificationSubscriptionData;
    defectCommentCreated: NotificationSubscriptionData;
}
