import {Box, Typography} from "@mui/material";
import React from "react";
import MultiSelect from "../../../../components/Form/Select/MultiSelect";
import {useAppSelector} from "../../../../hooks";
import {selectAllRegions} from "../../../../store/regionSlice";
import {useFormikContext} from "formik";
import Switch from "../../../../components/Form/Switch/Switch";
import {UserNotificationFormOptionType, UserNotificationsFormData} from "../types";


const NotificationsForm = () => {
    const regions = useAppSelector(selectAllRegions);
    const formikProps = useFormikContext<UserNotificationsFormData>();

    const getNotificationFormFields = (title: string, typeName: UserNotificationFormOptionType, description: string) => {
        const isActive = !!formikProps.values[typeName]?.isActive;

        return (
            <Box>
                <Typography>Uus {title}</Typography>
                <Typography variant="body2" color="text.secondary">
                    Sisse lülitamisel võimaldab saada emaili teel teavitusi, kui {description}.
                </Typography>
                <Switch
                    name={`${typeName}.isActive`}
                    label={isActive ? 'Sees' : 'Väljas'}
                />
                {isActive &&
                    <MultiSelect
                        name={`${typeName}.regions`}
                        label="Piirkonnad"
                        options={regions}
                        sx={{mt: 0.5}}
                    />
                }
            </Box>
        );
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
            {getNotificationFormFields(
                'rike',
                'defectCreated',
                'valitud piirkonna bussile lisatakse bussijuhi poolt uus rike'
            )}
            {getNotificationFormFields(
                'rikke kommentaar',
                'defectCommentCreated',
                'valitud piirkonna bussi rikkele lisatakse bussijuhi poolt uus kommentaar'
            )}
        </Box>
    );
};

export default NotificationsForm;
