import React, {useCallback, useEffect, useState} from 'react';
import logo from "../../../assets/logo.png";
import {Alert, Box, Button, ButtonProps, lighten, styled, Typography} from "@mui/material";
import {useAuth} from "../../../contexts/AuthContext";
import {OAuthProvider, signInWithPopup} from 'firebase/auth';
import PasswordLoginForm from "./components/PasswordLoginForm";
import PhoneLoginForm from "./components/PhoneLoginForm";
import {Email, Microsoft, Phone} from "@mui/icons-material";
import {mapFirebaseAuthError} from "./utils";
import {isSandboxEnv} from "../../../utils/utils";

const MicrosoftButton = styled(Button)<ButtonProps>(({theme}) => ({
    backgroundColor: theme.palette.common.black,
    '&:hover': {
        backgroundColor: lighten(theme.palette.common.black, 0.25),
    },
}));

type LoginType = 'email' | 'password' | 'phone' | 'microsoft';

const Login = () => {
    const [loginType, setLoginType] = useState<LoginType|undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const { firebaseAuth } = useAuth();
    const enablePasswordLogin = import.meta.env.VITE_ENABLE_PASSWORD_LOGIN;
    const showSandboxEnvTitle = isSandboxEnv();

    const handleMicrosoftLogin = () => {
        setError(undefined);
        const provider = new OAuthProvider('microsoft.com');
        provider.setCustomParameters({
            tenant: 'gobus.ee',
        });
        signInWithPopup(firebaseAuth, provider).catch((error) => setError(mapFirebaseAuthError(error.code)));
    };

    useEffect(() => {
        setError(undefined);
    }, [loginType]);

    const handleCancel = () => setLoginType(undefined);

    const renderForm = useCallback(() => {
        switch (loginType) {
            case 'password': return <PasswordLoginForm handleCancel={handleCancel} />;
            case 'microsoft': return null;
            case 'phone': return <PhoneLoginForm handleCancel={handleCancel} />;
        }
    }, [loginType]);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingTop: {xs: '5vh', sm: '20vh'},
            width: '350px',
            maxWidth: '100%'
        }}>
            <img src={logo} alt="logo" style={{
                width: '100%', maxWidth: '100px', paddingBottom: '1vh', margin: 'auto'
            }} />
            {showSandboxEnvTitle &&
                <Typography variant="body2" color="text.secondary" align="center" mt={-2} mb={3}>
                    VIIGRI LIIVAKAST
                </Typography>
            }
            {loginType === undefined &&
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, width: '250px', margin: 'auto'}}>
                    {error && <Alert severity="error" sx={{mb: 1}}>{error}</Alert>}
                    <MicrosoftButton
                        onClick={handleMicrosoftLogin}
                        variant="contained"
                        startIcon={<Microsoft />}
                    >Microsoft</MicrosoftButton>
                    <Button
                        onClick={() => setLoginType('phone')}
                        variant="contained"
                        color="secondary"
                        startIcon={<Phone />}
                    >Telefon</Button>
                    {enablePasswordLogin &&
                        <Button
                            onClick={() => setLoginType('password')}
                            variant="contained"
                            startIcon={<Email />}
                        >E-mail ja parool</Button>
                    }
                </Box>
            }
            {loginType && renderForm()}
        </Box>
    );
};

export default Login;