import React from "react";
import DataGrid, {DEFAULT_COL_DEF_PROPS, DEFAULT_MENU_COL_DEF_PROPS} from "../../../../../components/DataGrid/DataGrid";
import {GridColDef, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams} from "@mui/x-data-grid";
import {formatTimeAtDate} from "../../../../../utils/dateUtils";
import {Box} from "@mui/material";
import {WarningAmberRounded} from "@mui/icons-material";
import {decimalToFixedDisplayStr, getOppositeResourceType} from "../../../../../utils/utils";
import {ResourceType, WorkGroupItemType} from "../../../../../API/workGroup/types";
import {WorkScheduleItemStatus} from "../../../../../API/workSchedule/types";
import {WorkSheetDetails, WorkSheetWorkItem} from "../../../../../API/workSheets/types";
import SecondaryResourceName from "./SecondaryResourceName";
import {getWorkItemLabel} from "../utils";
import {useAuth} from "../../../../../contexts/AuthContext";
import {Permission} from "../../../../../types";
import ActionIcon from "../../../../../components/Icon/ActionIcon";
import Tooltip from "../../../../../components/Tooltip/Tooltip";


interface WorkItemsProps {
    workSheet: WorkSheetDetails;
    workItems: WorkSheetWorkItem[];
    handleDelete: (workItem: WorkSheetWorkItem) => void;
    handleEdit: (workItem: WorkSheetWorkItem) => void;
    handleSplit: (workItem: WorkSheetWorkItem) => void;
}

const WorkItems = ({workSheet, workItems, handleDelete, handleEdit, handleSplit}: WorkItemsProps) => {
    const {hasPermission} = useAuth();

    const columns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'type',
            headerName: 'Tegevus',
            sortable: false,
            minWidth: 200,
            maxWidth: 250,
            valueGetter: (params: GridValueGetterParams) => getWorkItemLabel(params.row),
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'code',
            headerName: 'Reisi kood',
            sortable: false,
            minWidth: 100,
            maxWidth: 120,
            valueGetter: (params: GridValueGetterParams) => params.row.trip?.code ?? params.row.charterTrip?.tripNumber,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'lineNumber',
            headerName: 'Liini nr',
            sortable: false,
            minWidth: 100,
            maxWidth: 120,
            valueGetter: (params: GridValueGetterParams) => params.row.trip?.lineNumber,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'startDateTime',
            headerName: 'Algus',
            sortable: false,
            minWidth: 100,
            maxWidth: 120,
            valueFormatter: (params: GridValueFormatterParams) => formatTimeAtDate(params.value, workSheet.startDate),
            renderCell: (params: GridRenderCellParams) => <>
                {params.formattedValue}
                {params.row.warning &&
                    <Tooltip title="Tegevus algab enne eelmise tegevuse lõppu">
                        <WarningAmberRounded sx={{px: 0.2, fontSize: '18px'}} color="warning" />
                    </Tooltip>
                }
            </>,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'endDateTime',
            headerName: 'Lõpp',
            sortable: false,
            minWidth: 90,
            maxWidth: 100,
            valueFormatter: (params: GridValueFormatterParams) => formatTimeAtDate(params.value, workSheet.startDate),
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'distance',
            headerName: 'Läbisõit',
            sortable: false,
            minWidth: 90,
            maxWidth: 100,
            valueFormatter: (params: GridValueFormatterParams) =>
                params.value ? decimalToFixedDisplayStr(params.value) + ' km' : '',
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'otherResource',
            headerName: workSheet.resourceType === ResourceType.DRIVER ? 'Buss' : 'Juht',
            sortable: false,
            minWidth: workSheet.resourceType === ResourceType.DRIVER ? 80 : 200,
            maxWidth: workSheet.resourceType === ResourceType.DRIVER ? 100 : 250,
            renderCell: (params: GridRenderCellParams) => <SecondaryResourceName
                workItem={params.row}
                secondaryResourceType={getOppositeResourceType(workSheet.resourceType)}
            />,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'comment',
            headerName: 'Kommentaar',
            sortable: false,
            minWidth: 120,
            valueFormatter: (params: GridValueFormatterParams) => params.value,
        },
        {
            ...DEFAULT_MENU_COL_DEF_PROPS,
            minWidth: 100,
            renderCell: (params: GridRenderCellParams) => (!hasPermission(Permission.UpdateWorkSchedules) || workSheet.status === WorkScheduleItemStatus.CONFIRMED) ? <></> :
                <Box>
                    {params.row.type === WorkGroupItemType.TRIP_DEFINITION && params.row.trip &&
                        <ActionIcon type="SPLIT" id={params.row.id} handleClick={() => handleSplit(params.row)} alignTooltipArrowRight />
                    }
                    <ActionIcon type="EDIT" id={params.row.id} handleClick={() => handleEdit(params.row)} alignTooltipArrowRight />
                    <ActionIcon type="DELETE" id={params.row.id} handleClick={() => handleDelete(params.row)} alignTooltipArrowRight />
                </Box>
        },
    ];

    return (
        <DataGrid rows={workItems} columns={columns} />
    );
};

export default WorkItems;