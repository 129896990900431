import React from 'react';
import {useAppSelector} from "../../../../hooks";
import {selectEfficiencyIndicators} from "../../store/selectors";
import {Box, CircularProgress, Typography} from "@mui/material";
import {selectToggledResourceType} from "../../../../store/viewSlice";
import {ResourceType} from "../../../../API/types";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import {selectIsPlannerLoading} from "../../../../store/plannerSlice";


const EfficiencyIndicators = () => {
    const efficiencyIndicators = useAppSelector(selectEfficiencyIndicators);
    const toggledResourceType = useAppSelector(selectToggledResourceType);
    const isLoading = useAppSelector(selectIsPlannerLoading);

    const EfficiencyIndicator = ({title, description, value, borderLeft = true}: {title: string, description: string, value?: number | string, borderLeft?: boolean}) => (
        <Tooltip title={description} alignTooltipArrowRight>
            <Typography variant="body2" color="text.secondary" sx={{
                cursor: 'default',
                lineHeight: '16px',
                px: 1,
                borderLeft: '1px solid',
                borderColor: borderLeft ? 'divider' : 'transparent',
                ':hover': {
                    color: 'text.primary'
                }
            }}>
                {title} {isLoading ? <CircularProgress size={12} /> : value}
            </Typography>
        </Tooltip>
    );

    return (
        <Box sx={{display: 'flex', height: 'fit-content', minHeight: '16px'}}>
            {toggledResourceType === ResourceType.DRIVER &&
                <EfficiencyIndicator
                    title="TT"
                    description="Töötundide arv"
                    value={efficiencyIndicators.driverWorkHours?.toFixed(2)}
                    borderLeft={false}
                />
            }
            {toggledResourceType === ResourceType.DRIVER &&
                <EfficiencyIndicator
                    title="LA"
                    description="Liiniaeg"
                    value={efficiencyIndicators.driverWorkGroupLineTime?.toFixed(2)}
                />
            }
            {toggledResourceType === ResourceType.DRIVER &&
                <EfficiencyIndicator
                    title="LK"
                    description="Liinikoefitsent (liiniaja ja töötundide arvu suhe)"
                    value={efficiencyIndicators.driverLineTimeCoefficient?.toFixed(2)}

                />
            }
            <EfficiencyIndicator
                title="TG"
                description="Planeeritud töögruppide arv (töögrupp vähemalt ühe tegevusega)"
                value={efficiencyIndicators.plannedWorkGroupsCount}
                borderLeft={toggledResourceType === ResourceType.DRIVER}
            />
            <EfficiencyIndicator
                title="R"
                description="Planeerimata reiside arv"
                value={efficiencyIndicators.unassignedTripsCount}
            />
        </Box>
    );
};

export default EfficiencyIndicators;
