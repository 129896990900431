import React, {useMemo} from 'react';
import {Role} from "../../../../types";
import {Clear} from '@mui/icons-material';
import {User, UserPermissions} from "../../../../API/types";
import {Formik, FormikProps} from "formik";
import PermissionsForm, {UserPermissionsFormData} from "./PermissionsForm";
import {useAppSelector} from "../../../../hooks";
import {selectAllRegions} from "../../../../store/regionSlice";
import {Region} from "../../../../API/region/types";
import {getRoleTranslation} from "../../../../utils/enumTranslations";
import {getEnumFromStr} from "../../../../utils/formUtils";
import Dialog from "../../../../components/Dialog/Dialog";


interface PermissionsDialogProps {
    user: User;
    updateUserPermissions: (userId: string, userPermissions: UserPermissions) => void;
    handleCloseDialog: () => void;
}

export default function PermissionsDialog({user, updateUserPermissions, handleCloseDialog}: PermissionsDialogProps) {
    const regions = useAppSelector(selectAllRegions);

    const userRegions: Region[] = useMemo(() => {
        return user.regionIds
            ?.map(id => regions.find(region => region.id === id))
            ?.filter((region): region is Region => !!region) ?? []
    }, [user, regions]);

    const handleUpdateUserPermissions = (data: UserPermissionsFormData) => {
        updateUserPermissions(user.id, {
            roles: data.roles.map(role => getEnumFromStr(role.id, Role)) as Role[],
            allRegions: data.allRegions,
            regionIds: !data.allRegions ? data.regions.map(region => region.id) : [],
        });
    };

    const initialValues: UserPermissionsFormData = {
        roles: user.roles?.map(role => ({
            id: role.toString(),
            name: getRoleTranslation(role),
        })) ?? [],
        allRegions: user.allRegions,
        regions: userRegions,
    };

    return <Formik initialValues={initialValues} onSubmit={handleUpdateUserPermissions}>
        {(formikProps: FormikProps<UserPermissionsFormData>) =>
            <Dialog
                open={true}
                onClose={handleCloseDialog}
                title={user.displayName}
                subtitle={user.userName}
                hasFormikForm={true}
                buttons={[
                    {
                        disabled: formikProps.isSubmitting,
                        text: "Loobu",
                        color: "secondary",
                        startIcon: <Clear />,
                        onClick: handleCloseDialog,
                    },
                    {
                        disabled: formikProps.isSubmitting,
                        text: "Salvesta",
                        type: "submit"
                    }
                ]}
            >
                <PermissionsForm />
            </Dialog>
        }
    </Formik>;
}
