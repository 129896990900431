import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {GridColDef} from '@mui/x-data-grid';
import {areListsIdentical, filterByValue, getRegionsDisplayStr} from "../../../utils/utils";
import {getRoleTranslation} from "../../../utils/enumTranslations";
import {Permission, Role} from "../../../types";
import PermissionsDialog from "./components/PermissionsDialog";
import DataGrid, {DEFAULT_COL_DEF_PROPS, DEFAULT_MENU_COL_DEF_PROPS} from "../../../components/DataGrid/DataGrid";
import {
    ApiError,
    CreateNotificationSubscription,
    NotificationSubscription,
    Region,
    User,
    UserPermissions
} from '../../../API/types';
import {
    createNotificationSubscription,
    deleteNotificationSubscription,
    getUserNotificationSubscriptions,
    getUsers,
    updateNotificationSubscription,
    updateUserPassword as apiUpdateUserPassword,
    updateUserPermissions as apiUpdateUserPermissions,
} from "../../../API";
import {mapErrors} from "../../../utils/errorMapping";
import ActionIcon from "../../../components/Icon/ActionIcon";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {selectAllRegions} from "../../../store/regionSlice";
import ListView from "../../../layouts/ListViewWrapper";
import InputSearch from "../../../components/FilterToolbar/components/InputSearch";
import ToolbarContainer from "../../../components/FilterToolbar/components/ToolbarContainer";
import {AxiosResponse} from "axios";
import CredentialsDialog from "./components/CredentialsDialog";
import {
    DisplayUser,
    NotificationSubscriptionData,
    UserDialogData,
    UserDialogType,
    UserNotificationsFormData
} from "./types";
import NotificationsDialog from "./components/NotificationsDialog";
import {setToast} from "../../../store/toastSlice";
import {FormikHelpers} from "formik";
import UserNotificationsCell from "./components/UserNotificationsCell";


const getUserRegionsStr = (allRegions: Region[], hasAllRegions: boolean, userRegionIds: number[]) =>
    hasAllRegions ? 'Kõik' : getRegionsDisplayStr(userRegionIds ?? [], allRegions);

const getUserRolesStr = (userRoles?: Role[] | null) => {
    if (!userRoles || userRoles.length === 0) return '';
    const roleOrder = Object.values(Role);
    const orderedRoles = [...userRoles].sort((a: Role, b: Role) => roleOrder.indexOf(a) < roleOrder.indexOf(b) ? -1 : 1);

    return orderedRoles.map((role: Role) => getRoleTranslation(role)).join(', ');
};

export default function Users() {
    const dispatch = useAppDispatch();
    const regions = useAppSelector(selectAllRegions);
    const [searchInput, setSearchInput] = useState('');
    const [users, setUsers] = useState<User[] | undefined>(undefined);
    const [rows, setRows] = useState<DisplayUser[] | undefined>(undefined);
    const [dialogData, setDialogData] = useState<UserDialogData | undefined>(undefined);

    useEffect(() => {
        loadUserRows();
    }, []);

    useEffect(() => {
        if (users && regions) {
            setRows(users.map(user => ({
                ...user,
                regionsStr: getUserRegionsStr(regions, user.allRegions, user.regionIds ?? []),
                rolesStr: getUserRolesStr(user.roles),
            })));
        }
    }, [users, regions]);

    const loadUserRows = () => {
        getUsers()
            .then(userData => {
                setUsers(userData);
            })
            .catch((error) => {
                dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Kasutajate pärimisel ilmnes viga'}));
                setRows([]);
            });
    };

    const handleOpenPermissionsDialog = (id: number | string) => handleOpenDialog(id, UserDialogType.PERMISSIONS);
    const handleOpenNotificationsDialog = (id: number | string) => handleOpenDialog(id, UserDialogType.NOTIFICATIONS);
    const handleOpenCredentialsDialog = (id: number | string) => handleOpenDialog(id, UserDialogType.CREDENTIALS);

    const handleOpenDialog = (id: number | string, dialogType: UserDialogType) => {
        const user = users?.find(row => row.id === id);
        if (user) {
            setDialogData({
                type: dialogType,
                user: user
            });
        }
    };

    const handleCloseDialog = () => setDialogData(undefined);

    const updateUserPermissions = (userId: string, userPermissions: UserPermissions) => {
        updateUser(
            userId,
            () => apiUpdateUserPermissions(userId, userPermissions),
            {...userPermissions}
        );
    };

    const updateUserNotifications = (user: User, data: UserNotificationsFormData, formHelpers: FormikHelpers<UserNotificationsFormData>) => {
        const newSubscriptions: CreateNotificationSubscription[] = [];
        const updatedSubscriptions: NotificationSubscription[] = [];
        const deletedSubscriptions: number[] = [];

        Object.values(data).forEach((subscriptionFormData: NotificationSubscriptionData) => {
            const previousSubscriptionSetting = user.notificationSubscriptions?.find(prevSetting => prevSetting.type === subscriptionFormData.type);
            if (previousSubscriptionSetting) {
                if (subscriptionFormData.isActive) {
                    if (subscriptionFormData.id && !areListsIdentical(
                        subscriptionFormData.regions.map(region => region.id),
                        previousSubscriptionSetting.regions.map(region => region.id)
                    )) {
                        updatedSubscriptions.push(subscriptionFormData);
                    }
                } else {
                    deletedSubscriptions.push(subscriptionFormData.id);
                }
            } else {
                if (subscriptionFormData.isActive) {
                    newSubscriptions.push({
                        type: subscriptionFormData.type,
                        regionIds: subscriptionFormData.regions.map(region => region.id)
                    });
                }
            }
        });

        Promise.all([
            ...newSubscriptions.map(newSubscription => createNotificationSubscription(user.id, newSubscription)),
            ...updatedSubscriptions.map(updatedSubscription => updateNotificationSubscription(updatedSubscription.id, {
                regionIds: updatedSubscription.regions.map(region => region.id)
            })),
            ...deletedSubscriptions.map(deletedSubscriptionId => deleteNotificationSubscription(deletedSubscriptionId)),
        ])
            .then(() =>
                getUserNotificationSubscriptions(user.id)
                    .then((result) => {

                        if (users) {
                            const selectedRowIndex = users.findIndex(row => row.id === user.id);
                            if (selectedRowIndex !== undefined) {
                                const updatedUsers = [...users];
                                updatedUsers[selectedRowIndex] = {...users[selectedRowIndex], notificationSubscriptions: result};
                                setUsers(updatedUsers);
                                dispatch(setToast({type: 'success', text: 'Kasutaja teavituste sätted edukalt uuendatud'}));
                                handleCloseDialog();
                            }
                        }
                    })
                    .catch((error: ApiError) => {
                        dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Kasutaja teavituste uuendamisel ilmnes viga'}));
                    })
            )
            .catch((error: ApiError) => {
                dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Kasutaja teavituste uuendamisel ilmnes viga'}));
            })
            .finally(() => formHelpers.setSubmitting(false));
    };

    const updateUserCredentials = (userId: string, userName: string, password: string) => {
        updateUser(
            userId,
            () => apiUpdateUserPassword(userId, userName, password),
            { userName: userName }
        );
    };

    const updateUser = (
        userId: string,
        apiCall: () => Promise<AxiosResponse<void, any>>,
        partial: Partial<User>
    ) => {
        if (users) {
            const selectedRowIndex = users.findIndex(row => row.id === userId);
            if (selectedRowIndex !== undefined) {
                apiCall()
                    .then(() => {
                        const updatedRows = [...users];
                        updatedRows[selectedRowIndex] = {...users[selectedRowIndex], ...partial};
                        setUsers(updatedRows);
                        dispatch(setToast({type: 'success', text: 'Kasutaja andmed edukalt uuendatud'}));
                        handleCloseDialog();
                    })
                    .catch((error: ApiError) => {
                        dispatch(setToast({type: 'error', text: mapErrors(error) ?? 'Kasutaja andmete uuendamisel ilmnes viga'}));
                    });
            }
        }
    };

    const columns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'userName',
            headerName: 'Kasutajanimi',
            minWidth: 180,
            maxWidth: 250,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'displayName',
            headerName: 'Nimi',
            minWidth: 150,
            maxWidth: 250,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'phoneNumber',
            headerName: 'Telefon',
            minWidth: 120,
            maxWidth: 150,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'notificationSubscriptions',
            headerName: 'Teavitused',
            minWidth: 100,
            maxWidth: 120,
            renderCell: params => <UserNotificationsCell notificationSubscriptions={params.value} />
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'rolesStr',
            headerName: 'Rollid',
            minWidth: 150,
            maxWidth: 300,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'regionsStr',
            headerName: 'Piirkonnad',
            minWidth: 150,
        },
        {
            ...DEFAULT_MENU_COL_DEF_PROPS,
            minWidth: 100,
            renderCell: params =>
                <Box>
                    {!params.row.providers.includes('microsoft.com') && params.row.driverId &&
                        <ActionIcon type="SET_PASSWORD" id={params.row.id} alignTooltipArrowRight handleClick={handleOpenCredentialsDialog} navPermission={Permission.UpdateUserPasswords} />
                    }
                    <ActionIcon type="EDIT_NOTIFICATION" id={params.row.id} alignTooltipArrowRight handleClick={handleOpenNotificationsDialog} navPermission={Permission.UpdateUsers} />
                    <ActionIcon type="EDIT" id={params.row.id} alignTooltipArrowRight handleClick={handleOpenPermissionsDialog} navPermission={Permission.UpdateUsers} />
                </Box>
        }
    ];

    return (
        <ListView headerProps={{title: 'Kasutajad'}} isLoading={!rows}>
            <DataGrid
                rows={filterByValue(rows ?? [], searchInput)}
                columns={columns}
                filterToolbar={
                    <ToolbarContainer sx={{flexDirection: {xs: 'column', md: 'row'}, gap: 1}}>
                        <InputSearch value={searchInput} updateValue={setSearchInput} />
                    </ToolbarContainer>
                }
            />
            {dialogData?.type === UserDialogType.PERMISSIONS &&
                <PermissionsDialog
                    user={dialogData.user}
                    updateUserPermissions={updateUserPermissions}
                    handleCloseDialog={handleCloseDialog}
                />
            }
            {dialogData?.type === UserDialogType.NOTIFICATIONS &&
                <NotificationsDialog
                    user={dialogData.user}
                    saveUserNotifications={updateUserNotifications}
                    handleCloseDialog={handleCloseDialog}
                />
            }
            {dialogData?.type === UserDialogType.CREDENTIALS &&
                <CredentialsDialog
                    user={dialogData.user}
                    updateUserCredentials={updateUserCredentials}
                    handleCloseDialog={handleCloseDialog}
                />
            }
        </ListView>
    );
}
