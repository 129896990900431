import React, {useContext, useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {Driver, ApiError, UpdateDriverRequest} from '../../../../API/types';
import {DriverForm} from './types';
import {useParams} from 'react-router-dom';
import Loader from "../../../../components/Loader/Loader";
import {ToastContext} from "../../../../contexts/ToastContext";
import {Formik, FormikHelpers} from 'formik';
import {useNavigate} from "react-router";
import * as Yup from "yup";
import {validationSchema} from "../../../../utils/formValidation";
import routes from '../../../../routes';
import DriverDetailsEditForm from "./components/DriverDetailsEditForm";
import {ErrorMessages} from "../../../../utils/errorMapping";
import {useAppSelector} from "../../../../hooks";
import {selectAllRegions} from "../../../../store/regionSlice";
import DriverContracts from "./components/DriverContracts";
import {createDriver, fetchDriver, updateDriver} from "../../../../API/driver/api";
import {useAuth} from "../../../../contexts/AuthContext";
import {isSandboxEnv, userCanEditResource} from "../../../../utils/utils";


const driverValidationSchema = Yup.object().shape({
    firstName: validationSchema('Eesnimi').fields.shortTextFieldRequired,
    lastName: validationSchema('Perekonnanimi').fields.shortTextFieldRequired,
    personalId: validationSchema('Isikukood').fields.personalIdRequired,
    phonePrefix: validationSchema('Suunakood').fields.integerTextFieldRequired,
    phone: validationSchema('Telefoninumber').fields.integerTextFieldRequired,
    email: validationSchema('E-post').fields.email,
    regions: validationSchema('Piirkond').fields.arrayRequired,
    accountingRegionId: validationSchema('Arvelduspiirkond').fields.numberRequired,
});

const defaultDriverFormValues: DriverForm = {
    firstName: '',
    lastName: '',
    personalId: '',
    phonePrefix: '+372',
    phone: '',
    email: '',
    active: true,
    regions: [],
    accountingRegionId: null,
    externalId: '',
};

export default function DriverDetails() {
    const navigate = useNavigate();
    const { addToast } = useContext(ToastContext);
    const regions = useAppSelector(selectAllRegions);
    const { id } = useParams<{ id: string }>();
    const [driver, setDriver] = useState<Driver | undefined>(undefined);
    const [initialValues, setInitialValues] = useState<DriverForm | undefined>(undefined);
    const { authenticatedUser } = useAuth();
    const canEdit = userCanEditResource(authenticatedUser, driver) && !isSandboxEnv();

    useEffect(() => {
        if (id === undefined) {
            setInitialValues(defaultDriverFormValues);
        } else {
            fetchDriver(id)
                .then(result => {
                    setDriver(result);
                })
                .catch(() => {
                    addToast({type: 'error', text: 'Bussijuhi andmete pärimisel ilmnes viga'});
                    navigate(routes.AUTHENTICATED.DRIVERS.ROOT);
                });
        }
    }, [id]);

    useEffect(() => {
        if (driver) {
            setInitialValues({
                ...driver,
                firstName: driver.firstName,
                email: driver.email ?? '',
                regions: regions?.filter(region => driver.regionIds.includes(region.id)) ?? [],
                accountingRegionId: driver.accountingRegionId,
                externalId: driver.externalId ?? '',
            });
        }
    }, [driver]);

    const onSubmit = (form: DriverForm, formHelpers: FormikHelpers<DriverForm>) => {
        if (!form.accountingRegionId) return;

        const request: UpdateDriverRequest = {
            firstName: form.firstName,
            lastName: form.lastName,
            personalId: form.personalId,
            phonePrefix: form.phonePrefix,
            phone: form.phone,
            email: form.email,
            active: form.active,
            regionIds: form.regions.map(region => region.id),
            accountingRegionId: form.accountingRegionId,
            externalId: form.externalId,
        };

        if (id) {
            updateDriver(id, request)
                .then(() => {
                    addToast({type: 'success', text: 'Bussijuhi andmed edukalt uuendatud'});
                    setDriver({id: Number(id), ...request, contracts: driver?.contracts ?? []});
                })
                .catch(onSaveDriverRejected)
                .finally(() => formHelpers.setSubmitting(false));
        } else {
            createDriver(request)
                .then(result => {
                    addToast({type: 'success', text: 'Uus bussijuht edukalt loodud'});
                    navigate(routes.AUTHENTICATED.DRIVERS.EDIT.replace(':id', result.id.toString()));
                })
                .catch(onSaveDriverRejected)
                .finally(() => formHelpers.setSubmitting(false));
        }
    };

    const onSaveDriverRejected = (error: ApiError) => {
        if (error?.errorCode) {
            addToast({type: 'error', text: ErrorMessages.get(error?.errorCode) ?? 'Bussijuhi salvestamisel ilmnes viga'});
        } else {
            addToast({type: 'error', text: 'Bussijuhi salvestamisel ilmnes viga'});
        }
    };

    return (initialValues ?
        <Box p={{xs: 1, sm: 0}}>
            <Formik enableReinitialize initialValues={initialValues} validationSchema={driverValidationSchema} onSubmit={onSubmit}>
                <DriverDetailsEditForm driver={driver} id={id} defaultFormValues={defaultDriverFormValues} canEdit={canEdit} />
            </Formik>
            {driver && <DriverContracts driverId={driver.id} contracts={driver.contracts} canEdit={canEdit} />}
        </Box>
        :
        <Loader />
    );
}