import {ResourceType} from "./API/workGroup/types";
import {EntityId} from "@reduxjs/toolkit";
import {Region} from "./API/region/types";

export interface AuthenticatedUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    permissions: Permission[];
    roles: Role[];
    driverId: number | null;
    regions: Region[];
    regionalFilteringApplies: boolean;
}

export enum Permission {
    ListUsers = 'list:users',
    UpdateUsers = 'update:users',
    UpdateUserPasswords = 'update:user-passwords',
    ListBuses = 'list:buses',
    ReadBuses = 'read:buses',
    UpdateBuses = 'update:buses',
    UpdateOdometerReadings = 'update:odometer-readings',
    ReadRegions = 'read:regions',
    UpdateRegions = 'update:regions',
    ListDrivers = 'list:drivers',
    ReadDrivers = 'read:drivers',
    UpdateDrivers = 'update:drivers',
    ReadCalendarEntries = 'read:calendar-entries',
    UpdateCalendarEntries = 'update:calendar-entries',
    ListTransportContracts = 'list:transport-contracts',
    ReadTransportContracts = 'read:transport-contracts',
    UpdateTransportContracts = 'update:transport-contracts',
    ListTrips = 'list:trips',
    ReadTrips = 'read:trips',
    UpdateTrips = 'update:trips',
    DeleteTrips = 'delete:trips',
    ReadCharterTrips = 'read:charter-trips',
    UpdateCharterTrips = 'update:charter-trips',
    ReadWorkGroups = 'read:work-groups',
    UpdateWorkGroups = 'update:work-groups',
    ListWorkSchedules = 'list:work-schedules',
    ReadWorkSchedules = 'read:work-schedules',
    UpdateWorkSchedules = 'update:work-schedules',
    DeleteWorkSchedules = 'delete:work-schedules',
    ReadPayroll = 'read:payroll',
    UpdatePayroll = 'update:payroll',
    ReadDefects = 'read:defects',
    ListDefects = 'list:defects',
    UpdateDefects = 'update:defects',
    ReadAvlAssignments = 'read:avl-assignments',
    UpdateAvlMessages = 'update:avl-assignments',
    ReadTripOccurrences = 'read:trip-occurrences',
    ReadReports = 'read:reports',
}

export enum Role {
    ADMIN = 'ADMIN',
    DISPATCHER = 'DISPATCHER',
    DRIVER = 'DRIVER',
    TECHNICAL_LEAD = 'TECHNICAL_LEAD',
    EXTERNAL_ADMIN = 'EXTERNAL_ADMIN',
    INFORMATION_CLERK = 'INFORMATION_CLERK',
}

export const activeFilterChipValues = ['Aktiivsed', 'Mitteaktiivsed'] as const;
export type ActiveFilterChipType = typeof activeFilterChipValues[number];

export const dateLimitFilterChipValues = ['Käigus', 'Möödunud'] as const;
export type DateLimitFilterChipType = typeof dateLimitFilterChipValues[number];

export const resourceGroupToggleButtonTypeValuesWithAll = ['KÕIK', 'JUHT', 'BUSS'] as const;
export type ResourceGroupToggleButtonTypeWithAll = typeof resourceGroupToggleButtonTypeValuesWithAll[number];

export const resourceGroupToggleButtonTypeValues = ['JUHT', 'BUSS'] as const;
export type ResourceGroupToggleButtonType = typeof resourceGroupToggleButtonTypeValues[number];

export const resourceGroupToggleButtonTypeToResourceGroupTypeMap =
    new Map<ResourceGroupToggleButtonTypeWithAll | ResourceGroupToggleButtonType, ResourceType>([
        ['KÕIK', ResourceType.DRIVER],
        ['JUHT', ResourceType.DRIVER],
        ['BUSS', ResourceType.VEHICLE],
    ]);

export const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] as const;

export interface SelectOption {
    id?: number | string | EntityId;
    name: string;
}

export interface SelectOptionWithId {
    id: number;
    name: string;
}

export interface SelectOptionWithIdAndRegion {
    id: number;
    name: string;
    regionName: string;
}

export interface SelectOptionWithIdAndPermission {
    id: number;
    name: string;
    description?: string;
    requireAllRegions?: boolean;
}

export enum Environment {
    PROD = 'PROD',
    TEST = 'TEST',
    SANDBOX = 'SANDBOX',
    LOCAL = 'LOCAL'
}
